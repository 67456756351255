import { useEffect, useState } from 'react';
import { createContainer } from 'react-tracked';
import dark from './dark';
import light from './light';

const themes = {
  light,
  dark,
};

const useThemeState = () => {
  const [theme, _setTheme] = useState('dark');

  const setTheme = (themeName = 'dark') => {
    const t = themes[themeName];
    if (!t) return;
    Object.keys(t).forEach(key => document.documentElement.style.setProperty(`--${key}`, t[key]));
    const metaThemeColor = document.querySelector('meta[name="theme-color"]');
    metaThemeColor.setAttribute('content', t['bg-color']);

    _setTheme(themeName);
    localStorage.setItem('theme', themeName);
  };

  const toggleTheme = () => setTheme(theme == 'light' ? 'dark' : 'light');

  useEffect(() => {
    setTheme(localStorage.getItem('theme') || theme);
  }, []);

  return [theme, toggleTheme];
};

const { Provider, useTracked } = createContainer(useThemeState);

export const useTheme = useTracked;
export const ThemeProvider = ({ children }) => <Provider>{children}</Provider>;

export default ThemeProvider;
