import React, { useState } from 'react';
import './filterComponent.scss';

const TimeFilter = ({ onFilterChange }) => {

    const handleFilterClick = (type) => {
        let filter = {};
        const currentDate = new Date();

        switch (type) {
            case '1H':
                filter = { startDate: new Date(currentDate.getTime() - (60 * 60 * 1000)).toISOString(), endDate: currentDate.toISOString() , type };
                break;
            case '1D':
                filter = { startDate: new Date(currentDate.getTime() - (24 * 60 * 60 * 1000)).toISOString(), endDate: currentDate.toISOString(), type };
                break;
            case '1W':
                filter = { startDate: new Date(currentDate.getTime() - (7 * 24 * 60 * 60 * 1000)).toISOString(), endDate: currentDate.toISOString() , type };
                break;
            default:
                break;
        }

        onFilterChange(filter);
    };

    return (
        <div className="time-filter-container">
            <div className="filter-buttons">
                <button className="filter-button" onClick={() => handleFilterClick('1H')}>1Hr</button>
                <button className="filter-button" onClick={() => handleFilterClick('1D')}>1D</button>
                <button className="filter-button" onClick={() => handleFilterClick('1W')}>1W</button>
            </div>
            
        </div>
    );
};

export default TimeFilter;
