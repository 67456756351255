import React, { useCallback, useEffect, useState } from 'react';
import './Petcat.scss';
import ButtonApi from '../ButtonApi';
import { useGlobalState } from '../../state/GlobalStateProvider';
import ToolTip from '../toolTip/Tooltip';
import { useWindowSize } from 'usehooks-ts';
import usePetcatHelper from '../../helpers/petcatHelper';
import { useCountdown } from '../../hooks/useCountDown';
import Icons from '../icons/Icons';

const Petcat = () => {
  const [{ user }] = useGlobalState();
  const { width } = useWindowSize();
  const { addEntry, points, lastEntry, getLastEntry, getPoints , activeCat} = usePetcatHelper();
  
  const [isDisabled, setIsDisabled] = useState(true);
 

  const [showText,setShowText]=useState(false)

  const STABLE_GIF='https://dskhnex0aho1n.cloudfront.net/public/catsleeponly.gif'
  const PET_GIF = 'https://dskhnex0aho1n.cloudfront.net/public/catmew.gif'  
  const TARGET_TIME = new Date(lastEntry).getTime() + 12 * 60 * 60 * 1000

  const { time, ago } = useCountdown(TARGET_TIME)

  async function handleAddEntry(e) {
    if (isDisabled) return;
   await addEntry(e);
  }

  useEffect(() => {
    if (!user) return;
    getPoints();
    getLastEntry();
  }, [user]);

  useEffect(() => {
    if (!lastEntry) return setIsDisabled(false)
    if (ago) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }

  }, [ago, lastEntry])

const handleShowText=(event)=>{
  event.stopPropagation()
  setShowText(!showText)
}

  if (!user) return;

  return (
    <>
      <ToolTip
        className='petcat-button'
        showOnClick={true}
        contentClassName="pet-cat-modal"
        tipContent={
          <>
            <div className='pet-cat-heading'>
              <div className='logo-container'>
              <span>{isDisabled ? "🥰" :Number(points?.current_negative) > 0? "😖" :  '🥺'}</span>
              <h1>{isDisabled ? 'Purr-fect Moments!' : Number(points?.current_negative) > 0? "Where were you?" :  'Time for Cuddles'}</h1>
              </div>
              <div>
                
              </div>
              <h1>
                {points?.points ?? 0} <p>MEW</p>
              </h1>
             <div onClick={handleShowText}>

              <Icons icon={'info'}  /> 
             </div>
            </div> 
            <div className='cat-gif-back'>
              {showText ? <ul>
                <h3>Rules</h3>
                <li><span>🥰 </span>Pet your cat every 12 hours to earn +1 MEW.</li>
                <li><span>🥺</span> Lose -1 MEW for every 24 hours.</li>
                <li><span>😖</span> Keep petting your cat regularly to avoid penalties.</li>
               
              </ul> :  <img className='cat-gif' src={activeCat ? PET_GIF : STABLE_GIF} />}
           
                </div>
              <div className='pet-cat-footer'>
                <div className='footer-text'>
                {isDisabled ? "Thanks you! Your kitty is happy and resting now, feeling blissful and loved" :Number(points?.current_negative) > 0? <>Your cat was feeling lonely.<div className='error'> You have received a -{Number(points?.current_negative)} MEW penalty.</div></> :  'Your furry friend is waiting. Give your cat some love to keep those purrs coming!'}
                </div>
            <ButtonApi disabled={isDisabled} onClick={handleAddEntry} className='pet-cat-modal-button' stopPropagation={true}>
              {(isDisabled) ? `${time}` :  ' Pet your cat'}
            </ButtonApi>
              </div>
          </>
        }
      >
        <span>🐱</span>
        {width <= 600 ? (
          ''
        ) : (
          <h3>
            {points?.points ?? 0}
            <p>MEW</p>
          </h3>
        )}
      </ToolTip>
    </>
  );
};

export default Petcat;