import { useRef, useState } from "react";
import Table from "./table";
import "./table.scss";
import Input from "./Input";

const Toolbar = ({ id, searchPlaceholder, onSearch, renderRightPanel, dataHookRef }) => {
  const debounceTimer = useRef();

  const setTextOptimized = (e) => {
    if (debounceTimer?.current) clearTimeout(debounceTimer?.current);
    debounceTimer.current = setTimeout(() => {
      onSearch(e);
    }, 300);
  };

  return (
    <div className="toolbar-container">
      <Input id={id} placeholder={searchPlaceholder || "Search"} onChange={setTextOptimized} />
      <div className="filter-container">{renderRightPanel({ dataHookRef })}</div>
    </div>
  );
};
const TableSearchApied = ({
  className = "",
  searchPlaceholder,
  idInputSearch,
  showToolbar = true,
  renderToolbarRightPanel = ({ dataHookRef }) => <></>,
  theadRows = [<th></th>],
  renderRow = ({ item, i, dataHookRef, page, pageSize }) => <></>,
  pageSize = 10,
  hookConfig = {
    // fetch data rows hook
    useDataHook: () => {
      return {};
    },
    // member containing data
    rowsKey: "tasksDone",
    totalCountKey: "totalCount",
    // some hooks may have different params
    funcHookParams: (searchText, page, pageSize) => {
      return { searchText, page, pageSize };
    },
  },
}) => {
  const [searchText, setSearchText] = useState();
  const [page, setPage] = useState(0);
  const dataHookFields = hookConfig.useDataHook(hookConfig.funcHookParams(searchText, page, pageSize));

  const onSearch = (e) => {
    setPage(0);
    setSearchText(e.target.value);
  };

  const fromDataIndex = page * pageSize;
  const toDataIndex = page * pageSize + pageSize;

  const rows = dataHookFields[hookConfig.rowsKey];

  return (
    <div className={`tablesearch-container ${className || ""}`}>
      {showToolbar && (
        <Toolbar
          id={idInputSearch}
          searchPlaceholder={searchPlaceholder}
          onSearch={onSearch}
          renderRightPanel={renderToolbarRightPanel}
          dataHookRef={dataHookFields}
        />
      )}
      <Table
        count={dataHookFields[hookConfig.totalCountKey]}
        fromDataIndex={fromDataIndex}
        toDataIndex={toDataIndex}
        page={page}
        pageSize={pageSize}
        onPageChange={setPage}
      >
        {theadRows && (
          <thead>
            <tr>{theadRows}</tr>
          </thead>
        )}
        {!rows ? (
          <>Loading...</>
        ) : rows.length ? (
          <tbody>{rows.map((item, i) => renderRow({ item, i, dataHookRef: dataHookFields, page, pageSize }))}</tbody>
        ) : (
          <></>
        )}
      </Table>
    </div>
  );
};

export default TableSearchApied;
