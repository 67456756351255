import NftTemplates from '../../helpers/nftTemplates/nftTemplates';
import utils from '../../utils';
import './NftPreview.scss';

const Content = ({ src, template, thumbnail = false }) => {
  if (!src && !template) return <></>;
  if (src) return <iframe src={src} />;

  let renderComp = <iframe srcDoc={NftTemplates.membershipCard[template.name](template?.config, {})} />;

  if (thumbnail) {
    switch (template?.name) {
      case NftTemplates.membershipCard.VideoCard.name:
        renderComp = (
          <div
            className='img'
            style={{
              backgroundImage: `url("${utils.getFileUrl(template.config.imageUrl)}")`,
            }}
          />
        );
        break;
      case NftTemplates.membershipCard.HOSCard.name:
        renderComp = (
          <div
            className='img'
            style={{
              backgroundImage: `url("${utils.getFileUrl(template.config.imageUrl)}")`,
            }}
          />
        );
        break;
      case NftTemplates.membershipCard.LayeredImageCard.name:
      default:
        renderComp = (
          <div
            className='img'
            style={{
              backgroundImage: `url("${utils.getFileUrl(template.config.imageUrlLayers[0])}")`,
            }}
          />
        );
        break;
    }
  }

  return renderComp;
};

/**
 * src = nft html link (optional)
 * template = campaign.template (optional)
 */
const NftPreview = ({ className = '', src, template, thumbnail = false, collected }) => {
  return (
    <div className={`NftPreview-container ${className}`}>
      {collected ? <div className='minted-tag'>MINTED</div> : <></>}
      {<Content src={src} template={template} thumbnail={thumbnail} />}
    </div>
  );
};

export default NftPreview;
