import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Grid from '../../components/Grid';
import { CardsLoader } from '../../components/Skeleton/CardsLoader';
import { useBackendApi } from '../../helpers/backendHelper';
import './TopCommunities.scss';

const TopCommunityCard = ({ data }) => {
  return (
    <Link className='TopCommunityCard-container card' to={`${data?.community?.name}/event`}>
      <img src={data.top.image || data.community.avatar} />
      <div className='content ellipse'>
        <h1 className='ellipse'>{data.top.title || data.community.displayName}</h1>
        <p className='ellipse'>{data.top.description || data.campaign.description}</p>
      </div>
      {/* <div className="rewards">
        {data.event.overview?.tags?.map((tag, i) =>
          resources.img.eventTags[tag] ? <img key={tag} src={resources.img.eventTags[tag]} /> : <></>
        )}
      </div> */}
    </Link>
  );
};

const TopCommunities = ({}) => {
  const backendApi = useBackendApi();
  const [data, setData] = useState();

  useEffect(() => {
    backendApi.communities
      .getTopCommunities('community', 0, 4)
      .then(setData)
      .catch(() => setData(null));
  }, []);

  if (data === undefined) return <CardsLoader rows={2} columns={2} columnHeight={100} />;
  if (!data?.top?.length) return <></>;
  return (
    <Grid className='TopCommunities-container no-scrollbar' title='Top Community' columns={4} rows={2}>
      {data?.top?.slice(0, 4)?.map((top, i) => (
        <TopCommunityCard
          key={i}
          data={{
            top: top,
            community: data.communities[top.commId],
            event: data.events[top.commId],
            campaign: data.campaigns[top.commId],
          }}
        />
      ))}
    </Grid>
  );
};

export default TopCommunities;
