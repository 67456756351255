import Hr from '../../../components/Hr';
import utils from '../../../utils';
import './TaskVerifyResultToast.scss';

const RewardsItems = ({ claimResult }) => {
  if (!Object.keys(claimResult || {}).length) return <></>;
  return (
    <>
      <Hr />
      <div className='rewardItems'>
        {(claimResult.rewardClaims?.[0]?.items || []).map(rewardItem => (
          <img alt='' src={utils.getFileUrl(rewardItem?.image)} />
        ))}
        {(claimResult.rewardClaims?.[1]?.items || []).map(rewardItem => (
          <img alt='' src={utils.getFileUrl(rewardItem?.image)} />
        ))}
      </div>
    </>
  );
};

const Result = ({ task, claimResult }) => {
  return (
    <>
      <p>{task.recurringTask ? <>Your progress rewards</> : <>You won {claimResult?.rewardClaims?.[0]?.items?.[0]?.xp} XP</>}</p>
      <RewardsItems claimResult={claimResult} />
    </>
  );
};

const TaskVerifyResultToast = ({ task, claimResult }) => {
  return (
    <div className='TaskVerifyResultToast-container'>
      <h1>{task.name}</h1>
      <Result task={task} claimResult={claimResult} />
    </div>
  );
};

export default TaskVerifyResultToast;
