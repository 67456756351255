import { useEffect, useMemo, useState } from 'react';
import ButtonApi from '../../components/ButtonApi';
import Grid from '../../components/Grid';
import { CardsLoader } from '../../components/Skeleton/CardsLoader';
import { NftCard } from '../../components/nft/NftCard';
import { useBackendApi } from '../../helpers/backendHelper';
import { isEmpty } from '../../utils';
import './AllEvents.scss';
import { useNavigate } from 'react-router-dom';

const AllEvents = () => {
  const limit = 12;
  const backendApi = useBackendApi();
  const [data, setData] = useState();
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const onPageChange = useMemo(() => {
    setIsLoading(true);
    const skip = (page - 1) * limit;
    backendApi.communities
      .getAllEvents(skip, limit)
      .then(res => {
        if (isEmpty(res) || res?.allEvents?.length < limit) {
          setHasMore(false);
        }

        setData(prevData => {
          const updatedData = {
            ...prevData,
            allEvents: [...(prevData?.allEvents || []), ...res.allEvents],
            communities: { ...prevData?.communities },
            campaigns: { ...prevData?.campaigns },
          };

          // Update communities and campaigns
          Object.keys(res.communities || {}).forEach(key => {
            updatedData.communities[key] = res.communities[key];
          });
          Object.keys(res.campaigns || {}).forEach(key => {
            updatedData.campaigns[key] = res.campaigns[key];
          });
          return updatedData;
        });
      })
      .catch(() => {
        setData(null);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [page]);

  useEffect(() => {
    backendApi.communities
      .getAllEvents(0, limit)
      .then(setData)
      .catch(() => setData(null));
  }, []);

  if (data === undefined) return <CardsLoader columns={3} rows={2} columnHeight={300} />;
  if (!data?.allEvents?.length) return <></>;
  return (
    <>
      <Grid 
      className='AllEvents-container' 
      title={'All Projects'} columns={4}
      sectionAction={{
        label: 'View More',
        action: () => {
          navigate(`/allevents`);
        },
      }}
      >
        {data?.allEvents?.map((event, i) => (
          <NftCard campaign={data.campaigns?.[event.commId]} community={data.communities?.[event.commId]} event={event} />
        ))}
      </Grid>
      {hasMore ? (
        <ButtonApi
          className='view-more'
          onClick={async () => {
            setPage(page + 1);
            await onPageChange();
          }}
        >
          {isLoading ? 'Loading it for you ser...' : 'Load more campaigns!'}
        </ButtonApi>
      ) : (
        <div className='spacer'></div>
      )}
    </>
  );
};

export default AllEvents;
