import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './skeleton.scss';

const random = (min = 15, max = 35) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const CardsLoader = ({ rows, columns, columnHeight, className }) => {
  const [loaderRandoms] = useState(new Array(rows * columns || random(4, 7)).fill(0).map(() => ({ count: random(1, 2), width: random(25, 45) })));

  return (
    <div
      className={`custom-skeleton-loader skeleton-loader-cards-container ${className || ''}`}
      style={{ gridTemplateRows: `repeat(${rows}, 1fr)`, gridTemplateColumns: `repeat(${columns}, 1fr)` }}
    >
      {loaderRandoms.map((v, i) => {
        return (
          <div style={{ height: `${columnHeight}px` }} className='skeleton-loader-card'>
            <Skeleton baseColor='var(--skeleton-base-color)' highlightColor='var(--skeleton-highlight-color)' />
          </div>
        );
      })}
    </div>
  );
};

export { CardsLoader };
