import { types } from '../constants';
import { useGlobalState } from '../state/GlobalStateProvider';
import { useOauthHelper } from './login/oauthHelper';

export const useTelegramHelper = community => {
  const [{ user }] = useGlobalState();
  const oauthHelper = useOauthHelper();

  const loginTelegram = async redirectUrl => {
    if (user?.telegram?.id) return true;

    const state = await oauthHelper.getLoginState(types.loginVia.telegram, types.postLoginActions.joinTelegram, redirectUrl);
    window.open(state, '_blank');
    return false;
  };

  const joinTelegramGroup = async _redirectUrl => {
    if (!_redirectUrl && !community?.links?.telegram) return;
    try {
      const redirectUrl = _redirectUrl || `https://${community?.links?.telegramUrl}`;
      if (await loginTelegram(redirectUrl)) {
        window.open(redirectUrl, '_blank');
      } else {
        alert.info('Please login with Telegram');
      }
    } catch (e) {
      console.error(e);
      alert.error('Something went wrong');
    }
  };

  return {
    loginTelegram,
    joinTelegramGroup,
  };
};

export default { useTelegramHelper };
