import Address from '../../../../components/Address';
import './RaffleWinnerCard.scss';

const RaffleWinnerCard = ({ winner }) => {
  return (
    <div className='RaffleWinnerCard-container card'>
      <div className='img' style={{ backgroundImage: `url("${winner?.rewardNameImage}")` }} />
      <div className='content'>
        <a className='link' href={winner?.rewardExternal} target='_blank'>
          <h1 className='ellipse'>
            {winner?.rewardName} #{winner?.rewardTokenId}
          </h1>
          🔗
        </a>
        <h2>Winner Details</h2>
        <h1 className='ellipse'>{winner?.name}</h1>
        <h2>Winner Address</h2>
        <h1>
          <Address a={winner?.address} />
        </h1>
      </div>
    </div>
  );
};

export default RaffleWinnerCard;
