import { useWindowSize } from 'usehooks-ts';
import './Grid.scss';
import Section from './Section';

const Grid = ({ className = '', title, columns = 'auto', rows, children, minWidth = 250, sectionAction, toggle }) => {
  const { width } = useWindowSize();

  return (
    <Section
      className={`Grid-container ${className}`}
      title={title}
      style={{
        gridTemplateColumns: `repeat(${width >= 1500 || children?.length <= 2 ? columns : 'auto-fit'}, minmax(${minWidth}px, 1fr))`,
      }}
      sectionAction={sectionAction}
      toggle={toggle}
    >
      {rows && typeof columns == 'number' ? children?.slice(0, columns * rows) : children}
    </Section>
  );
};

export default Grid;
