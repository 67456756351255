import React from 'react';
import './ScoreAnimation.scss';
import Typewriter from './TypeWriter';

export function ScoreAnimation({ mbadge, defiXp }) {
  return (
    <div className='score_animation'>
      <div className='entry'>
        <h2>
          MBADGE - <Typewriter text={`100 * ${mbadge} `} startDelay={0} delay={50} />
        </h2>
        <h2>
          DE-FI XP - <Typewriter startDelay={1000} delay={50} text={String(defiXp)} />{' '}
        </h2>
      </div>

      <h2>
        Total - <Typewriter text={`100 * ${mbadge} + ${defiXp} = ${mbadge * 100 + defiXp}`} delay={50} startDelay={1500} />
      </h2>
    </div>
  );
}
export default ScoreAnimation;
