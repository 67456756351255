import base from './base';

export default {
  primary: '#000',
  'primary-hover': '#000000af',
  secondary: '#fff',
  'secondary-hover': '#ffffff6e',

  'text-color': '#000',
  'text-color-hover': '#FE7119',
  'text-color-light': '#00000096',
  'text-color-xlight': 'rgba(0, 0, 0, 0.425)',
  'text-color-xxlight': 'rgba(0, 0, 0, 0.3)',

  'text-contrast': '#fff',

  'bg-color': '#fff',
  'bg-color-card': 'rgba(0, 0, 0, 0.055)',

  'box-shadow-color': 'rgba(0, 0, 0, 0.75)',

  'button-bg-color': '#e9e9ed',
  'button-bg-color-hover': '#d0d0d7',
  'button-bg-color-dark': '#d1d1d1',
  'button-bg-color-dark-hover': '#d1d1d1b6',

  'skeleton-base-color': '#f0f0f0',
  'skeleton-highlight-color': '#e0e0e0',

  'toggle-bg-color': 'rgba(0, 0, 0, 0.425)',

  ...base,
};
