import NftTemplates from '../../helpers/nftTemplates/nftTemplates';
import './NftPreview.scss';
import './PfpBuilderPreview.scss';

const PfpBuilderPreview = ({ className = '', selected }) => {
  const templateName = 'LayeredImageCard';

  const config = {
    imageUrlLayers: Object.values(selected)
      .sort((a, b) => a.value - b.value)
      .map(v => v.image),
  };

  return (
    <div className={`NftPreview-container PfpBuilderPreview-container ${className}`}>
      <iframe srcDoc={NftTemplates.membershipCard[templateName](config, {})} />
    </div>
  );
};

export default PfpBuilderPreview;
