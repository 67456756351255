import { useContext } from 'react';
import { walletContext, constants as wcc } from 'walletconnector';
import ButtonApi from '../ButtonApi';
import './LoginPanel.scss';

const buttonArr = [];
if (window.ethereum) {
  buttonArr.push({
    id: wcc.enums.ConnectorTypes.injected,
    name: `Metamask`,
    icon: '/walletIcon/Metamask.svg',
  });
}
buttonArr.push({
  id: wcc.enums.ConnectorTypes.walletConnect,
  name: `WalletConnect`,
  icon: '/walletIcon/WalletConnect.svg',
});

const LoginButton = ({ icon, name, onClick }) => {
  return (
    <ButtonApi className='loginOption' onClick={onClick}>
      <img className='icon' src={icon} />
      <h1>{name}</h1>
      <img src='/walletIcon/RightArrow.svg' />
    </ButtonApi>
  );
};

const LoginPanel = ({ onWalletConnect, onWalletConnectError }) => {
  const { connect } = useContext(walletContext);

  const loginUser = async connectorId => {
    try {
      await connect(connectorId);
      onWalletConnect();
    } catch (e) {
      console.error(e);
      onWalletConnectError();
    }
  };

  return (
    <>
      <h2>AUTHENTICATE YOURSELF</h2>
      <h1>Choose Wallet</h1>
      {buttonArr.map((c, i) => (
        <LoginButton key={i} onClick={() => loginUser(c.id)} {...c} />
      ))}

      <footer>
        <img src='/walletIcon/NoticeCircle.svg' />
        <p>
          Connecting your wallet gives you access to an exclusive NFT experience curated just for you. Privacy is our priority, and we will never track or store any personally
          identifiable information related to your account.
        </p>
      </footer>
    </>
  );
};

export default LoginPanel;
