import mixpanel from 'mixpanel-browser';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { WalletProvider, chains as allchains } from 'walletconnector';
import App from './App';
import Modal from './components/modal/Modal';
import ModalProvider from './components/modal/ModalContext';
import ThemeProvider, { useTheme } from './components/theme/Theme';
import './index.css';
import reportWebVitals from './reportWebVitals';
import GlobalStateProvider from './state/GlobalStateProvider';
import './styles/Animation.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));

window.mixpanel = mixpanel;
window.mixpanel.init('04802fa77265ea8c945709cd9e072f32');

alert = toast;

const chains = [allchains.mainnet, allchains.optimism, allchains.polygon, allchains.arbitrum, allchains.avalanche, allchains.tenet, allchains.zklink, allchains.scroll];

const ToastContainerThemed = () => {
  const [theme] = useTheme();

  return <ToastContainer position={window.innerWidth < 800 ? 'top-center' : 'bottom-right'} theme={theme} hideProgressBar={true} autoClose={1000} />;
};

root.render(
  <BrowserRouter>
    <WalletProvider chains={chains}>
      <ThemeProvider>
        <GlobalStateProvider>
          <ModalProvider>
            <App />
            <Modal />
            <ToastContainerThemed />
          </ModalProvider>
        </GlobalStateProvider>
      </ThemeProvider>
    </WalletProvider>
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
