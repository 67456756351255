import confetti from 'https://cdn.skypack.dev/canvas-confetti';
import { useEffect, useState } from 'react';
import { useMintHelper } from '../../../helpers/mintHelper';
import ButtonApi from '../../ButtonApi';
import CustomCursorSection from '../../customCursor/CustomCursor';
import AnimatedButton from '../../dragButton/Drag';
import NftPreview from '../../nft/NftPreview';
import { useTheme } from '../../theme/Theme';
import './MbadgeClaim.scss';
import ScoreAnimation from './Score/ScoreAnimation';
import Typewriter from './Score/TypeWriter';

function Banner1({ onNext, onPrev, data, total }) {
  const [checkEligible, setCheckEligible] = useState(false);
  const handleClick = () => {
    setCheckEligible(true);
    if (total?.level !== 'NONE') {
      confetti();
    }
  };
  return (
    <div className='banner_layout'>
      <div className='layout_1'>
        {checkEligible ? (
          <>
            {total?.level === 'NONE' ? (
              <>
                {' '}
                <img className='not_elig' src='https://dskhnex0aho1n.cloudfront.net/public/sad.svg' />
                <h1>Oops, better luck next time.</h1>
              </>
            ) : (
              <>
                <h1>
                  <Typewriter orange={false} text={'Congratulations ser! You are eligible for Hands of Support NFT.'} delay={30} />
                </h1>
                <button className='primary button' onClick={onNext}>
                  Continue
                </button>
              </>
            )}
          </>
        ) : (
          <>
            <h1>Check your eligibilty</h1>
            <button className='primary button' onClick={handleClick}>
              Check
            </button>
          </>
        )}
      </div>
    </div>
  );
}

function Banner2({ onNext, onPrev, data }) {
  return (
    <div className='banner_layout'>
      <div className='layout_1'>
        <h1>Are you ready to take a look at your Chapter 1 stats?</h1>
        <AnimatedButton text={'Yes'} onClick={onNext} />
      </div>
    </div>
  );
}

function Banner3({ onNext, onPrev, data }) {
  const calculatePercent = mbadges => {
    if (mbadges > 40) {
      return 1.2;
    } else if (mbadges > 30) {
      return 2.1;
    } else if (mbadges > 20) {
      return 5;
    } else if (mbadges >= 3) {
      return 15;
    }
  };
  return (
    <div className='banner_layout'>
      <div className='layout_2'>
        <CustomCursorSection classname={'section_1'} text={'Previous'} cursorClassName={'cursor_class'} onClick={onPrev}>
          <h1>Collected MBADGEs</h1>
          <h2>
            You are in the top <span className='orange_color'>{calculatePercent(data?.totalMbadgeCount)}% </span> of MBADGE collectors!
          </h2>
        </CustomCursorSection>
        <CustomCursorSection classname={'section_2'} text={'Next'} cursorClassName={'cursor_class'} onClick={onNext}>
          <div className='star_svg'>
            <svg width='179' height='177' viewBox='0 0 179 177' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M120.557 2.46531C123.042 -0.716405 128.14 1.36751 127.684 5.37868L122.249 53.232C121.978 55.6209 123.86 57.7078 126.264 57.6832L174.423 57.1923C178.459 57.1512 180.009 62.4362 176.589 64.5809L135.787 90.1675C133.75 91.4449 133.292 94.2177 134.81 96.082L165.22 133.428C167.769 136.558 164.603 141.065 160.794 139.728L115.35 123.781C113.081 122.984 110.628 124.355 110.117 126.705L99.8789 173.765C99.0208 177.71 93.5235 178.044 92.1936 174.232L76.3278 128.76C75.5358 126.49 72.9344 125.426 70.7789 126.491L27.6026 147.829C23.9835 149.617 20.2946 145.528 22.4454 142.112L48.1052 101.356C49.3862 99.321 48.5957 96.6241 46.4189 95.6031L2.81661 75.1502C-0.838227 73.4358 0.0590454 68.002 4.07102 67.5536L51.9341 62.2042C54.3235 61.9371 55.9391 59.6376 55.3802 57.2992L44.1853 10.4573C43.2469 6.53094 48.0547 3.84451 50.9067 6.70164L84.9311 40.7872C86.6297 42.4888 89.4349 42.3182 90.9147 40.4232L120.557 2.46531Z'
                fill='#F6D31D'
              />
            </svg>
          </div>
          <h1>{data?.totalMbadgeCount || 0}</h1>
          <div className='tap_to_next'>
            Tap to go next
          </div>
        </CustomCursorSection>
      </div>
    </div>
  );
}

function Banner4({ onNext, onPrev, data }) {
  const [theme] = useTheme();

  function fetchLine(count) {
    if (count > 3) {
      return 'You are an explorer, journeying through multiple chains of Web3!';
    }
    return 'You seem like a person who loves sticking to certain chains.';
  }
  return (
    <div className='banner_layout'>
      <div className='layout_2'>
        <CustomCursorSection classname={'section_1'} text={'Previous'} cursorClassName={'cursor_class'} onClick={onPrev}>
          <div>
            <h1>Chains Explored</h1>
            <p>{fetchLine(data?.uniqueChains?.count || 0)}</p>
          </div>
          <div className='chains_container'>
            {Array.isArray(data?.uniqueChains?.chainIds) &&
              data?.uniqueChains?.chainIds?.map(chainId => <img src={`https://dskhnex0aho1n.cloudfront.net/public/chain_${chainId}.svg`} />)}
          </div>
        </CustomCursorSection>
        <CustomCursorSection
          classname={'section_2'}
          text={'Next'}
          cursorClassName={'cursor_class cursor_negative'}
          background={theme == 'dark' ? 'white' : '#000'}
          onClick={onNext}
        >
          <h1 style={{ color: theme == 'dark' ? 'black' : 'white' }}>{data?.uniqueChains?.count || 0}</h1>
          <div className='tap_to_next' style={{ color: theme == 'dark' ? 'black' : 'white' }} >
            Tap to go next
          </div>
        </CustomCursorSection>
      </div>
    </div>
  );
}

function Banner5({ onNext, onPrev, data }) {
  const [theme] = useTheme();
  return (
    <div className='banner_layout'>
      <div className='layout_2'>
        <CustomCursorSection classname={'section_1'} text={'Previous'} cursorClassName={'cursor_class'} onClick={onPrev}>
          <img src={`https://dskhnex0aho1n.cloudfront.net/public/crosses_${theme}.svg`} />
          <div>
            <h1>Attestations Received</h1>
            <p>These are EIP-712 standard attestations, signifying proof of contributions.</p>
          </div>
        </CustomCursorSection>
        <CustomCursorSection classname={'section_2'} text={'Next'} cursorClassName={'cursor_class'} background={'#F0C900'} onClick={onNext}>
          <h1 style={{ color: 'blue' }}>{data?.totalAttestationCount || 0}</h1>
          <div className='tap_to_next'>
            Tap to go next
          </div>
        </CustomCursorSection>
      </div>
    </div>
  );
}

function Banner6({ onNext, onPrev, data }) {
  const [theme] = useTheme()
  return (
    <div className='banner_layout'>
      <div className='layout_2'>
        <CustomCursorSection classname={'section_1'} text={'Previous'} cursorClassName={'cursor_class'} onClick={onPrev}>
          <div>
            <h1>Total DeFi XP Collected</h1>
            <p>DeFi XPs are the points that you earned by completing onchain actions and providing data liquidity to Mercle Network.</p>
          </div>
          <img src={`https://dskhnex0aho1n.cloudfront.net/public/crosses_${theme}.svg`} />
        </CustomCursorSection>
        <CustomCursorSection classname={'section_2'} text={'Next'} cursorClassName={'cursor_class'} background={'blue'} onClick={onNext}>
          <h1>{data?.totalDefiXp || 0}</h1>
          <div className='tap_to_next'>
            Tap to go next
          </div>
        </CustomCursorSection>
      </div>
    </div>
  );
}

function Banner7({ onNext, onPrev, data }) {
  const [campaigns, setCampaigns] = useState([]);

  useEffect(() => {
    if (data?.camapaignsByXpEarned) {
      setCampaigns(data?.camapaignsByXpEarned?.slice(0, 3));
    }
  }, [data?.camapaignsByXpEarned]);
  return (
    <div className='banner_layout'>
      <div className='layout_2'>
        <CustomCursorSection classname={'section_1'} text={'Previous'} cursorClassName={'cursor_class'} onClick={onPrev}>
          <h1 className='small_text'>MBADGEs that got you the most XP—</h1>
          <div className='claimed_attestation'>
            {campaigns?.map((item, index) => (
              <h3>
                {' '}
                {item?.name} got you {item?.value} XP
              </h3>
            ))}
          </div>
        </CustomCursorSection>
        <CustomCursorSection classname={'section_2'} text={'Next'} cursorClassName={'cursor_class'} onClick={onNext}>
          <div className='inner align_center'>
            {campaigns?.map((item, index) => (
              <div className={`badges ${index % 2 != 0 && 'margin_right'}`}>{String(item?.communityName).toUpperCase()}</div>
            ))}
          </div>
          <div className='tap_to_next'>
            Tap to go next
          </div>
        </CustomCursorSection>
      </div>
    </div>
  );
}
function Banner8({ onNext, onPrev, data }) {
  const [theme] = useTheme();
  return (
    <div className='banner_layout'>
      <CustomCursorSection classname={'layout_3'} text={'Next'} cursorClassName={'cursor_class cursor_negative'} background={theme == 'dark' ? 'white' : '#000'} onClick={onNext}>
        <h1 className='famouns_attestation'>-{data?.famousAttestationCollected?.name}</h1>
        <h2>This was the most famous attestation that you have collected.</h2>
        <div className='arrow_svg'>
          <svg width='233' height='233' viewBox='0 0 233 233' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M0 1.01848e-05H20V233H0V1.01848e-05Z' fill='#FE7119' />
            <path d='M0 20V1.01848e-05L233 0V20L0 20Z' fill='#FE7119' />
            <path d='M6 20.1426L20.1421 6.00045L231.306 217.165L217.164 231.307L6 20.1426Z' fill='#FE7117' />
          </svg>
        </div>
      </CustomCursorSection>
    </div>
  );
}

function Banner9({ onNext, onPrev, data }) {
  return (
    <div className='banner_layout'>
      <div className='layout_1'>
        <h1>And your persona attribute on Mercle says that....</h1>
        <AnimatedButton text={'Continue'} onClick={onNext} />
        <div className='circle_1'>
          <svg xmlns='http://www.w3.org/2000/svg' width='48' height='48' viewBox='0 0 48 48' fill='none'>
            <circle cx='24.3672' cy='23.8809' r='23.4375' fill='#F6D31D' />
          </svg>
        </div>
        <div className='circle_2'>
          <svg xmlns='http://www.w3.org/2000/svg' width='97' height='97' viewBox='0 0 97 97' fill='none'>
            <circle cx='48.0195' cy='48.5352' r='48.0039' fill='#FF5506' />
          </svg>
        </div>
        <div className='circle_3'>
          <svg xmlns='http://www.w3.org/2000/svg' width='80' height='80' viewBox='0 0 80 80' fill='none'>
            <circle cx='40.364' cy='40.4187' r='39.5359' fill='#0048FF' />
          </svg>
        </div>
      </div>
    </div>
  );
}

function Banner10({ onNext, onPrev, data }) {
  const [theme] = useTheme();
  return (
    <div className='banner_layout'>
      <div className='layout_2'>
        <CustomCursorSection classname={'section_1'} text={'Previous'} cursorClassName={'cursor_class'} onClick={onPrev}>
          <h1 className='chain_count'>
            You have high number of <span className='orange_color'>{data?.mostDoneTask?.name || 'SWAP'}</span> attestations in your reputation profile
          </h1>
          <img src={`https://dskhnex0aho1n.cloudfront.net/public/crosses_${theme}.svg`} />
        </CustomCursorSection>
        <CustomCursorSection classname={'section_2'} text={'Next'} cursorClassName={'cursor_class'} onClick={onNext}>
          <h1 className='most_done'>{data?.mostDoneTask?.name || 'SWAP'}</h1>
          <div className='tap_to_next'>
            Tap to go next
          </div>
        </CustomCursorSection>
      </div>
    </div>
  );
}

function Banner11({ onNext, onPrev, data }) {
  const [theme] = useTheme();
  return (
    <div className='banner_layout'>
      <CustomCursorSection classname={'layout_3'} text={'Next'} cursorClassName={'cursor_class cursor_negative'} background={theme == 'dark' ? 'white' : '#000'} onClick={onNext}>
        <h1 className='shine_like'>Shine like your Hands of Support</h1>
        <div className='arrow_svg'>
          <svg xmlns='http://www.w3.org/2000/svg' width='97' height='96' viewBox='0 0 97 96' fill='none'>
            <circle cx='48.4857' cy='48.1481' r='33.883' transform='rotate(41.3458 48.4857 48.1481)' fill='#FF5506' />
            <line x1='75.467' y1='41.5394' x2='64.1064' y2='44.1195' stroke='#FBF7F4' stroke-width='2' stroke-linecap='round' />
          </svg>
        </div>
        <div className='blue_star'>
          <svg xmlns='http://www.w3.org/2000/svg' width='324' height='323' viewBox='0 0 324 323' fill='none'>
            <path
              d='M223.623 318.456C223.88 322.485 218.685 324.315 216.361 321.014L160.344 241.483C158.959 239.518 156.166 239.209 154.385 240.825L82.3472 306.197C79.3577 308.91 74.6883 305.989 75.8193 302.114L103.073 208.732C103.747 206.424 102.246 204.047 99.8731 203.663L3.84785 188.1C-0.137098 187.454 -0.76512 181.983 2.96987 180.451L92.9716 143.535C95.196 142.623 96.1187 139.968 94.9396 137.873L47.2363 53.0943C45.2567 49.5761 49.1429 45.6737 52.6694 47.6386L137.646 94.9885C139.746 96.1588 142.397 95.225 143.3 92.9968L179.84 2.84219C181.357 -0.899143 186.831 -0.293892 187.493 3.68834L203.456 99.6479C203.85 102.02 206.233 103.51 208.538 102.827L301.806 75.1844C305.677 74.0373 308.616 78.6944 305.916 81.6952L240.844 154.005C239.236 155.792 239.556 158.584 241.528 159.96L321.291 215.646C324.601 217.957 322.793 223.159 318.763 222.918L221.658 217.128C219.258 216.985 217.275 218.976 217.428 221.375L223.623 318.456Z'
              fill='#0048FF'
            />
          </svg>
        </div>
        <div className='tap_to_next'>
            Tap to go next
          </div>
      </CustomCursorSection>
    </div>
  );
}

function Banner12({ onNext, onPrev, data, total }) {
  const [showScore, setShowScore] = useState(false);
  const [mbadge, setMbadge] = useState(data?.totalMbadgeCount || 0);
  const [defiXp, setDefiXp] = useState(data?.totalDefiXp || 0);

  const handleClick = () => {
    if (showScore) {
      onNext();
    } else {
      setShowScore(true);
    }
  };

  return (
    <div className='banner_layout'>
      <div className='layout_2'>
        <CustomCursorSection classname={'section_1'} text={showScore ? 'Reveal NFT' : 'Get Score'} cursorClassName={'cursor_class'} onClick={handleClick}>
          <h1>Your SHINE Score</h1>
          {showScore ? <ScoreAnimation mbadge={total?.currentLevel?.mbadges || 0} defiXp={total?.currentLevel?.defiXP || 0} /> : <h2>Click anywhere to get Shine Score</h2>}
        </CustomCursorSection>
        <CustomCursorSection classname={'section_2'} text={showScore ? 'Reveal NFT' : 'Get Score'} cursorClassName={'cursor_class '} onClick={handleClick}>
          <div className='inner'>
            <h1>{showScore && <Typewriter startDelay={3000} text={String(total?.points || 0)} orange={false} delay={100} />}</h1>
            <h3>{showScore && <Typewriter startDelay={3500} text={`Reveal Your ${total?.level}  NFT`} orange={false} delay={50} />}</h3>
          </div>
          <div className='tap_to_next'>
            Tap to Reveal
          </div>
        </CustomCursorSection>
      </div>
    </div>
  );
}

function Banner13({ COMMUNITY_NAME, state, total, getNftProfile }) {
  const mintHelper = useMintHelper();

  function refresNftProfile() {
    getNftProfile();
  }

  async function mintNFT() {
    if (total?.level === 'NONE') return;
    await mintHelper.mint(COMMUNITY_NAME, state?.campaign, '', null, refresNftProfile);
  }

  if (total?.level === 'NONE') return;
  return (
    <div className='banner_layout'>
      <div className='layout_1'>
        <div className='preview_container'>
          <NftPreview className='nft' src={state?.nftProfile?.animation_url} template={state?.campaign?.template} />
          <div className='nft_action'>
            <div className='button_container'>
              <h2>{state?.campaign?.name}</h2>
              <p>{state?.campaign?.description}</p>
              <h3>Shine Points - {total?.points}</h3>
            </div>
            <div className='button_container'>
              <ButtonApi className='primary' onClick={mintNFT}>
                Mint on Base
              </ButtonApi>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export const MbadgeBanner = [Banner1, Banner2, Banner3, Banner4, Banner5, Banner6, Banner7, Banner8, Banner9, Banner10, Banner11, Banner12, Banner13];
