import { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useBackendApi } from './backendHelper';

export const useQuizHelper = () => {
  const backendApi = useBackendApi();
  const [quizResult, setQuizResult] = useState();
  const [quiz, setQuiz] = useState();
  const location = useLocation();
  const communityName = location.pathname.split('/')?.[1];
  const [loading, setLoading] = useState(false);

  const fetchQuizResult = useCallback(
    eventItem => {
      backendApi.communities.tokens.events
        .getQuizResult(communityName, eventItem.campaign, eventItem.quiz.quizId)
        .then(result => {
          if (!result) return setQuizResult(null);
          setQuizResult(result);
        })
        .catch(e => {
          console.error(e);
        });
    },
    [backendApi, setQuizResult],
  );
  const fetchQuiz = useCallback(
    eventItem => {
      backendApi?.communities?.tokens?.events
        .getQuiz(communityName, eventItem.campaign, eventItem.quiz.quizId)
        .then(response => {
          setQuiz(response);
        })
        .catch(e => {
          console.error('Could not get quiz', e);
        });
    },
    [setQuiz, backendApi],
  );
  const onQuizSubmit = async (selected, eventItem) => {
    if (loading) return;
    try {
      setLoading(true);
      const quizAnswerRes = await backendApi?.communities?.tokens?.events?.submitQuizAnswer(communityName, eventItem?.campaign, {
        quizId: eventItem?.quiz?.quizId,
        answers: Object.values(selected),
      });
      setQuizResult(quizAnswerRes);
    } catch (e) {
      console.error(e);
      alert.error('Could not submit answer');
    } finally {
      setLoading(false);
    }
  };

  return { quiz, quizResult, fetchQuizResult, fetchQuiz, onQuizSubmit, setQuizResult };
};
