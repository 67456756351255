import { useState } from 'react';
import { createContainer } from 'react-tracked';

const useModalState = () => {
  const [state, setState] = useState({ isOpen: false, contentConfig: <></> });
  const defaultConfig = {
    showBackButton: true,
    hideNavbar: false,
    className: '',
    classNameContent: '',
    content: <></>,
    toolbarActionButton: undefined,
    onClose: () => {},
  };

  const setIsOpen = v => setState(s => ({ ...s, isOpen: v }));
  const setContentConfig = v => setState(s => ({ ...s, contentConfig: v }));

  const openModal = (contentConfig = defaultConfig) => {
    setContentConfig({ ...defaultConfig, ...contentConfig });
    setIsOpen(true);
  };

  /**
   * starts exit animation
   */
  const closeModal = () => {
    setIsOpen(false);
    if (state.contentConfig?.onClose) {
      state.contentConfig?.onClose();
    }
  };

  /**
   * clears content of panel and dispatches close event after exit animation is completed
   */
  const clearModal = () => {
    setContentConfig(<></>);
  };

  const updateTitleAndDescription = (title, description) => {
    setContentConfig({ ...state.contentConfig, title, description });
  };

  return [
    {
      ...state,
      isOpen: state.isOpen,
      contentConfig: state.contentConfig,
      openModal,
      closeModal,
      clearModal,
      updateTitleAndDescription,
    },
    setState,
  ];
};

const { Provider, useTracked } = createContainer(() => useModalState());

export const useModal = useTracked;
export const ModalProvider = ({ children }) => {
  return <Provider>{children}</Provider>;
};

export default ModalProvider;
