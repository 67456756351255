import { Link } from 'react-router-dom';
import { useWindowSize } from 'usehooks-ts';
import GreenTick from '../../../../components/GreenTick';
import Section from '../../../../components/Section';
import { CardsLoader } from '../../../../components/Skeleton/CardsLoader';
import { useRaffle } from '../RaffleProvider';
import './RaffleTasks.scss';

const TaskCardMobile = ({ task, isCompleted }) => {
  return (
    <div className='TaskCard-container mobile'>
      <h1>{task?.taskTitle}</h1>
      <div className='buttons'>
        <h1 className='points'>{task?.taskSubtitle}</h1>
        {isCompleted ? (
          <GreenTick />
        ) : (
          <Link className='button primary' to={`/${task.communityName}/event`}>
            Collect now
          </Link>
        )}
      </div>
    </div>
  );
};

const TaskCard = ({ task }) => {
  const [{ taskComplete }] = useRaffle();
  const { width } = useWindowSize();
  const isCompleted = taskComplete?.taskIdTaskComplete?.[task.id];

  if (width < 600) return <TaskCardMobile task={task} isCompleted={isCompleted} />;

  return (
    <div className='TaskCard-container'>
      <h1 className='ellipse'>{task?.taskTitle}</h1>
      <div className='buttons'>
        <h1 className='points'>{task?.taskSubtitle}</h1>
        {isCompleted ? (
          <GreenTick />
        ) : (
          <Link className='button primary' to={`/${task.communityName}/event`}>
            Collect now
          </Link>
        )}
      </div>
    </div>
  );
};

const RaffleTasks = () => {
  const [{ raffle }] = useRaffle();
  const tasks = raffle?.taskSection?.sectionTasks;

  if (tasks === undefined) return <CardsLoader rows={5} columns={1} columnHeight={60} />;
  if (!tasks?.length) return <></>;
  return (
    <Section className='RaffleTasks-container' title={'How to participate?'}>
      {tasks?.map((v, i) => (
        <TaskCard key={i} task={v} />
      ))}
    </Section>
  );
};

export default RaffleTasks;
