import { apis, values } from '../constants';

export const getFile = async api => {
  return await _handleLogout(_getFile, api);
};

export const get = async api => {
  return await _handleLogout(_get, api);
};

export const post = async (api, body) => {
  return await _handleLogout(_post, api, body);
};

export const del = async (api, body) => {
  return await _handleLogout(_del, api, body);
};

export const put = async (api, body) => {
  return await _handleLogout(_put, api, body);
};

export const putFormData = async (api, fd) => {
  return await _handleLogout(_putFormData, api, fd);
};

export const postFormDataWithoutHeaders = async (api, body) => {
  return await _handleLogout(_postFormDataWithoutHeaders, api, body);
};

const headers = () => {
  return {
    'content-type': 'application/json',
  };
};

const headersFormData = () => {
  return {};
};

const _handleLogout = async (apiFunc, api, body, retryCount = 0) => {
  try {
    switch (retryCount) {
      case 0:
        // no-op
        break;
      case 1:
        // refresh access token
        // todo check if ref token is valid first
        await _post(apis.backend.refreshAccessToken());
        break;
      case 2:
        // relogin
        // if (!document.hidden) {
        //   await loginHelper.waitForLoginInvasive();
        // } else {
        //   return undefined;
        // }
        throw new Error('user not logged in');
        break;
      default:
        return alert.error('Something went wrong, please try again');
    }
    return await apiFunc(api, body);
  } catch (e) {
    console.error(e);
    if (e.message == 401) {
      return await _handleLogout(apiFunc, api, body, retryCount + 1);
    }
    // alert.error(resources.strings.errors.error(e.message));
    throw e;
  }
};

export const _getVanillan = async api => {
  const res = await fetch(api, {
    method: 'get',
  });
  return await _checkTokenGate(res);
};

export const _getFile = async api => {
  const res = await fetch(api, {
    method: 'get',
    headers: headers(),
    credentials: 'include',
  });

  return await res.blob();
};

export const _get = async api => {
  const res = await fetch(api, {
    method: 'get',
    headers: headers(),
    credentials: 'include',
  });
  return await _checkTokenGate(res);
};

export const _post = async (api, body) => {
  const res = await fetch(api, {
    method: 'post',
    headers: headers(),
    credentials: 'include',
    body: JSON.stringify(body || {}),
  });
  return await _checkTokenGate(res);
};

export const _del = async (api, body) => {
  const res = await fetch(api, {
    method: 'delete',
    headers: headers(),
    credentials: 'include',
    body: JSON.stringify(body || {}),
  });
  return await _checkTokenGate(res);
};

export const _put = async (api, body) => {
  const res = await fetch(api, {
    method: 'put',
    headers: headers(),
    credentials: 'include',
    body: JSON.stringify(body || {}),
  });
  return await _checkTokenGate(res);
};

export const _putFormData = async (api, body) => {
  const res = await fetch(api, {
    method: 'put',
    headers: headersFormData(),
    credentials: 'include',
    body: body,
  });
  return await _checkTokenGate(res);
};

export const _postFormDataWithoutHeaders = async (api, body) => {
  const res = await fetch(api, {
    method: 'post',
    headers: headersFormData(),
    credentials: 'include',
    body: body,
  });
  return await _checkTokenGate(res);
};

const _checkTokenGate = async res => {
  let body;
  if (!res.ok) {
    if (res.status == 403) {
      body = await _getResponseBody(res);
      if (body.status == values.errors[403].tokenGate.status) return values.errors[403].tokenGate;
    }
    let err = await _getResponseBody(res);
    throw new Error(err?.message || res?.status);
  }
  return body || _getResponseBody(res);
};

const _getResponseBody = async res => {
  try {
    return await res.json();
  } catch (e) {
    return undefined;
  }
};

export default {
  getFile,
  get,
  post,
  put,
  del,
  putFormData,
};
