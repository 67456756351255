import { useState } from "react";

import "./communityCreate.scss";
import { useCommunityHelper } from "../../../helpers/communityHelper";
import { useGlobalState } from "../../../state/GlobalStateProvider";
import { useLoginHelper } from "../../../helpers/login/loginHelper";
import { resources } from "../../../constants";
import Input from "../components/Input";
import AfterLoggedIn from "../../../components/login/AfterLoggedIn";

const CommunityCreate = () => {
  const [{ user }] = useGlobalState();
  const { createCommunity } = useCommunityHelper();
  const [communityName, setCommunityName] = useState();

  const updateCommunityName = (e) => {
    const max = 30;
    let value = e.target.value.replaceAll(/[^a-z0-9_]/g, "");
    value = value.length > max ? value.substring(0, max) : value;
    setCommunityName(value);
  };
  const onNext = async () => {
    await createCommunity();
  };

  return (
    <AfterLoggedIn>
      <div className="communitycreate-container">
        <div className="content">
          <img className="logo" alt="" src={resources.img.logo} />
          <h1>Create community</h1>
          <Input
            label=""
            id="id.input.onboarding.createCommunity.name"
            placeholder="Community Domain"
            value={communityName}
            onChange={updateCommunityName}
          />
          <br />
          <Input label="" id="id.input.onboarding.createCommunity.dispalyName" placeholder="Community Name" />
          <div className="text-center">
            <button className="button-gradient" onClick={onNext}>
              Create
            </button>
          </div>
        </div>
      </div>
    </AfterLoggedIn>
  );
};

export default CommunityCreate;
