import React, { useEffect, useState } from 'react';
import './CustomCursor.scss';

const CustomCursorSection = ({ classname, children, cursorClassName, text, background, onClick }) => {
  const [cursorPos, setCursorPos] = useState({ x: 0, y: 0 });
  const [isInside, setIsInside] = useState(false);

  useEffect(() => {
    const handleMouseMove = e => {
      const section = e.currentTarget;
      const rect = section.getBoundingClientRect();
      setCursorPos({ x: e.clientX - rect.left, y: e.clientY - rect.top });
    };

    const handleMouseEnter = () => {
      setIsInside(true);
    };

    const handleMouseLeave = () => {
      setIsInside(false);
    };

    const sections = document.querySelectorAll(`.${classname}`);
    sections.forEach(section => {
      section.addEventListener('mousemove', handleMouseMove);
      section.addEventListener('mouseenter', handleMouseEnter);
      section.addEventListener('mouseleave', handleMouseLeave);
    });

    return () => {
      sections.forEach(section => {
        section.removeEventListener('mousemove', handleMouseMove);
        section.removeEventListener('mouseenter', handleMouseEnter);
        section.removeEventListener('mouseleave', handleMouseLeave);
      });
    };
  }, [classname]);

  return (
    <div className={`CustomCursorSection ${classname}`} style={{ background }} onClick={onClick}>
      {isInside && (
        <div className={`custom-cursor ${cursorClassName}`} style={{ top: `${cursorPos.y}px`, left: `${cursorPos.x}px` }}>
          {text}
        </div>
      )}
      {children}
    </div>
  );
};

export default CustomCursorSection;
