import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useWindowSize } from 'usehooks-ts';
import ButtonApi from '../../../components/ButtonApi';
import CollapsingDivContainer from '../../../components/CollapsingDiv';
import GreenTick from '../../../components/GreenTick';
import Section from '../../../components/Section';
import { useQuizHelper } from '../../../helpers/quizHelper';
import { useTaskHelper } from '../../../helpers/taskHelper';
import { useGlobalState } from '../../../state/GlobalStateProvider';
import utils from '../../../utils';
import { useEvent } from './EventProvider';
import './Level.scss';

const TaskActionButtons = ({ task }) => {
  const [{ userIdentity }] = useGlobalState();
  const [{ event, nftProfile }] = useEvent();
  const taskHelper = useTaskHelper();
  const [isLoading, setIsLoading] = useState(false);
  const { fetchQuiz, fetchQuizResult, quizResult, quiz } = useQuizHelper(task);
  useEffect(() => {
    if (task.type === 'quiz') {
      fetchQuiz(task);
      fetchQuizResult(task);
    }
  }, [task]);
  const { xpThreshold, isLocked, onVerify, onAction } = useMemo(() => {
    if (!task?.rewards || !nftProfile) return {};

    const currentXp = nftProfile.properties?.XP?.value;
    const xpThreshold = event.eventNames[task.levelId]?.xpThreshold;
    const isLocked = xpThreshold > currentXp;

    const onVerify = async () => {
      setIsLoading(true);
      try {
        await taskHelper.verify(task);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
      }
    };
    const onAction = () => taskHelper.action(task);

    return { xpThreshold, isLocked, onVerify, onAction };
  }, [task?.rewards, nftProfile]);

  const futureTime = new Date(Number(localStorage.getItem(task.id))).getTime();

  if (!nftProfile) return <></>;
  if (isLocked) return <h1 className='points'>🔒 {xpThreshold}XP required</h1>;
  if (futureTime && Date.now() < futureTime && (task.type === 'twitterRetweet' || task.type === 'connectTwitter'))
    return (
      <ButtonApi className='secondary' isLoading={true}>
        Verifying (~2-3 mins)
      </ButtonApi>
    );
  if (userIdentity?.claims?.[utils.bsonIdToBytes12(task?.id)] && !task?.recurringTask) return <GreenTick />;
  if (task.type === 'quiz' && !(quiz?.retry && (quizResult?.retryCount || 0) < quiz.retry)) {
    return <button className='quiz-disabled'>{`${quiz?.retry ?? 0}/${quizResult?.retryCount ?? 0}  Reached`} </button>;
  }
  return (
    <>
      {task.type === 'twitterRetweet' || task.type === 'connectTwitter' ? (
        <button onClick={onVerify} className='ButtonApi-container secondary' isLoading={isLoading} disabled={isLoading}>
          {isLoading ? (
            <>
              'Verifying (~ 2-3 mins)'<div className='spinner'></div>
            </>
          ) : (
            'Verify'
          )}
        </button>
      ) : (
        <ButtonApi className='secondary' onClick={onVerify}>
          Verify
        </ButtonApi>
      )}

      <ButtonApi className='primary' onClick={onAction}>
        Go To Action
      </ButtonApi>
    </>
  );
};

const TaskCardMobile = ({ task, hasAsset, xp, xpType }) => {
  return (
    <div className='TaskCard-container mobile'>
      <header>
        <h1>{task.name}</h1>
        <p className='description'>{task.description}</p>
        <div className='buttons'>
          {hasAsset ? <h1 className='points'>ASSETS</h1> : <></>}
          {xp > 0 ? (
            <h1 className='points'>
              {xp} {xpType}
            </h1>
          ) : (
            <></>
          )}
        </div>
        <div className='buttons'>
          <TaskActionButtons task={task} />
        </div>
      </header>
    </div>
  );
};

const TaskCard = ({ task, ref, highlightedTaskId }) => {
  const [{ nftProfile }] = useEvent();
  const { width } = useWindowSize();
  const { xp, xpType, hasAsset } = useMemo(() => {
    if (!task?.rewards) return {};

    const xpType = task.rewards?.[0]?.items?.[0]?.value?.toUpperCase();
    const xp = task.rewards?.[0]?.items?.[0]?.xp;
    const hasAsset = !!task.rewards?.[1]?.items?.[0];

    return { xp, xpType, hasAsset };
  }, [task?.rewards, nftProfile]);
  if (task?.[task?.type]?.isHidden) return <></>;
  if (width < 600) return <TaskCardMobile task={task} hasAsset={hasAsset} xp={xp} xpType={xpType} />;

  return (
    <CollapsingDivContainer
      className={`TaskCard-container ${task?.id === highlightedTaskId && 'highlight'}`}
      ref={ref}
      header={
        <>
          <h1 className='ellipse' id={task?.id}>
            {task.name}
          </h1>
          <div className='buttons'>
            {hasAsset ? <h1 className='points'>ASSETS</h1> : <></>}
            {xp > 0 ? (
              <h1 className='points'>
                {xp} {xpType}
              </h1>
            ) : (
              <></>
            )}
            <TaskActionButtons task={task} />
          </div>
        </>
      }
      content={
        <div className='task-content'>
          <h1>Description</h1>
          <p className='description'>{task.description}</p>
          <h1>Guide</h1>
          <ol>
            {task.guide?.map((g, i) => (
              <li key={i}>{g}</li>
            ))}
          </ol>
        </div>
      }
    />
  );
};

const Level = ({ title, levelId }) => {
  const location = useLocation();
  let highlightedTaskId = location?.hash?.slice(1);
  const [{ event }] = useEvent();
  const taskRefs = useRef({});
  const tasks = useMemo(() => {
    if (!event?.events?.length) return [];

    return event.events.filter(task => task.levelId == levelId);
  }, [event?.events]);
  useEffect(() => {
    if (highlightedTaskId && taskRefs.current[highlightedTaskId]) {
      taskRefs.current[highlightedTaskId].scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [highlightedTaskId]);
  return (
    <Section className='Level-container' title={title}>
      {tasks?.map((v, i) => (
        <TaskCard key={i} task={v} ref={el => (taskRefs.current[v?.id] = el)} highlightedTaskId={highlightedTaskId} />
      ))}
    </Section>
  );
};

export default Level;
