import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { id } from '../constants';
import { useBackendApi } from '../helpers/backendHelper';
import { Spinner } from './loaders/Spinner';

const ReferralTracker = () => {
  const { code } = useParams();
  const navigate = useNavigate();
  const backend = useBackendApi();

  const registerReferredUser = async code => {
    try {
      const body = {
        code,
      };

      // Will throw if user isnt signed in
      await backend.user.useReferralCode(body);

      // Remove the code if call succeeds
      localStorage.removeItem(id.storage.referralCode);
      return true;
    } catch (error) {
      console.log(error);
    }
  };
  const getData = async () => {
    try {
      const res = await backend.user.getReferralRedirectUrl(code);
      if (!res) navigate('/');

      localStorage.setItem(id.storage.referralCode, code);
      await registerReferredUser(code);
      navigate(res.url, { replace: true });
    } catch (error) {
      navigate('/');
      console.log('error', error);
    }
  };

  useEffect(() => {
    getData();
  }, [code, navigate]);

  return <Spinner size={50} text={'Loading...'} />;
};

export { ReferralTracker };
