import React, { useEffect, useState } from 'react';
import { useBackendApi } from '../../helpers/backendHelper';
import { useQuizHelper } from '../../helpers/quizHelper';
import ButtonApi from '../ButtonApi';
import { Spinner } from '../loaders/Spinner';
import './Quiz.scss';
import QuizResult from './QuizResult';
const OptionItem = ({ option, text, isSelected, onClick }) => {
  const optionArr = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O'];

  return (
    <div className={`option ${isSelected && 'selected'}`} onClick={onClick}>
      <div className='optionCircle'>{optionArr[option]}</div>
      {text}
    </div>
  );
};
const Quiz = ({ eventItem }) => {
  const backendApi = useBackendApi();
  const [currentQuestion, setCurrentQuestion] = useState(0);

  const [selected, setSelected] = useState({});

  const { quiz, setQuizResult, quizResult, fetchQuiz, fetchQuizResult, onQuizSubmit } = useQuizHelper(eventItem);
  const handleSelectOption = index => {
    setSelected({ ...selected, [currentQuestion]: index });
  };
  const handleQuestionChange = isAdd => {
    if (isAdd) {
      if (quiz?.qna?.length > currentQuestion) {
        setCurrentQuestion(currentQuestion + 1);
      }
    } else {
      if (currentQuestion > 0) {
        setCurrentQuestion(currentQuestion - 1);
      }
    }
  };
  useEffect(() => {
    if (eventItem) {
      fetchQuiz(eventItem);
      fetchQuizResult(eventItem);
    }
  }, [eventItem]);
  const retry = () => {
    setQuizResult(null);
    setCurrentQuestion(0);
    setSelected({});
  };
  if (!quiz) {
    return (
      <div className='quizresult_container'>
        <Spinner />
      </div>
    );
  }
  if (quizResult) {
    return <QuizResult onRetry={retry} quizResult={quizResult} quiz={quiz} />;
  }
  return (
    <div className='Quiz_container'>
      <div className='panel'>
        <div className='describe'>{quiz?.qna?.[currentQuestion]?.q}</div>
        <div className='optionBox'>
          {quiz?.qna?.[currentQuestion]?.o?.map((e, idx) => (
            <OptionItem {...e} option={idx} text={e} isSelected={selected[currentQuestion] === idx} onClick={() => handleSelectOption(idx)} />
          ))}
        </div>
        <div className='action_buttons'>
          <ButtonApi className='secondary prevbtn' disabled={currentQuestion === 0} onClick={() => handleQuestionChange(false)}>
            Prev Ques
          </ButtonApi>
          {quiz?.qna?.length - 1 <= currentQuestion ? (
            <ButtonApi className='primary' onClick={() => onQuizSubmit(selected, eventItem)}>
              Submit
            </ButtonApi>
          ) : (
            <ButtonApi onClick={() => handleQuestionChange(true)} className='primary'>
              Next Question
            </ButtonApi>
          )}
        </div>
      </div>
    </div>
  );
};

export default Quiz;
