import './Section.scss';
import { Toggle } from './toggle/Toggle';

const Section = ({ title, children, className = '', style = {}, sectionAction, toggle }) => {
  return (
    <>
      <div className='section-header-container'>
        {title ? <h1 className={`${className?.replaceAll(' ', '-')}-title sectionTitle`}>{title}</h1> : <></>}
        {sectionAction ? (
          <button className='section-header-action' onClick={sectionAction.action}>
            {sectionAction.label}
          </button>
        ) : (
          <></>
        )}
        {toggle && toggle?.required && (
          <div className='section-header-toggle'>
            <h3>{toggle?.title}</h3>
            <Toggle onChange={toggle?.onChange} checked={toggle?.isChecked} />
          </div>
        )}
      </div>
      <section className={className} style={style}>
        {children}
      </section>
    </>
  );
};

export default Section;
