import { resources } from '../../constants';
import utils from '../../utils';

export const characterTemplate = (name, imageUrlLayers, isAnimated = true) => {
  const layers = imageUrlLayers.map(image => `<img class="layer" src="${utils.getFileUrl(image)}"/>`);

  return `<!DOCTYPE html>
  <html>
    <head>
    <style>
      body {
        margin: 0;
          color: white;
          width: 100%;
          overflow: hidden;
          max-width: 800px;
      }
      .card {
        display: grid;
        place-items:center;
        grid-template-areas: "layer";
        width: 100%;
          max-width: 800px;
      }
      .layer {
        grid-area: layer;
        margin: 0;
        padding: 0;
        text-align: center;
        width: 100%;
          max-width: 800px;
      }
    
    </style>
    ${isAnimated ? `<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.1/jquery.min.js"></script>` : ''}
    </head>
    <body>
      <div class="card" id="card" data-tilt>
        ${layers.join('\n')}
      </div>
     ${
       isAnimated
         ? `<script type="text/javascript" src="https://unpkg.com/vanilla-tilt@1.8.0/dist/vanilla-tilt.min.js"></script>
      <script>
      const cardEle = document.getElementById("card")
      window.VanillaTilt.init(cardEle)
    </script>`
         : ''
     }
    </body>
    </html>`;
};

const RippleBackgroundTemplate = (
  { bgColor = '#228B13', logo = resources.img.placeholder, cardName = 'Mercle', userName = 'Vitalik Buterin', userTitle = 'Founder', userCompany = 'Ethereum' },
  { shouldAnimateBg = true } = {},
) => {
  const logoUrl = utils.getFileUrl(logo);

  const titleAndCompany = [];
  if (userTitle) {
    titleAndCompany.push(userTitle);
  }
  if (userCompany) {
    titleAndCompany.push(userCompany);
  }

  return `<!DOCTYPE html>
<html>

<head>
  <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@700&display=swap">
  <style>
    :root {
      --font-size-h1: 4.98vh;
      --font-size-p: 3.99vh;
    }

    html {
      background-color: ${bgColor + 88};
    }

    body {
      overflow: hidden;
      position: relative;
      height: 100vh;
      aspect-ratio: 6.4/10;
      font-family: Plus Jakarta Sans;
      margin: 0 auto 0 auto;
      border-radius: 2.99vh;
      overflow: hidden;
      box-shadow: 0px 0px 2px #000000;
      transition: 1s;
      transform: scale(0.95);
    }

    body.hover {
      box-shadow: 0px 0px 10px #000000;
      transform: scale(0.97);
    }

    .memberData-container {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 82%;
      height: 100%;
      margin-top: 11.22vh;
      margin-left: 5.86vh;
      color: white;
      transform: translateY(48vh);
      transition: 300ms ease-in-out;
    }

    .memberData-container.hover {
      transform: translateY(0);
    }

    .memberData-container img {
      width: 12.46vh;
      border-radius: 3.1vh;
      object-fit: cover;
    }

    .memberData-container h1 {
      margin: 0;
      padding: 0;
      margin-top: 4.98vh;
      font-size: var(--font-size-h1);
      word-break: break-all;
    }

    .memberData-container .memberDetails {
      margin-top: 32.3vh;
    }

    .memberData-container .memberDetails h1 {
      margin-top: 0;
    }

    .memberData-container .memberDetails p {
      padding: 0;
      margin: 0;
      margin-top: 2.99vh;
      font-size: var(--font-size-p);
      opacity: 0.8;
    }

    .background {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: -1;
      border-radius: 2.99vh;
      overflow: hidden;
    }

    .background canvas {
      width: 100% !important;
      height: 100% !important;
    }
  </style>
  <script src="https://cdnjs.cloudflare.com/ajax/libs/p5.js/1.5.0/p5.min.js" integrity="sha512-WJXVjqeINVpi5XXJ2jn0BSCfp0y80IKrYh731gLRnkAS9TKc5KNt/OfLtu+fCueqdWniouJ1ubM+VI/hbo7POQ==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
  <script>
    const onHover = () => {
      document.getElementsByClassName("memberData-container")[0].classList.add("hover")
      document.getElementsByTagName("body")[0].classList.add("hover")
      lerpBgAnimation(HOVER_SPEED)
    }
    const onHoverEnd = () => {
      document.getElementsByClassName("memberData-container")[0].classList.remove("hover")
      document.getElementsByTagName("body")[0].classList.remove("hover")
      lerpBgAnimation(DEFAULT_SPEED)
    }
    const lerpBgAnimation = (to) => {
      if (currentLerpTimeout) clearTimeout(currentLerpTimeout)
      if (currentLerpInterval) clearInterval(currentLerpInterval)
      // set lerp interval
      lerpDiff = (to - speed) / 15
      currentLerpInterval = setInterval(() => {
        speed += lerpDiff
      }, 100)
      // cleanup after timeout
      currentLerpTimeout = setTimeout(() => {
        clearInterval(currentLerpInterval)
        clearTimeout(currentLerpTimeout)
        speed = to
      }, 1500)
    }

    let currentLerpInterval;
    let currentLerpTimeout;
    let lerpDiff;

    const DEFAULT_SPEED = 0.00000125
    const HOVER_SPEED = 0.0000125
    let speed = DEFAULT_SPEED;

    const inc = 0.005;
    const hexColor = "${bgColor}";
    const colorInt = parseInt(hexColor.replace("#", ""), 16);
    const rOff = (colorInt >> 16) & 255;
    const gOff = (colorInt >> 8) & 255;
    const bOff = colorInt & 255;

    let zoff = 0;

    function setup() {
      canvas = createCanvas(Math.floor(800 / 5), Math.floor(800 / 5));
      canvas.parent('background-container')
      pixelDensity(1);
      loadPixels();
      noiseSeed(600);
    }

    function draw() {
      background(220);
      let yoff = 0;

      for (let y = 0; y < height; y++) {
        let xoff = 0;
        for (let x = 0; x < width; x++) {
          const blur = Math.max(rOff, gOff, bOff)*0.09
          const n_1 = blur + noise(xoff, yoff, zoff) * blur;
          const n_2 = noise(n_1, n_1, n_1);

          let r = Math.min(255, n_2 * rOff);
          let g = Math.min(255, n_2 * gOff);
          let b = Math.min(255, n_2 * bOff);

          const index = (x + y * width) * 4;

          pixels[index + 0] = r;
          pixels[index + 1] = g;
          pixels[index + 2] = b;
          pixels[index + 3] = 255;
          xoff += inc;
        }
        yoff += inc;
        zoff += speed;
      }
      updatePixels();
      if(${!shouldAnimateBg}) {
        noLoop();
      }
    }


  </script>
</head>

<body onmouseenter="onHover()" onmouseleave="onHoverEnd()">
  <div id="background-container" class="background"></div>
  <div class="memberData-container">
    <img src="${logoUrl}"/>
    <h1>${cardName}</h1>
    <div class="memberDetails">
      <h1>${userName}</h1>
      <p>${titleAndCompany.join(', ')}</p>
    </div>
  </div>
</body>

</html>`;
};

const SingleImage = ({ image }, { shouldProxy = false } = {}) => {
  const imageUrl = utils.getFileUrl(image);

  return `<!DOCTYPE html>
<html>
<head>
<style>
  body {
    margin: 0;
      color: white;
      width: 100%;
      overflow: hidden;
      max-width: 800px;
  }
  .card {
    display: grid;
    place-items:center;
    grid-template-areas: "layer";
    width: 100%;
    max-width: 600px;
  }
  .image {
    width: 100%;
    max-width: 600px;
  }

</style>
<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.1/jquery.min.js"></script>
</head>
<body>
  <div class="card" id="card" data-tilt>
    <img class="image" src="${imageUrl}"/>
  </div>
<script type="text/javascript" src="https://unpkg.com/vanilla-tilt@1.8.0/dist/vanilla-tilt.min.js"></script>
    <script>
  <script>
  const cardEle = document.getElementById("card")
  window.VanillaTilt.init(cardEle)
</script>
</body> 
</html> `;
};

const LayeredImageCard = ({ imageUrlLayers = [] }) => {
  const layers = imageUrlLayers.map(image => `<img class="layer" src="${utils.getFileUrl(image)}"/>`);

  return `<!DOCTYPE html>
<html>
<head>
<style>
body {
    margin: 0;
    color: white;
    height: 100vh;
    overflow: hidden;
    max-width: 800px;
    max-height: 800px;
}
.card {
    display: grid;
    place-items:center;
    grid-template-areas: "layer";
    height: 100%;
    max-width: 800px;
    max-height: 800px;
}
.layer {
    grid-area: layer;
    margin: 0;
    padding: 0;
    text-align: center;
    width: 100%;
    height: 100%;
    max-width: 800px;
    max-height: 800px;
    object-fit: contain;
}

</style>
<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.1/jquery.min.js"></script>
</head>
<body>
<div class="card" id="card" data-tilt>
    ${layers.join('\n')}
</div>
<script type="text/javascript" src="https://unpkg.com/vanilla-tilt@1.8.0/dist/vanilla-tilt.min.js"></script>
<script>
    const cardEle = document.getElementById("card")
    window.VanillaTilt.init(cardEle)
</script>
</body>
</html>`;
};

const PowerLoomIdCard = ({ username, tokenId, discordUsername }) => {
  return `<!DOCTYPE html>
  <html>
  <head>
  <style>
  @import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@700&family=Space+Grotesk&display=swap');
  
  body {
      overflow: hidden;
      background: black;
      margin:0;
      color: white;
  
  }
  .card {
      width: 63.5vh;
      height: 89vh;
      padding: 5.1vh 2.3vh;
      border-radius: 24px;
      border: 1px solid #6e6e6e;
  
      margin-left: auto;
      margin-right: auto;
  }
  .image {
      width: 63vh;
      height: 78vh;
  }
  .footer {
      display: flex;
      justify-content: space-between;
  }
  .usernamesContainer {
      margin-top: 1.5vh;
      margin-left: 3vh;
  }
  h1 {
      position: absolute;
      margin-top: 7vh;
      left: 0;
      margin-left: 6vh;
      font-size: 5vh;
      top: 0;
      font-family: Space Grotesk;
      font-style: normal;
      font-weight: 700;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 80%;
  }
  h2 {
      color: white;
      font-size: 6vh;
      margin: 0;
      font-family: Space Grotesk;
      font-style: normal;
      font-weight: 700; 
  }
  h6 {
      font-size: 3.6vh;
      font-family: Space Grotesk;
      margin: 0;
      font-weight: 300;
  }
  .badge {
      object-fit: cover;
      height: 13vh;
      margin-right: 5vh;
  
  }
  </style>
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.1/jquery.min.js"></script>
  </head>
  <body>
  <div class="card" id="card" data-tilt>
      <img class="image" src="https://api.mercle.xyz/ipfs/bafkreifrqynprxaxxvuao7x2igtsaalu2fxjla2gmqqesuuvrn7jklnxvy"/>
      <h1>${username || ''}</h1>
      <div class="footer">
              <div class="usernamesContainer">
                  <h2>${tokenId ? `OG #${tokenId}` : '&nbsp;'}</h2>
                  <h6>${discordUsername || ''}</h3>
              </div>
              ${
                tokenId
                  ? `<img
                    class="badge"
                    src="https://api.mercle.xyz/ipfs/bafkreidhja7gpjqqgxw7sngdvi6zwhjcxn4jloyv4qnq5lpprnbodbhsyy"
                  />`
                  : ''
              }
          </div>
      </div>
  <script type="text/javascript" src="https://unpkg.com/vanilla-tilt@1.8.0/dist/vanilla-tilt.min.js"></script>
  <script>
      const cardEle = document.getElementById("card")
      window.VanillaTilt.init(cardEle)
  </script>
  </body>
  </html>`;
};

const VideoCard = ({ videoUrl }) => {
  return `<!DOCTYPE html>
  <html>
      <head>
          <style>
              body {
                  margin: 0;
                  background: black;
                  overflow: hidden;
              }
              video {
                  width: 100%;
                  height: 100vh;
                  max-width: 800px;
                  max-height: 800px;
              }
          </style>
      </head>
      <body>
           <video controls autoplay muted loop>
            <source src="${utils.getFileUrl(videoUrl)}" type="video/mp4">
              Your browser does not support the video tag.
          </video> 
      </body>
  </html>`;
};
const HOSCard = ({ videoUrl }) => {
  return `<!DOCTYPE html>
  <html>
      <head>
          <style>
              body {
                  margin: 0;
                  background: black;
                  overflow: hidden;
              }
              video {
                  width: 100%;
                  height: 100vh;
                  max-width: 800px;
                  max-height: 800px;
                  object-fit:cover;
              }
          </style>
      </head>
      <body>
           <video controls autoplay muted loop>
            <source src="${utils.getFileUrl(videoUrl)}" type="video/mp4">
              Your browser does not support the video tag.
          </video> 
      </body>
  </html>`;
};

const Lifi2023YearWrapCard = () => {
  const summary = {
    totalTxn: 0,
    totalUsd: 0,
    chains: { '&nbsp;': 0 },
    tools: { '&nbsp;': 0 },
    tokens: { '&nbsp;': 0 },
  };
  const chainMap = {
    1: {
      icon: 'https://raw.githubusercontent.com/lifinance/types/main/src/assets/icons/chains/ethereum.svg',
      name: 'Ethereum',
    },
    10: {
      icon: 'https://raw.githubusercontent.com/lifinance/types/main/src/assets/icons/chains/optimism.svg',
      name: 'Optimism',
    },
    56: { icon: 'https://raw.githubusercontent.com/lifinance/types/main/src/assets/icons/chains/bsc.svg', name: 'Bsc' },
    100: {
      icon: 'https://raw.githubusercontent.com/lifinance/types/main/src/assets/icons/chains/gnosis.svg',
      name: 'Gnosis',
    },
    137: {
      icon: 'https://raw.githubusercontent.com/lifinance/types/main/src/assets/icons/chains/polygon.svg',
      name: 'Polygon',
    },
    324: {
      icon: 'https://raw.githubusercontent.com/lifinance/types/main/src/assets/icons/chains/zksync.svg',
      name: 'ZkSync',
    },
    1101: {
      icon: 'https://raw.githubusercontent.com/lifinance/types/main/src/assets/icons/chains/zkevm.png',
      name: 'Zkevm',
    },
    8453: {
      icon: 'https://raw.githubusercontent.com/lifinance/types/main/src/assets/icons/chains/base.svg',
      name: 'Base',
    },
    42161: {
      icon: 'https://raw.githubusercontent.com/lifinance/types/main/src/assets/icons/chains/arbitrum.svg',
      name: 'Arbitrum',
    },
    43114: {
      icon: 'https://raw.githubusercontent.com/lifinance/types/main/src/assets/icons/chains/avalanche.svg',
      name: 'Avalanche',
    },
    59144: {
      icon: 'https://raw.githubusercontent.com/lifinance/types/main/src/assets/icons/chains/linea.svg',
      name: 'Linea',
    },
  };
  const topTokens = Object.keys(summary.tokens)
    .map(t => ({ name: t, value: summary.tokens[t] }))
    .sort((a, b) => b.value - a.value)
    .slice(0, 5);
  const topChains = Object.keys(summary.chains)
    .map(c => ({ id: c, value: summary.chains[c] }))
    .sort((a, b) => b.value - a.value)
    .slice(0, 5);
  const topTools = Object.keys(summary.tools)
    .map(to => ({ name: to, value: summary.tools[to] }))
    .sort((a, b) => b.value - a.value)
    .slice(0, 5);

  return `<!DOCTYPE html>
<html>
<head>
<style>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;800&family=Plus+Jakarta+Sans:wght@700&family=Space+Grotesk:wght@700&display=swap');

body {
    overflow: hidden;
    background: black;
    margin: 0;
    color: white;
    font-family: Inter;
}
.card {
	max-width: 99vh;
	height: 90.29vh;
	aspect-ratio: 0.91;
    padding: 4.63vh 7.53vh;
    border-radius: 3.47vh;

    margin-left: auto;
    margin-right: auto;
    text-align: center;

    background:  radial-gradient(circle, #1f0e4a 0%, #161426 80%);
}
.logo {
	max-width: 26.07vh;
	margin-bottom: 0.28vh;
}
.title {
	margin-bottom: 6.95vh;
	color: #d964f8;
	font-size: 5.79vh;
}
.flex{
	display: flex;
	justify-content: center;
	gap: 3.47vh;
}
.info-container {
	border: 0.14vh solid #bea2e6;
	border-radius: 0.86vh;
	margin-bottom: 3.47vh;
	padding: 2.31vh;
	flex: 1;
}
h1 {
	margin: 0;
}
h2 {
	margin:0;
}
.info-container h1 {
	font-size: 1.73vh;
	color: #bea2e6;
	margin-bottom: 1.15vh;
	font-weight: 400;
}
.flex .info-container h1 {
	margin-bottom: 1.73vh;
}
.info-container h2 {
	font-size: 3.47vh;
	color: #d964f8;
	font-weight: 800;
}
.info-container h2.tag {
	font-size: 1.73vh;
	display: flex;
	align-items: center;
	gap: 0.86vh;
}
.tag .icon {
	width: 2.02vh;
	height: 2.02vh;
	object-fit: contain;
	background: #31244f;
	border-radius: 50%;
	padding: 0.28vh;
}
.caps {
    text-transform: capitalize;
}

</style>
<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.1/jquery.min.js"></script>
</head>
<body>
<div class="card" id="card" data-tilt>
	<img class="logo" src="https://dskhnex0aho1n.cloudfront.net/public/events/jumper_logo_word_mode_dark.png"/>
	<h1 class="title">2023 Wrapped</h1>
	<div class="flex">
		<div class="info-container">
			<h1>Transactions</h1>
			<h2>${summary?.totalTxn ? summary?.totalTxn?.toLocaleString() : '&nbsp;'}</h2>
		</div>
		<div class="info-container">
			<h1>Volume</h1>
			<h2>${summary?.totalUsd ? '$' + Math.floor(summary?.totalUsd || 0)?.toLocaleString() : '&nbsp;'}</h2>
		</div>
	</div>
	<div class="info-container">
		<h1>Top Tokens</h1>
		<div class="flex">
            ${topTokens.map(token => `<h2 class="tag">${token.name}</h2>`).join('\n')}
		</div>
	</div>
	<div class="info-container">
		<h1>Top Bridges & DEX's</h1>
		<div class="flex">
            ${topTools.map(tool => `<h2 class="tag caps">${tool.name}</h2>`).join('\n')}
		</div>
	</div>
	<div class="info-container">
		<h1>Top Chains</h1>
		<div class="flex">
            ${topChains
              .map(
                chain =>
                  `<h2 class="tag caps">
                   ${chainMap[chain.id]?.icon ? `<img class="icon" src="${chainMap[chain.id].icon}"/>` : ''}
                    ${chainMap[chain.id]?.name || '-'}
                   </h2>`,
              )
              .join('\n')}
        </div>
	</div>
</div>
<script type="text/javascript" src="https://unpkg.com/vanilla-tilt@1.8.0/dist/vanilla-tilt.min.js"></script>
<script>
    const cardEle = document.getElementById("card")
    window.VanillaTilt.init(cardEle)
</script>
</body>
</html>`;
};

const OgNftBuilder = ({firstName, lastName, nickName}) => {
  return `
  <!DOCTYPE html>
  <html>
  
  <head>
      <style>
          @import url("https://fonts.googleapis.com/css2?family=Patrick+Hand&display=swap");
  
          body {
              overflow: hidden;
              margin: 0;
              color: white;
              font-family: Inter;
          }
  
          p {
              margin: 0;
          }
  
          .card {
              max-width: 100vh;
              height: 100vh;
              aspect-ratio: 1;
              overflow: hidden;
              position: relative;
  
              margin-left: auto;
              margin-right: auto;
          }
  
          .logo {
              width: 100%;
              height: 100%;
          }
  
          .overlay-box {
              position: absolute;
              bottom: 0;
              left: 0;
          }
  
          .overlay-box .text-box {
            color: black;
            font-family: "Patrick Hand", sans-serif;
            width: 58vh;
            font-size: 3.5vh;
            line-height: 4vh;
            overflow: hidden;
            margin-left: 21.5vh;
            margin-bottom: 13.5vh;
            padding: 0.5vh 0;
          }
  
          .highlighted {
              font-family: "Patrick Hand", cursive;
          }
  
      </style>
  </head>
  
  <body>
      <div class="card" id="card" data-tilt>
          <img class="logo" src="https://resolve.mercle.xyz/ipfs/bafybeihejr43khevmk2lp7uajv77ztkq3a5lktyscxj6tfdj242275mxjq" />
          <div class="overlay-box">
              <div class="text-box">
                  <p>
                      My name is
                      <span class="highlighted"> ${firstName} ${lastName}</span>,
                  </p>
                  <p>
                      but everyone calls me <span class="highlighted">${nickName}</span>.
                  </p>
                  </p>
              </div>
          </div>
          <script
        type="text/javascript"
        src="https://unpkg.com/vanilla-tilt@1.8.0/dist/vanilla-tilt.min.js"
      ></script>
          <script>
              const cardEle = document.getElementById("card");
              window.VanillaTilt.init(cardEle);
          </script>
  </body>
  
  </html>
  `
}

export default {
  cardTemplate: characterTemplate,
  membershipCard: {
    RippleBackgroundTemplate,
    SingleImage,
    LayeredImageCard,
    PowerLoomIdCard,
    VideoCard,
    Lifi2023YearWrapCard,
    HOSCard,
    OgNftBuilder,
  },
};
