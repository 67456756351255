import AllEvents from './AllEvents';
import Banners from './Banners';
import './Dashboard.scss';
import EasyTasks from './EasyTasks';
import MyStats from './MyStats';
import TopCommunities from './TopCommunities';
import TopTasks from './TopTasks';

const Dashboard = () => {
  return (
    <div className='Dashboard-container'>
      <Banners />
      <MyStats />
      {/* <Raffles title="Live Raffles" columns={4} rows={1} limit={4} isLive={true} /> */}
      <TopCommunities />
      <TopTasks />
      <EasyTasks />
      <AllEvents />
    </div>
  );
};
export default Dashboard;
