import { useEffect, useState } from 'react';
import Grid from '../../components/Grid';
import { CardsLoader } from '../../components/Skeleton/CardsLoader';
import { TableSkeleton } from '../../components/Skeleton/TableLoader';
import { NftCard } from '../../components/nft/NftCard';
import { SNAPSHOT_MILLIS, types } from '../../constants';
import { useBackendApi } from '../../helpers/backendHelper';
import { useEthersHelper } from '../../helpers/ethersHelper';
import { useGlobalState } from '../../state/GlobalStateProvider';
import utils, { isEmpty, isPastTimestamp } from '../../utils';
import './Mbadges.scss';

const PageLoader = () => {
  return (
    <>
      <div style={{ marginTop: '80px' }} />

      <TableSkeleton rowsWithHeight={[30]} gap={10} />
      <div style={{ margin: '10px 0' }} />
      <CardsLoader rows={1} columns={4} columnHeight={300} />

      <div style={{ margin: '100px 0' }} />

      <TableSkeleton rowsWithHeight={[30]} gap={10} />
      <div style={{ margin: '10px 0' }} />
      <CardsLoader rows={2} columns={4} columnHeight={300} />
    </>
  );
};

const Mbadges = () => {
  const backendApi = useBackendApi();
  const [data, setData] = useState();
  const [collected, setCollected] = useState();
  const [parsedAll, setParsedAll] = useState(false);
  const [stats, setStats] = useState({ totalAction: 0, completedAction: 0, totalXp: 0, collectedXp: 0 });
  const [{ user, userIdentity }] = useGlobalState();
  const ethersHelper = useEthersHelper();
  const [showCollected, setShowCollected] = useState(true);
  const IS_SNAPSHOT = isPastTimestamp(SNAPSHOT_MILLIS);
  const calculateStats = (mbadgesData, userIdentity) => {
    let _totalAction = 0,
      _completedAction = 0,
      _totalDiFiXp = 0,
      _collectedDiFiXp = 0;
    mbadgesData.top.forEach((mbadge, i) => {
      const event = mbadgesData.events[mbadge.commId];
      _totalAction += event.events.length;

      event.events.forEach(x => {
        const pointData = x.rewards?.find(_x => _x.name == 'Points');
        const isTaskDone = userIdentity?.claims[utils.bsonIdToBytes12(x.id)];
        if (isTaskDone) _completedAction += 1;

        if (pointData?.items?.[0]?.value == types.SkillTypes['De-Fi']) {
          _totalDiFiXp += parseInt(pointData?.items?.[0]?.xp || 0);

          if (isTaskDone) {
            _collectedDiFiXp += parseInt(pointData?.items?.[0]?.xp || 0);
          }
        }
      });
    });

    setStats(_stat => ({
      ..._stat,
      totalAction: _totalAction,
      totalXp: _totalDiFiXp,
      completedAction: _completedAction,
      collectedXp: _collectedDiFiXp,
    }));
  };

  useEffect(() => {
    backendApi.communities
      .getTopCommunities('mbadge', 0, undefined, true)
      .then(res => {
        setData(res);
        calculateStats(res, undefined);
        if (user && userIdentity) {
          calculateStats(res, userIdentity);
          res.top.forEach((mbadge, i) => {
            const community = res.communities[mbadge.commId];
            const campaign = res.campaigns[mbadge.commId];
            const tokenAddress = campaign.tokenAddr;
            const chainId = campaign.chainId;

            ethersHelper
              .getNftProfileTokenId(community?.name, user?.address, tokenAddress, chainId)
              .then(() => {
                setCollected(_c => (_c ? [..._c, mbadge] : [mbadge]));
              })
              .catch(e => {})
              .finally(() => {
                if (i == res?.top.length - 1) {
                  // last mbadge
                  setParsedAll(true);
                }
              });
          });
        } else {
          setParsedAll(true);
        }
      })
      .catch(() => setData(null));
  }, [user, userIdentity]);

  return (
    <div className='mbadges-page'>
      <div className='info-grid'>
        <div>
          <h2>Welcome To MBADGEs!</h2>
          <p>
            MBADGEs are ERC-721-standard NFTs created by Mercle that securely store and organize all the attestations you receive from the Mercle Network. You can easily store
            these tokens in your wallet for convenient access. These tokens contain an EIP-712 standard attestation, which you receive upon successfully completing specific actions
            within the listed ecosystems. Your attestations serve as proof of your contributions and also assist the Mercle Network in acquiring on-chain data from you.
          </p>
        </div>
      </div>

      <div className='progress-stats-container'>
          <h2>Your MBADGE Progress</h2>
        <div className='your-stats-container'>
          <div className='stat'>
            <span>{collected?.length || 0}</span>
            <h3>MBADGES Collected</h3>
          </div>
          <div className='stat'>
            <span>{stats?.completedAction || 0}</span>
            <h3>MBADGES DeFi Action Completed</h3>
          </div>
        </div>
      </div>

      {!parsedAll ? (
        <PageLoader />
      ) : (
        <>
          <div className='heading_bar'>
            <h1 className={showCollected ? 'active_heading' : ''} onClick={() => setShowCollected(true)}>
              Collected
            </h1>
            <h1 className={showCollected ? '' : 'active_heading'} onClick={() => setShowCollected(false)}>
              Not Collected
            </h1>
          </div>
          {isEmpty(collected) ? (
            <></>
          ) : (
            <>
              {showCollected && (
                <Grid className='nft-grid-container' columns={4}>
                  {collected?.map(mbadge => {
                    const campaign = data.campaigns[mbadge.commId];
                    const community = data.communities[mbadge.commId];
                    const event = data.events[mbadge.commId];

                    return <NftCard campaign={campaign} community={community} event={event} />;
                  })}
                </Grid>
              )}
            </>
          )}
          {!showCollected && (
            <Grid className='nft-grid-container' columns={4}>
              {data.top.map(mbadge => {
                const isCollected = collected?.find(x => x._id == mbadge._id);
                const campaign = data.campaigns[mbadge.commId];
                const community = data.communities[mbadge.commId];
                const event = data.events[mbadge.commId];

                if (isCollected) return <></>;
                return <NftCard campaign={campaign} community={community} event={event} />;
              })}
            </Grid>
          )}
        </>
      )}
    </div>
  );
};

export { Mbadges };
