import { useEffect, useState } from "react";
import utils from "../../../utils";
import Dropdown from "../components/Dropdown";
import { useBackendApi } from "../../../helpers/backendHelper";
import { useGlobalState } from "../../../state/GlobalStateProvider";
import { useEvent } from "../../events/event/EventProvider";

const OnboardingDefaultDiscordChannel = () => {
  const [{community }] = useEvent();
  const backendApi = useBackendApi();
  const [channels, setChannels] = useState([]);
  const [defaultJoinChannelId, setDefaultJoinChannelId] = useState();
  const getChannels = async () => {
    try {
      setChannels((await backendApi.communities.discord.getGuildChannels()) || []);
    } catch (e) {
      console.error(e);
      alert.error("could not get channels");
    }
  };

  const getDefaultJoinChannel = async () => {
    try {
      setDefaultJoinChannelId((await backendApi.communities.discord.getDefaultJoinChannel())?.value);
    } catch (e) {
      console.error(e);
      alert.error("Could not get default join channel");
    }
  };

  const setDefaultChannel = async () => {
    const channelId = utils.getElementValue("id.input.settings.community.defaultDiscordJoinChannelId", "selected");
    try {
      await backendApi.communities.discord.setDefaultJoinChannel({ channelId });
      alert.success("Default channel set");
      getDefaultJoinChannel();
    } catch (e) {
      console.error(e);
      alert.error("Could not set default channel added");
    }
  };

  useEffect(() => {
    if (!community?.discord) return;

    getChannels();
    getDefaultJoinChannel();
  }, []);

  if (!community?.discord) {
    return (
      <div className="onboardingDefaultDiscordChannel-container">
        <h1>Set Default Channel</h1>
        <p>Import discord to community to use this feature</p>
      </div>
    );
  }

  return (
    <div className="onboardingDefaultDiscordChannel-container">
      <h1>Set Default Channel</h1>
      <Dropdown
        id={"id.input.settings.community.defaultDiscordJoinChannelId"}
        label="Channel Id for which bot will create Invite"
        defaultValues={defaultJoinChannelId ? [defaultJoinChannelId] : undefined}
        options={channels?.map((channel) => {
          return {
            label: (
              <div>
                {channel.name} [{channel.id}]
              </div>
            ),
            value: channel.id,
          };
        })}
      />
      <button onClick={setDefaultChannel}>Set Discord Default Channel</button>
    </div>
  );
};

export default OnboardingDefaultDiscordChannel;
