import React from 'react';
import { Link } from 'react-router-dom';
import { resources } from '../../constants';
import ButtonApi from '../ButtonApi';

const REF_LINK_TEXT = `I have minted attestation storage NFTs from the Mercle Network,
you can mint yours here - `;

const ReferalModal = ({ link, closeModal, referalLink }) => {
  const handleCopyClick = () => {
    if (referalLink) {
      window.navigator.clipboard.writeText(referalLink);
      alert.success('Referral link copied.', { autoClose: 500 });
    }
  };

  const socialMedia = [
    {
      name: 'Twitter',
      logo: resources?.img?.twitter,
      sharelink: `https://x.com/intent/post?text=${REF_LINK_TEXT + link}`,
    },
    {
      name: 'Facebook',
      logo: resources?.img?.facebook,
      sharelink: `https://www.facebook.com/sharer/sharer.php?u=${REF_LINK_TEXT + link}`,
    },
    {
      name: 'Telegram',
      logo: resources?.img?.telegram,
      sharelink: `https://t.me/share/url?url=${REF_LINK_TEXT + link}`,
    },
  ];

  const shareLink = link => {
    window.open(link, '_blank');
  };

  return (
    <div className='referal_modal_container'>
      <h1>Invite to Earn!</h1>
      <h2>You now earn crypto on inviting your friends over at Mercle! Read More</h2>
      <div className='referal_link_container' onClick={handleCopyClick}>
        <div className='link'>{String(referalLink || '')}</div>
        <ButtonApi className='copy_button'>
          <img className='copy' src={resources?.img?.copywhite} />
        </ButtonApi>
      </div>
      <div className='social_icons'>
        {Array.isArray(socialMedia) &&
          socialMedia.map(item => (
            <div className='each_social'>
              <ButtonApi className='icon_button' onClick={() => shareLink(item?.sharelink)}>
                <img className='social_icon' src={item?.logo} />
              </ButtonApi>
              <h2>{item?.name}</h2>
            </div>
          ))}
      </div>

      <Link onClick={closeModal} to={'/referral'} className='link_to'>
        View Earnings{' '}
        <svg width='30' height='18' viewBox='0 0 30 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M28.8026 9.68572C29.246 9.24229 29.246 8.52334 28.8026 8.0799L21.5764 0.853729C21.133 0.410295 20.414 0.410295 19.9706 0.853729C19.5271 1.29716 19.5271 2.01611 19.9706 2.45955L26.3938 8.88281L19.9706 15.3061C19.5271 15.7495 19.5271 16.4685 19.9706 16.9119C20.414 17.3553 21.133 17.3553 21.5764 16.9119L28.8026 9.68572ZM0.748047 10.0183H27.9997V7.74733H0.748047V10.0183Z'
            fill='#FE7117'
          />
        </svg>
      </Link>
    </div>
  );
};

export default ReferalModal;
