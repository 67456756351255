import { AbstraxionProvider as AbsProvider, useAbstraxionAccount, useAbstraxionSigningClient, useModal as useAbstraxtionModal } from '@burnt-labs/abstraxion';
import { coin } from '@cosmjs/proto-signing';
import { useCallback, useEffect, useState } from 'react';
import { createContainer } from 'react-tracked';
import { dateMillis, deployed, id, types } from '../constants';
import EventProvider, { useEvent } from '../pages/events/event/EventProvider';
import { useGlobalState } from '../state/GlobalStateProvider';
import { limitedApi } from '../utils';
import { useBackendApi } from './backendHelper';
import { cacheCall } from './cacheHelper';

const useCosmosHelper = () => {
  const { data: cosmosAccount, isConnected } = useAbstraxionAccount();
  const { client, logout, signArb } = useAbstraxionSigningClient();
  const [showAbstraxion, setShowAbstraxion] = useAbstraxtionModal();
  const [{ user, setUser }] = useGlobalState();
  const backendHelper = useBackendApi();
  const [{ campaign, token, communityName }, setState] = useEvent();
  const [state, setCosmosState] = useState({});
  const [cosmosLoggedIn, setIsLoggedIn] = useState(false);

  const MESSAGE = 'Login to Mercle';

  async function getCosmosSign() {
    return '0xabcd1234abc';
  }

  const getXionNftProfileTokenId = async (communityName, ownerAddress, tokenAddress, chainId, refreshCache = false, isCosmos = false) => {
    const getNftTokenId = async () => {
      const token = await getXionTokenId(tokenAddress);
      return { tokenId: token };
    };

    const { tokenId, metadataUrl } = await cacheCall(id.storage.nftProfile(ownerAddress, tokenAddress, chainId), getNftTokenId, dateMillis.month_1, refreshCache);

    if (!tokenId) throw new Error('Nft Profile found');

    return { tokenId, metadataUrl };
  };

  const refreshCosmosNftProfile = async (fetchTokenId = false) => {
    let tokenId = token.tokenId;
    if (fetchTokenId) {
      const tokenDetails = await getXionNftProfileTokenId(communityName, user?.address, token.address, token.chainId, true, campaign?.isCosmos);
      tokenId = tokenDetails.tokenId;
      setState(s => ({ ...s, token: { ...s.token, tokenId } }));
    }

    return backendHelper.communities
      .getNftProfile(communityName, token.address, tokenId)
      .then(nftProfile => {
        setState(s => ({ ...s, nftProfile: nftProfile || null }));
      })
      .catch(e => {
        if (campaign?.isCosmos) {
          getXionNFTProfile(token.address, tokenId, client).then(profile => {
            if (profile) {
              setState(s => ({ ...s, nftProfile: profile }));
            } else {
              throw new Error('Could not get profile');
            }
          });
        } else {
          setState(s => ({ ...s, nftProfile: null }));
          throw new Error('Could not get profile');
        }
      });
  };

  const mintCosmosNFT = async (communityName, campaign, username, referralCode) => {
    const alertId = alert.loading('Verifying your details');

    try {
      window.mixpanel?.track?.('MintCard', {
        url: window.location.href,
        func: 'mintProfile',
        name: username,
      });

      alert.update(alertId, { render: 'Uploading your NFT to IPFS', isLoading: true });

      const offChainToOnChain = campaign.chainId === types.MercleOffchain && campaign?.onChainToken?.tokenAddr;
      const txnResponse = await backendHelper.communities.mintToken(communityName, campaign?.tokenAddr, campaign.name, user.address, username, referralCode, offChainToOnChain);
      let txn;

      // await for txn

      alert.update(alertId, { render: 'Minting your NFT', isLoading: true });
      const mintFee = txnResponse.mintClaim.message.fee;
      const chainId = offChainToOnChain ? campaign?.onChainToken?.chainId : campaign.chainId;

      const res = await client.execute(
        cosmosAccount?.bech32Address,
        deployed.MintWithClaim[chainId],
        {
          mint_with_claim: txnResponse?.mintClaim,
        },
        'auto',
        'test1',
        [coin(mintFee?.amount, mintFee?.denom)],
      );
      console.log(res);
      // alert.update(alertId, { render: 'Please wait while we mint your NFT', isLoading: true });

      alert.update(alertId, { render: 'Loading your profile', isLoading: true });
      await refreshCosmosNftProfile(true);

      window.mixpanel?.track?.('MintCard', {
        url: window.location.href,
        func: 'mintProfile',
        name: username,
        success: true,
      });

      localStorage.removeItem(id.storage.referralCode);
      localStorage.removeItem(`cache_${id.storage.mintFeeTxnHash(campaign.commId)}`);
      alert.update(alertId, {
        render: 'Mint complete',
        type: 'success',
        isLoading: false,
        autoClose: 5000,
        closeButton: true,
      });
    } catch (error) {
      const alertConfig = {
        render: 'Transaction rejected',
        type: 'error',
        isLoading: false,
        autoClose: 5000,
        closeButton: true,
      };

      if (error.message?.includes?.('user reject')) alertConfig.render = 'Transaction rejected';
      else if (error?.message?.includes?.('insufficient funds')) alertConfig.render = 'Insufficient Balance';
      else if (error?.message?.includes?.('estimateGas')) alertConfig.render = 'Gas estimation failed. Please ensure you have sufficient balance or try again after few seconds.';
      else if (error?.message != '400') alertConfig.render = error?.message;
      else alertConfig.render = 'Something went wrong. Please try again after sometime';

      alert.update(alertId, alertConfig);
      console.error('error mintProfile', error);
      throw error;
    }
  };

  const getXionTokenId = useCallback(
    async tokenAddress => {
      try {
        const tokenId = await backendHelper.cosmos.getXionTokenId(tokenAddress, cosmosAccount?.bech32Address);
        return tokenId?.tokenId;
      } catch (error) {
        return null;
      }
    },
    [backendHelper, cosmosAccount],
  );

  const getXionNFTProfile = async (tokenAddress, tokenId, client) => {
    try {
      const nftProfile = await client?.queryContractSmart(tokenAddress, {
        nft_info: {
          token_id: `${tokenId}`,
        },
      });
      const nftJson = await fetch(nftProfile?.token_uri);
      return await nftJson.json();
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const verifySign = useCallback(
    async (client, message, signature) => {
      const granteeAccountData = await client?.getGranteeAccountData();
      if (!granteeAccountData) return false;

      const userSessionAddress = granteeAccountData.address;
      const userSessionPubKey = Buffer.from(granteeAccountData.pubkey).toString('base64');
      const onError = window.location.href;
      const response = await backendHelper.getAccessTokenForWallet({
        signature,
        message,
        xionAddress: cosmosAccount?.bech32Address,
        userSessionPubKey,
        onError,
        userSessionAddress,
      });
      setUser(response);
      return response;
    },
    [client, backendHelper],
  );

  const getUserSignAndVerify = useCallback(
    async (paraClient = client, signMessage = signArb) => {
      try {
        console.log('calleddd', paraClient, cosmosAccount?.bech32Address);
        if (paraClient && cosmosAccount?.bech32Address) {
          // Sign the message
          const response = await signMessage(paraClient?.granteeAddress, MESSAGE);
          await verifySign(paraClient, MESSAGE, response);
          setIsLoggedIn(true);
        }
      } catch (error) {
        console.log(error);
        alert.error('Different wallet is linked with this account');
        setIsLoggedIn(false);
      }
    },
    [alert, signArb, client, cosmosAccount, verifySign, isConnected],
  );

  useEffect(() => {
    window.addEventListener('logout', cosmosLogout);
  }, []);

  const cosmosLogout = useCallback(async () => {
    try {
      console.log('called cosmos logout');
      logout();
      setIsLoggedIn(false);
    } catch (error) {
      alert.error('Something went wrong!');
    }
  }, [logout, setIsLoggedIn]);

  const handleClose = useCallback(async () => {
    console.log('Called close');
    await getUserSignAndVerify();
    setShowAbstraxion(false);
  }, [setShowAbstraxion, getUserSignAndVerify]);

  // useEffect(() => {
  //   if (isConnected) {
  //     if (user?.xionAddress === cosmosAccount?.bech32Address && client) {
  //       setIsLoggedIn(true);
  //     }
  //   }
  // }, [isConnected, client, user]);

  useEffect(() => {
    if (!user) return;
    if (!client) return;
    if (!token?.address) return;
    if (!campaign?.isCosmos) return;
    if (!cosmosLoggedIn) return;

    limitedApi('nftProfile', () =>
      getXionNftProfileTokenId(communityName, user?.address, token.address, token.chainId, true, campaign?.isCosmos)
        .then(({ tokenId }) => {
          setState(s => ({ ...s, token: { ...s.token, tokenId } }));
          const promise = backendHelper.communities
            .getNftProfile(communityName, token.address, tokenId)
            .then(nftProfile => {
              console.log({ nftProfile });
              setState(s => ({ ...s, nftProfile: nftProfile || null }));
              return;
            })
            .catch(e => {
              getXionNFTProfile(token?.address, tokenId, client).then(profile => {
                if (profile) {
                  setState(s => ({ ...s, nftProfile: profile }));
                  return;
                } else {
                  setState(s => ({ ...s, nftProfile: null }));
                  throw e;
                }
              });
            });

          alert.promise(promise, {
            pending: 'Loading your profile',
            success: 'Profile loaded',
            error: 'Could not get profile',
          });
        })
        .catch(e => {
          setState(s => ({ ...s, nftProfile: null }));
          console.log(e);
        }),
    );
  }, [client, token, user]);

  return [
    {
      cosmosLoggedIn,
      cosmosAccount,
      isConnected,
      getCosmosSign,
      mintCosmosNFT,
      getXionTokenId,
      cosmosLogout,
      getUserSignAndVerify,
      handleClose,
      getXionNFTProfile,
      ...state,
      refreshCosmosNftProfile,
    },
    setCosmosState,
  ];
};

const { Provider, useTracked } = createContainer(() => useCosmosHelper());

export const useCosmos = useTracked;
export const CosmosProvider = ({ children, isSetting = false }) => {
  const [{ campaign }] = useEvent();
  if (campaign?.isCosmos || isSetting) {
    return (
      <AbsProvider config={{ contracts: [{ address: 'xion1zp64n3n5ukw2dcfzncc9wmym7uwn92zqf773znhuk43ptc3xmzysfp5mqc', amounts: [{ amount: '100000000000', denom: 'uxion' }] }] }}>
        <Provider>{children}</Provider>
      </AbsProvider>
    );
  }
  return <>{children}</>;
};

export const CosmosEventProvider = ({ children }) => {
  return (
    <EventProvider>
      <CosmosProvider isSetting={true}>{children}</CosmosProvider>
    </EventProvider>
  );
};

export default CosmosProvider;
