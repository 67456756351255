import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './skeleton.scss';

const random = (min = 15, max = 35) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const TableSkeleton = ({ rowsWithHeight, className, containerHeight, gap }) => {
  const [loaderRandoms] = useState(
    rowsWithHeight
      ? rowsWithHeight.map(height => ({
          width: random(25, 65),
          height: height,
        }))
      : new Array(random(4, 7)).fill(0).map(() => ({ width: random(25, 65) })),
  );

  const containerStyle = {};
  if (gap) {
    containerStyle['gap'] = `${gap}px`;
  }
  if (containerHeight) {
    containerStyle['height'] = `${containerHeight}px`;
  }

  return (
    <div className={`custom-skeleton-loader skeleton-loader-table-container ${className || ''}`} style={containerStyle}>
      {loaderRandoms.map(({ width, height }) => (
        <Skeleton
          // borderRadius={}
          baseColor='var(--skeleton-base-color)'
          highlightColor='var(--skeleton-highlight-color)'
          style={{ width: `${width}ch`, height }}
        />
      ))}
    </div>
  );
};

export { TableSkeleton };
