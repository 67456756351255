
import { useState } from "react";
import ObjectID from "bson-objectid";

import "./AdminIpfsFileUploader.scss";

import Input from "../components/Input";
import { types } from "../../../constants";
import { useBackendApi } from "../../../helpers/backendHelper";
import Dropdown from "../components/Dropdown";
import utils from "../../../utils";

const ImagePreview = ({ imageData, i, setImages }) => {
  return (
    <tr className="imagePreview-container">
      <td>{i + 1}</td>
      <td>
        <img
          className="img-preview"
          src={imageData.ipfsUrl ? utils.getFileUrl(imageData.ipfsUrl) : URL.createObjectURL(imageData.file)}
        />
      </td>
      <td>{imageData.file.name}</td>
      <td>
        <Dropdown
          options={[
            { label: "None", value: 0 },
            { label: `${100 / 1}%`, value: 1 },
            { label: `${100 / 2}%`, value: 2 },
            { label: `${100 / 4}%`, value: 4 },
            { label: `${100 / 8}%`, value: 8 },
            { label: `${100 / 16}%`, value: 16 },
            { label: `${100 / 32}%`, value: 32 },
          ]}
          id="id.input.adminIpfsFileUploader.item.rarity"
          onChange={(value) =>
            setImages((images) => {
              const _images = [...images];
              _images[i].rarity = value;
              return [..._images];
            })
          }
        />
      </td>
      <td>
        <code>{imageData.ipfsUrl}</code>
      </td>
    </tr>
  );
};

const AdminIpfsFileUploader = () => {
  const backendApi = useBackendApi();
  const [images, setImages] = useState([]);
  const [rewardItemJson, setRewardItemJson] = useState();

  const attachFiles = () => {
    const files = [...(utils.getElementValue("id.input.adminIpfsFileUploader.images", "files") || [])];
    if (!files.length) return alert.error("Please select files");

    const _images = files.map((file) => ({ file }));
    setImages((images) => [...images, ..._images]);

    document.getElementById("id.input.adminIpfsFileUploader.images").value = null;
  };

  const uploadFiles = async () => {
    let promises = [];
    for (let i = 0; i < images.length; i++) {
      const imageData = images[i];
      if (imageData.ipfsUrl) {
        console.log(`#${i + 1} already uploaded`);
        continue;
      }

      console.log(`#${i + 1} uploading...`);

      const file = imageData.file;
      const imageFd = new FormData();
      imageFd.append("file", file);
      imageFd.append("name", "image");
      imageFd.append("task", types.fileUploadTask.nftResource);

      promises.push(
        new Promise((resolve, reject) => {
          backendApi.communities
            .uploadFileAdmin(imageFd)
            .then(({ filePath }) => {
              setImages((images) => {
                const _images = [...images];
                _images[i].ipfsUrl = filePath;
                return _images;
              });
              resolve();
            })
            .catch((e) => {
              console.error(e);
              reject();
            });
        })
      );
      if (promises.length > 2) {
        await Promise.allSettled(promises);
        promises = [];
      }
    }
    if (promises.length) {
      await Promise.allSettled(promises);
      promises = [];
    }
    alert.success("Upload task complete");
  };

  const getRewardItemJson = () => {
    const description = utils.getElementValue("id.input.adminIpfsFileUploader.itemDescription");
    const layerValue = utils.getElementValue("id.input.adminIpfsFileUploader.itemLayerValue");
    if (!description || !layerValue) return alert.error("Description or Layer Value missing");

    const rewardItems = [];
    images.forEach((imageData) => {
      if (!imageData.ipfsUrl) return;
      const rewardItem = {
        id: ObjectID().toHexString(),
        name: imageData.file.name.split(".")[0],
        description: description,
        image: imageData.ipfsUrl,
        type: "inventoryLayer",
        value: `${layerValue}`,
      };
      if (imageData.rarity > 0) {
        rewardItem.rarity = imageData.rarity;
      }
      rewardItems.push(rewardItem);
    });

    setRewardItemJson(rewardItems);
  };

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(JSON.stringify(rewardItemJson, undefined, 2))
      .then(() => {
        alert.success("Json Copied");
      })
      .catch((e) => {
        console.error(e);
        alert.error("Could not copy to clipboard");
      });
  };

  return (
    <>
      <section>
        <h1>Attach files for Upload</h1>
        <Input
          label="Upload Images to Ipfs"
          id="id.input.adminIpfsFileUploader.images"
          type="file"
          multiple="multiple"
          accept=".jpg,.jpeg,.png,.svg"
        />
        <div style={{ display: "flex", gap: "1rem" }}>
          <button style={{ background: "var(--alert-color)" }} onClick={() => setImages([])}>
            Clear Files
          </button>
          <button onClick={attachFiles}>Attach Files</button>
        </div>

        <br />

        <h1>Upload files to ipfs</h1>
        <button onClick={uploadFiles}>Upload files</button>
      </section>

      <section className="previewSection">
        <h1>Image Preview and Status</h1>
        <table>
          <thead>
            <tr>
              <td>#</td>
              <td>Preview</td>
              <td>Name</td>
              <td>Rare Level</td>
              <td>Ipfs Url</td>
            </tr>
          </thead>
          <tbody>
            {images.map((data, i) => (
              <ImagePreview imageData={data} i={i} setImages={setImages} />
            ))}
          </tbody>
        </table>
      </section>

      <section>
        <h1>Reward Item Json</h1>
        <Input label="Item Description" id="id.input.adminIpfsFileUploader.itemDescription" />
        <Input label="Item Layer Value" id="id.input.adminIpfsFileUploader.itemLayerValue" type="number" />
        <button onClick={getRewardItemJson}>Get JSON</button>
        <button style={{ width: "fit-content" }} onClick={copyToClipboard}>
          Copy
        </button>
        <pre id="json">{JSON.stringify(rewardItemJson, undefined, 2)}</pre>
      </section>
    </>
  );
};

export default AdminIpfsFileUploader;
