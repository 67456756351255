import React from 'react';

const Icons = ({ icon, size }) => {
  //default size is 24x
  //size must be in px and string
  switch (icon) {
    case 'referral':
      return (
        <span className='material-symbols-outlined md-24' style={size && { fontSize: size }}>
          person_add
        </span>
      );
    case 'copy':
      return (
        <span className='material-symbols-outlined md-24' style={size && { fontSize: size }}>
          content_copy
        </span>
      );
    case 'profile':
      return (
        <span className='material-symbols-outlined md-24' style={size && { fontSize: size }}>
          person
        </span>
      );
    case 'setting':
      return (
        <span className='material-symbols-outlined md-24' style={size && { fontSize: size }}>
          settings
        </span>
      );
    case 'contact':
      return (
        <span className='material-symbols-outlined md-24' style={size && { fontSize: size }}>
          contact_support
        </span>
      );
    case 'done':
      return (
        <span className='material-symbols-outlined md-24' style={size && { fontSize: size }}>
          check_circle
        </span>
      );
    case 'close':
      return (
        <span className='material-symbols-outlined md-24' style={size && { fontSize: size }}>
          cancel
        </span>
      );
    case 'social':
      return (
        <span className='material-symbols-outlined md-24' style={size && { fontSize: size }}>
          public
        </span>
      );
    case 'community':
      return (
        <span className='material-symbols-outlined md-24' style={size && { fontSize: size }}>
          communities
        </span>
      );
    case 'star':
      return (
        <span className='material-symbols-outlined md-24' style={size && { fontSize: size }}>
          stars
        </span>
      );
    case 'graph':
      return (
        <span className='material-symbols-outlined md-24' style={size && { fontSize: size }}>
          monitoring
        </span>
      );
    case 'info':
      return (
        <span className='material-symbols-outlined md-24' style={size && { fontSize: size }}>
          info
        </span>
      );
    case 'logout':
      return (
        <span className='material-symbols-outlined md-24' style={size && { fontSize: size }}>
          logout
        </span>
      );
    case 'home':
      return (
        <span className='material-symbols-outlined md-24' style={size && { fontSize: size }}>
          home
        </span>
      );
    case 'raffle':
      return (
        <span className='material-symbols-outlined md-24' style={size && { fontSize: size }}>
          redeem
        </span>
      );
    case 'store':
      return (
        <span className='material-symbols-outlined md-24' style={size && { fontSize: size }}>
          store
        </span>
      );
    case 'share':
      return (
        <span className='material-symbols-outlined md-24' style={size && { fontSize: size }}>
          share
        </span>
      );
    case 'dark_mode':
      return (
        <span className='material-symbols-outlined md-24' style={size && { fontSize: size }}>
          dark_mode
        </span>
      );
    case 'light_mode':
      return (
        <span className='material-symbols-outlined md-24' style={size && { fontSize: size }}>
          light_mode
        </span>
      );

    case 'sad-face':
      return (
        <span class='material-symbols-outlined md-24' style={size && { fontSize: size }}>
          sentiment_dissatisfied
        </span>
      );

    case 'happy-face':
      return (
        <span class='material-symbols-outlined md-24' style={size && { fontSize: size }}>
          sentiment_satisfied
        </span>
      );

    default:
      break;
  }
};

export default Icons;
