import dayjs from 'dayjs';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import Icons from '../../../../components/icons/Icons';
import { useCountdown } from '../../../../hooks/useCountDown';
import { useGlobalState } from '../../../../state/GlobalStateProvider';
import { useRaffle } from '../RaffleProvider';
import './RaffleDetails.scss';

const Footer = ({ iWon }) => {
  const [{ raffle, raffleTicket, taskComplete, claimReward, participateInRaffle }] = useRaffle();

  const notOpened = Date.now() < raffle?.startDateMillis;
  const hasJoined = raffleTicket?.length;
  const hasEnded = raffle?.raffleStatus == 'ended' || raffle?.endDateMillis < Date.now();
  const canParticipate = taskComplete?.shouldAllowParticipation;

  const { time } = useCountdown(notOpened ? raffle?.startDateMillis : raffle?.endDateMillis);

  const { caption, Button } = useMemo(() => {
    if (iWon)
      return {
        caption: 'Claim your reward',
        Button: (
          <button className='primary' onClick={claimReward}>
            Claim
          </button>
        ),
      };
    if (notOpened)
      return {
        caption: `Raffle has not started yet. It will open on ${dayjs(raffle?.startDateMillis).format('DD MMM YYYY')}`,
        Button: <button disabled={true}>⏱️ {time}</button>,
      };
    if (hasEnded) {
      if (!raffle?.raffleResult?.winners)
        return {
          caption: `Raffle ended on ${dayjs(raffle?.endDateMillis).format('DD MMM YYYY')}`,
          Button: <button disabled={true}>⏱️ Winner Announcing Soon</button>,
        };
      return {
        caption: `Raffle ended on ${dayjs(raffle?.endDateMillis).format('DD MMM YYYY')}`,
        Button: <button disabled={true}>Raffle Ended</button>,
      };
    }
    if (hasJoined)
      return {
        caption: `Your participation has been confirmed in the Raffle`,
        Button: (
          <button disabled={true} className='primary'>
            ⏱️ {time}
          </button>
        ),
      };

    return {
      caption: 'Complete eligibility criteria to participate',
      Button: (
        <button className='primary' disabled={!canParticipate} onClick={participateInRaffle}>
          Participate Now
        </button>
      ),
    };
  }, [notOpened, hasJoined, hasEnded, canParticipate]);

  return (
    <footer>
      <p className='caption'>
        <Icons icon={'info'} /> <i>{caption}</i>
      </p>
      {Button}
    </footer>
  );
};

const Info = () => {
  const [{ raffle }] = useRaffle();

  return (
    <>
      <div className='dates'>
        <h2>Dates</h2>
        <span>
          Start Date:
          <b>{dayjs(raffle?.startDateMillis).format('DD MMM YYYY')}</b>
        </span>
        <span>
          End Date:
          <b>{dayjs(raffle?.endDateMillis).format('DD MMM YYYY')}</b>
        </span>
      </div>
      <h2>Eligibility Criteria</h2>
      <p className='criteria'>{raffle?.eligibilityCriteria}</p>
      <h2>Entry Criteria</h2>
      <p className='criteria'>{raffle?.loyaltyPassHolder}</p>
      <h2>Prize</h2>
      <p className='criteria prizedescription ellipse'>
        {raffle?.reward?.rewardDescription}
        {/* {raffle?.reward?.rewardItems?.map((item, i) => (
          <a key={i} href={item.external_url} target="_blank">
            {item.name}
          </a>
        ))} */}
      </p>
    </>
  );
};

const CongratsContent = () => {
  return (
    <>
      <h1 className=''>Congrats 🎉</h1>
      <p className='winnerText'>You are one of the winner. Claim your reward by clicking on the button below 👇</p>
    </>
  );
};

const BetterLuck = () => {
  return (
    <>
      <h1 className=''>Better luck next time!</h1>
      <p className=''>
        Explore other activities on Mercle.
        <br />
        <br />
        <Link className='button explore' to='/'>
          Explore
        </Link>
      </p>
    </>
  );
};

const RaffleInfo = () => {
  const [{ raffle }] = useRaffle();

  return (
    <div className='RaffleDetails-container'>
      <div className='info'>
        <h1>{raffle?.displayName}</h1>
        <p>{raffle?.description}</p>
        <Info />
      </div>
      <Footer />
    </div>
  );
};

const RaffleResult = () => {
  const [{ user }] = useGlobalState();
  const [{ raffle }] = useRaffle();

  const iWon = raffle?.raffleResult?.winners?.findIndex(winner => winner.address === user?.address) > -1;
  return (
    <div className='RaffleDetails-container'>
      <div className='info'>
        <h2>{raffle?.displayName}</h2>
        <p className='criteria'>{raffle?.description}</p>
        <br />
        <br />
        <div className='result-status-info'>{iWon ? <CongratsContent /> : <BetterLuck />}</div>
      </div>
      <Footer iWon={iWon} />
    </div>
  );
};

const RaffleDetails = ({ raffleWinners }) => {
  return raffleWinners ? <RaffleResult /> : <RaffleInfo />;
};

export default RaffleDetails;
