import "./table.scss"

const Table = ({ children, fromDataIndex, toDataIndex, count, page, pageSize, onPageChange, showFooter = true }) => {
  const maxPage = Math.floor((count - 1) / pageSize);
  const onPrev = () => {
    onPageChange(Math.max(page - 1, 0));
  };

  const onNext = () => {
    onPageChange(Math.min(page + 1, maxPage));
  };

  return (
    <div className="table-container">
      <div className="table">{children}</div>
      {showFooter && (
        <footer className="table-pagination-container">
          {fromDataIndex + 1}-{Math.min(toDataIndex, count)} of {count}
          <button onClick={onPrev} disabled={page <= 0}>
            Prev
          </button>
          <button onClick={onNext} disabled={page >= maxPage}>
            Next
          </button>
        </footer>
      )}
    </div>
  );
};

export default Table;
