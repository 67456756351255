import { CardsLoader } from '../../../../components/Skeleton/CardsLoader';
import { useEvent } from '../EventProvider';
import './MintNft.scss';
import MintProvider, { useMint } from './MintNftProvider';

const MintNft = () => {
  const [{ campaign, event }] = useEvent();
  const [{ steps, step }] = useMint();

  if (!steps?.length) return <CardsLoader rows={1} columns={1} columnHeight={200} />;

  return (
    <div className='MintNft-container'>
      <div className='info'>
        <h1> {event?.overview?.title || 'Mint Your NFT To Continue'}</h1>
        <p>
          {event?.overview?.text ||
            'Helu ser! Welcome to the campaign. You need to first mint this mNFT to verify the actions below. mNFTs are data storage vaults that keep storing all the claims of your completed actions.'}
        </p>
        <h2>Benefits</h2>
        <ul>
          {campaign?.introConfig?.rewards?.values?.map(({ value }) => (
            <li>{value}</li>
          ))}
        </ul>
      </div>
      <footer>{steps[step]}</footer>
    </div>
  );
};

const P = () => {
  return (
    <MintProvider>
      <MintNft />
    </MintProvider>
  );
};

export default P;
