import ReactSelect from 'react-select';
import darkTheme from '../components/theme/dark';
import lightTheme from '../components/theme/light';
import { useTheme } from './theme/Theme';

const CustomSelect = ({ reactSelectProps }) => {
  const [theme] = useTheme();

  const themeVariables = theme === 'dark' ? darkTheme : lightTheme;

  const reactSelectStyle = {
    control: provided => ({
      ...provided,
      backgroundColor: themeVariables['bg-color'],
      color: themeVariables['text-color'],
      borderColor: themeVariables['primary'],
    }),
    menu: provided => ({
      ...provided,
      backgroundColor: themeVariables['bg-color'],
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? themeVariables['primary-hover'] : themeVariables['bg-color'],
      color: state.isSelected ? themeVariables['text-contrast'] : themeVariables['text-color'],
      ':hover': {
        backgroundColor: themeVariables['primary-hover'],
        color: themeVariables['text-contrast'],
      },
    }),
    singleValue: provided => ({
      ...provided,
      color: themeVariables['text-color'],
    }),
    placeholder: provided => ({
      ...provided,
      color: themeVariables['text-color-light'],
    }),
  };

  return (
    <>
      <ReactSelect styles={{ ...reactSelectStyle, ...reactSelectProps?.styles }} {...reactSelectProps} className={`custom-react-select ${reactSelectProps?.className}`} />
    </>
  );
};

export { CustomSelect };
