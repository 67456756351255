import { useContext } from 'react';
import Grid from '../../../components/Grid';
import PfpBuilderPreview from '../../../components/nft/PfpBuilderPreview';
import utils from '../../../utils';
import './PfpBuilder.scss';
import PfpBuilderProvider, { pfpBuilderContext } from './PfpBuilderProvider';

const InventoryItem = ({ item }) => {
  const { inventory, selectedItems, equip } = useContext(pfpBuilderContext);

  const itemId = utils.bsonIdToBytes12(item.id);
  const isEquipped = selectedItems[item.value]?.id == itemId;
  const isLocked = !inventory[itemId];

  const onClick = () => {
    if (isLocked) return alert.error('Asset is locked');
    equip(item);
  };

  return (
    <div className={`InventoryItem-container ellipse ${isEquipped ? 'active' : ''} ${isLocked ? 'locked' : ''}`} onClick={onClick}>
      <img src={utils.getFileUrl(item.image)} />
      <p className='ellipse'>{item?.name}</p>
      {isLocked ? <div className='lockIcon'>🔒</div> : <></>}
    </div>
  );
};

const Categories = () => {
  const { categories, activeCategory, setActiveCategory } = useContext(pfpBuilderContext);

  const onClick = cat => {
    if (activeCategory == cat) return setActiveCategory(undefined);
    setActiveCategory(cat);
  };

  return (
    <div className='Categories-container'>
      {categories?.map((c, i) => (
        <button key={i} className={activeCategory == c ? 'primary' : ''} onClick={() => onClick(c)}>
          {c}
        </button>
      ))}
    </div>
  );
};

const Inventory = () => {
  const { allItems, activeCategory, categoriesMap, save, cancel } = useContext(pfpBuilderContext);

  const items = categoriesMap?.[activeCategory] || allItems;

  return (
    <div className='Inventory-container'>
      <Categories />
      <Grid className='Inventory' showTitle={false} columns={4} minWidth={120}>
        {items?.map((v, i) => (
          <InventoryItem key={i} item={v} />
        ))}
      </Grid>
      <footer>
        <button onClick={cancel}>Cancel</button>
        <button className='primary' onClick={save}>
          Save
        </button>
      </footer>
    </div>
  );
};

const PfpBuilderPanel = () => {
  const { selectedItems } = useContext(pfpBuilderContext);
  return (
    <>
      <PfpBuilderPreview className='eventNftPreview' selected={selectedItems} />
      <Inventory />
    </>
  );
};

const P = () => (
  <PfpBuilderProvider>
    <PfpBuilderPanel />
  </PfpBuilderProvider>
);

export default P;
