import base from './base';

export default {
  primary: '#fff',
  'primary-hover': '#ffffffaf',
  secondary: '#000',
  'secondary-hover': '#0000006e',

  'text-color': '#fff',
  'text-color-hover': '#FE7119',
  'text-color-light': '#ffffff96',
  'text-color-xlight': 'rgba(255, 255, 255, 0.425)',
  'text-color-xxlight': 'rgba(255, 255, 255, 0.3)',

  'text-contrast': '#000',

  'bg-color': '#000',
  'bg-color-card': 'rgba(255, 255, 255, 0.1019607843)',

  'box-shadow-color': 'rgba(0, 0, 0, 0.75)',

  'button-bg-color': '#161612',
  'button-bg-color-hover': '#2f2f28',
  'button-bg-color-dark': '#2e2e2e',
  'button-bg-color-dark-hover': '#2e2e2eb6',

  'alert-color': 'red',
  'alert-color-hover': 'rgb(179, 0, 0)',
  'success-color': 'rgb(20, 227, 20)',

  'skeleton-base-color': '#2c2c2c',
  'skeleton-highlight-color': '#3a3a3a',

  'toggle-bg-color': '#c0c0c0',

  ...base,
};
