import { useGlobalState } from '../../state/GlobalStateProvider';
import Section from '../Section';
import './AfterLoggedIn.scss';

const NotLoggedIn = () => {
  return (
    <div className='AfterLoggedIn-container-box'>
      <img src='https://dskhnex0aho1n.cloudfront.net/public/catwelcome2.png' />
      <Section className='AfterLoggedIn-container' title='Welcome 😎'>
        <p>Connect your wallet and start building your onchain reputation.</p>
      </Section>
    </div>
  );
};

const AfterLoggedIn = ({ children }) => {
  const [{ user }] = useGlobalState();

  if (!user) return <NotLoggedIn />;
  return children;
};

export default AfterLoggedIn;
