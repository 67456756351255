import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis } from 'recharts';
import { CustomSelect } from '../../components/CustomSelect';
import Grid from '../../components/Grid';
import Section from '../../components/Section';
import { CardsLoader } from '../../components/Skeleton/CardsLoader';
import AfterLoggedIn from '../../components/login/AfterLoggedIn';
import { types } from '../../constants';
import { useBackendApi } from '../../helpers/backendHelper';
import { useGlobalState } from '../../state/GlobalStateProvider';
import utils, { isEmpty } from '../../utils';
import MyStats from '../dashboard/MyStats';
import './Me.scss';

const ToolTipContent = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className='chart-tooltip-box'>
        <p>{payload[0].value} tasks done</p>
      </div>
    );
  }
};

const MyActivity = () => {
  const backendApi = useBackendApi();
  const [{ user }] = useGlobalState();
  const [activity, setActivity] = useState();

  useEffect(() => {
    backendApi.user
      .getUserActivity()
      .then(v => setActivity(isEmpty(v) ? null : v))
      .catch(e => {
        console.error(e);
        setActivity(null);
      });
  }, [user]);

  if (activity === null) return <></>;

  if (!activity?.length || !activity) {
    return <CardsLoader rows={1} columns={1} columnHeight={250} />;
  }

  return (
    <Section className='MyActivity-container' title='Your Daily Activity'>
      <ResponsiveContainer width={'100%'} height={250}>
        <AreaChart data={activity}>
          <CartesianGrid strokeDasharray='3 9' stroke='#ffffff44' />
          <XAxis dataKey='date' tickFormatter={tickItem => dayjs(tickItem).format('DD MMM')} />
          <Area type='natural' dataKey='count' className='user-activity-chart-area' fillOpacity={1} />
          <Tooltip content={<ToolTipContent />} />
        </AreaChart>
      </ResponsiveContainer>
    </Section>
  );
};

const NftCard = ({ nftProfile, tokenToCommunityName, eventMap }) => {
  const claims = nftProfile?.properties?.XP?.claims;
  const tokenKey = Object.keys(claims)[0];
  const tokenAddress = claims[tokenKey]?.attested?.domain?.verifyingContract;
  const chainId = claims[tokenKey]?.attested?.domain?.chainId;
  const tokenId = claims[tokenKey]?.attested?.domain?.version;
  const communityName = tokenToCommunityName?.[`${tokenAddress}_${chainId}`];

  return (
    <Link className='NftCard-container card' to={`/${communityName}/event`}>
      <div className='img' style={{ backgroundImage: `url("${nftProfile?.image}")` }} />
      <div className='content'>
        <h1 className='ellipse'>{nftProfile?.name}</h1>
        <footer>
          <p className='ellipse'>
            #{tokenId} • {communityName}
          </p>
          {/* {isEmpty(eventMap) ? <></> : <UserProgress taskIds={eventMap[`${tokenAddress}_${chainId}`]?.events} />} */}
        </footer>
      </div>
    </Link>
  );
};

const ViewAll = ({ onClick }) => {
  return (
    <button className='ViewAll' onClick={onClick}>
      View All
    </button>
  );
};

const MyNfts = ({ nftProfiles, tokenToCommunityName, eventMap }) => {
  const [profiles, setProfiles] = useState([]);

  useEffect(() => {
    if (!nftProfiles || !nftProfiles?.length || isEmpty(tokenToCommunityName)) return; //|| isEmpty(eventMap)

    if (nftProfiles.length <= 10) {
      setProfiles(nftProfiles.map((v, i) => <NftCard key={i} nftProfile={v} tokenToCommunityName={tokenToCommunityName} eventMap={eventMap} />));
      return;
    }

    const p = nftProfiles.slice(0, 9).map((v, i) => <NftCard key={i} nftProfile={v} tokenToCommunityName={tokenToCommunityName} eventMap={eventMap} />);

    p.push(
      <ViewAll
        key='viewall'
        onClick={() => {
          setProfiles(nftProfiles.map((v, i) => <NftCard key={i} nftProfile={v} tokenToCommunityName={tokenToCommunityName} eventMap={eventMap} />));
        }}
      />,
    );

    setProfiles(p);
  }, [nftProfiles, tokenToCommunityName, eventMap]);

  if (nftProfiles === null) {
    return <></>;
  }

  if (!nftProfiles?.length) {
    return <CardsLoader rows={2} columns={5} columnHeight={250} />;
  }

  return (
    <Grid className='MyNfts-container' title='Your NFTs' columns={4}>
      {profiles}
    </Grid>
  );
};

const RewardRow = ({ rewards, userIdentity, taskId, rewardAttested }) => {
  return (
    <div className='RewardRow-container'>
      <div className='title'>
        <h1 className='ellipse'>{userIdentity?.claims?.[taskId]?.request?.message?.name}</h1>
        <p>{dayjs(rewardAttested?.message?.timestamp * 1000).format('h:mm A on DD MMMM YYYY')}</p>
      </div>
      <div className='assets'>
        {rewards?.[1]?.items?.map((v, i) => (
          <img key={i} src={utils.getFileUrl(v.image)} />
        ))}
      </div>
      <div className='xp'>{rewards[0].items[0].xp} XP</div>
    </div>
  );
};

const RewardHistory = ({ nftProfiles, tokenToCommunityName, mintedTokens }) => {
  const [{ userIdentity }] = useGlobalState();
  const [selectedCommunity, setSelectedCommunity] = useState();
  const [rewardRows, setRewardRows] = useState([]);

  const flattenList = _nftProfiles => {
    const flattenedList = [];
    _nftProfiles.map(nftMetadata => {
      const nftProfile = nftMetadata.metadata || nftMetadata;
      if (!nftProfile || !userIdentity) return;

      const rewardClaims = nftProfile.properties?.[types.SkillTypes.Xp]?.claims;
      const taskIds = Object.keys(rewardClaims || {});
      taskIds.map(taskId => {
        const rewards = rewardClaims[taskId].attested.message.claims;
        flattenedList.push({
          rewards,
          rewardClaims,
          timeStamp: rewardClaims[taskId].attested?.message?.timestamp,
          taskId,
        });
      });
    });

    return flattenedList.sort((a, b) => {
      var dateA = new Date(a.timeStamp);
      var dateB = new Date(b.timeStamp);
      return dateB - dateA;
    });
  };

  useEffect(() => {
    if (!nftProfiles || !userIdentity) return;

    const selectedDAta = nftProfiles.filter(x => {
      if (isEmpty(selectedCommunity)) return true;
      let claims = Object.values(x.properties?.[types.SkillTypes.Xp]?.claims || {});
      const domain = claims?.[0]?.attested?.domain;
      const key = `${domain?.verifyingContract}_${domain?.chainId}`;

      return key == selectedCommunity.value;
    });

    const filteredRewards = flattenList(selectedDAta);

    if (filteredRewards.length <= 10) {
      setRewardRows(
        filteredRewards
          ?.slice(0, 10)
          ?.map((v, i) => <RewardRow key={i} rewards={v.rewards} userIdentity={userIdentity} taskId={v.taskId} rewardAttested={v.rewardClaims[v.taskId].attested} />),
      );
      return;
    }

    const h = filteredRewards
      .slice(0, 10)
      .map((v, i) => <RewardRow key={i} rewards={v.rewards} userIdentity={userIdentity} taskId={v.taskId} rewardAttested={v.rewardClaims[v.taskId].attested} />);

    h.push(
      <ViewAll
        onClick={() => {
          setRewardRows(
            filteredRewards.map((v, i) => (
              <RewardRow key={i} rewards={v.rewards} userIdentity={userIdentity} taskId={v.taskId} rewardAttested={v.rewardClaims[v.taskId].attested} />
            )),
          );
        }}
        key='viewall'
      />,
    );

    setRewardRows(h);
  }, [nftProfiles, userIdentity, selectedCommunity]);

  if (nftProfiles === null) {
    return <></>;
  }

  if (!rewardRows?.length && isEmpty(mintedTokens)) {
    return <CardsLoader rows={5} columns={1} columnHeight={60} />;
  }

  return (
    <Section className='RewardHistory-container' title='Reward History'>
      <div className='reward-history-actions'>
        <CustomSelect
          reactSelectProps={{
            placeholder: 'Select Community',
            value: selectedCommunity || undefined,
            onChange: value => {
              setSelectedCommunity(value || undefined);
            },
            options: Object.keys(mintedTokens).map(key => ({ label: tokenToCommunityName?.[key], value: key })),
            onchange,
          }}
        />
      </div>
      {rewardRows}
    </Section>
  );
};

const Me = () => {
  const backendApi = useBackendApi();
  const [nftProfiles, setNftProfiles] = useState([]);
  const [{ userIdentity, user }] = useGlobalState();
  const [eventMap, setEventMap] = useState({});
  const [mintedTokens, setMintedTokens] = useState({});
  const [tokenToCommunityName, setTokenToCommunityName] = useState({});

  useEffect(() => {
    backendApi.user
      .getNftProfiles()
      .then(res => {
        setNftProfiles(isEmpty(res) ? null : res);
      })
      .catch(e => {
        console.error(e);
        setNftProfiles(null);
      });
  }, [user]);

  useEffect(() => {
    if (!userIdentity) return;

    const tokens = {};
    const values = Object.values(userIdentity?.claims);

    for (let i = 0; i < values.length; i++) {
      const address = values[i].attested.domain.verifyingContract;
      const chainId = parseInt(values[i].attested.domain.chainId);
      const tokenId = parseInt(values[i].attested.domain.version);
      tokens[`${address}_${chainId}`] = {
        address,
        chainId,
        tokenId,
      };
    }

    setMintedTokens(tokens);
    const tokensArray = Object.values(tokens);

    // get this so we can redirect user to the community page from nft card
    backendApi.communities
      .getForTokens(tokensArray)
      .then(tokenToCommMap => setTokenToCommunityName(tokenToCommMap))
      .catch(e => console.error(e));
  }, [userIdentity]);

  useEffect(() => {
    if (isEmpty(tokenToCommunityName) || isEmpty(mintedTokens)) return;

    Promise.all(
      Object.keys(mintedTokens || {}).map(async key => {
        const communityName = tokenToCommunityName?.[key];
        const event = await backendApi.communities.getEvent(communityName);
        return { event, key };
      }),
    ).then(events => {
      // create map and assign to event map
      const _eventMap = {};
      events.forEach(e => {
        _eventMap[e.key] = e.event;
      });
      setEventMap(_eventMap);
    });
  }, [tokenToCommunityName, mintedTokens]);

  return (
    <AfterLoggedIn>
      <div className='Me-container'>
        <MyActivity />
        <MyStats />
        <MyNfts nftProfiles={nftProfiles} tokenToCommunityName={tokenToCommunityName} eventMap={eventMap} />
        <RewardHistory nftProfiles={nftProfiles} tokenToCommunityName={tokenToCommunityName} mintedTokens={mintedTokens} />
      </div>
    </AfterLoggedIn>
  );
};

export default Me;
