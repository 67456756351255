import { resources, types } from '../constants';
import { useGlobalState } from '../state/GlobalStateProvider';
import { useOauthHelper } from './login/oauthHelper';

export const useTwitterHelper = community => {
  const [{ user }] = useGlobalState();
  const oauthHelper = useOauthHelper();

  const loginTwitter = async (redirectUrl, isInvasive = false) => {
    if (user?.twitter?.username && !isInvasive) return true;
    const state = await oauthHelper.getLoginState(types.loginVia.twitter, types.postLoginActions.followTwitter, redirectUrl);
    window.open(state, '_blank');
    return false;
  };

  const followCommunityOnTwitter = async (username, isInvasive) => {
    if (!username && !community?.links?.twitter) return;
    try {
      const redirectUrl = resources.links.twitter.userPage(username || community?.links?.twitter);
      if (await loginTwitter(redirectUrl, isInvasive)) {
        window.open(redirectUrl, '_blank');
      } else {
        alert.info('Please login with Twitter');
      }
    } catch (e) {
      console.error(e);
      alert.error('Something went wrong');
    }
  };

  const redirectUrlOnTwitter = async (redirectUrl, isInvasive, skipLogin) => {
    if (!redirectUrl) return;
    try {
      if (skipLogin || (await loginTwitter(redirectUrl, isInvasive))) {
        window.open(redirectUrl, '_blank');
      } else {
        alert.info('Please login with Twitter');
      }
    } catch (e) {
      console.error(e);
      alert.error('Something went wrong');
    }
  };

  return {
    loginTwitter,
    followCommunityOnTwitter,
    redirectUrlOnTwitter,
  };
};

export default { useTwitterHelper };
