import { useContext, useEffect, useState } from 'react';
import { createContainer } from 'react-tracked';
import { walletContext } from 'walletconnector';
import { useBackendApi } from '../helpers/backendHelper';
import { limitedApi } from '../utils';

const useGlobal = () => {
  const [state, setState] = useState({});
  const { getUserAddress } = useContext(walletContext);
  const backendApi = useBackendApi();

  const setValue = (key, value) => setState(state => ({ ...state, [key]: value }));
  const setUser = user => setValue('user', user);
  const logout = () => {
    setValue('user', undefined);
    setValue('userIdentity', undefined);
    window.dispatchEvent(new Event('logout'));
  };

  useEffect(() => {
    backendApi.communities
      .get('mercle')
      .then(mercleCommunity => {
        setState(s => ({ ...s, mercleCommunity: mercleCommunity || null }));
      })
      .catch(e => {
        setState(s => ({ ...s, mercleCommunity: null }));
      });
  }, []);

  useEffect(() => {
    if (!getUserAddress()) return;
    if (state.user) return;

    limitedApi('user', () =>
      backendApi
        .refreshAccessToken()
        .then(v => setValue('user', v))
        .catch(e => console.error(e)),
    );
  }, [getUserAddress()]);

  useEffect(() => {
    if (!state.user) return;
    if (state.userIdentity) return;

    limitedApi('userIdentity', () =>
      backendApi.user
        .getIdentityClaims()
        .then(v => setValue('userIdentity', v))
        .catch(e => console.error(e)),
    );
  }, [state.user]);

  const testToggle = () => {
    setState(s => ({ ...s, r: Math.random() }));
  };

  const updateUserIdentity = userIdentity => {
    setState(s => ({ ...s, userIdentity }));
  };

  return [{ ...state, setUser, logout, testToggle, updateUserIdentity }, setState];
};

const { Provider, useTracked } = createContainer(() => useGlobal());

export const useGlobalState = useTracked;
export const GlobalStateProvider = ({ children }) => <Provider>{children}</Provider>;
export default GlobalStateProvider;
