import { useContext } from 'react';
import { walletContext } from 'walletconnector';
import { useLoginHelper } from '../../helpers/login/loginHelper';
import { useGlobalState } from '../../state/GlobalStateProvider';
import ButtonApi from '../ButtonApi';

const LoginButton = ({ content = <></> }) => {
  const [{ user }] = useGlobalState();
  const { getUserAddress } = useContext(walletContext);
  const { userLogin } = useLoginHelper();

  const openLogin = async e => {
    try {
      await userLogin({ isInvasive: true });
    } catch (e) {
      console.error(e);
      alert.error('Could not login');
    }
  };

  if (user) return content;
  return (
    <ButtonApi className='primary ellipse' onClick={openLogin}>
      {getUserAddress() ? <>Login</> : <>Connect Wallet</>}
    </ButtonApi>
  );
};

export default LoginButton;
