import { useEffect, useState } from 'react';
import Address from '../../../../components/Address';
import Grid from '../../../../components/Grid';
import Section from '../../../../components/Section';
import { useEthersHelper } from '../../../../helpers/ethersHelper';
import { useRaffle } from '../RaffleProvider';
import RaffleWinnerCard from './RaffleWinnerCard';
import './RaffleWinners.scss';

const RaffleWinnerTxnsRow = ({ label, value }) => {
  return (
    <div className='RaffleWinnerTxnsRow-container'>
      <h1>{label}</h1>
      <h2>{value}</h2>
    </div>
  );
};

const RafflePickerDetails = () => {
  const ethersHelper = useEthersHelper();
  const [{ raffle }] = useRaffle();
  const [raffleDetails, setRaffleDetails] = useState();

  useEffect(() => {
    if (!raffle?.raffleResult?.txnHash) return {};

    const txnHash = raffle.raffleResult.txnHash.hash;
    const chainId = raffle.raffleResult.txnHash.chainId;

    ethersHelper
      .parseRafflePickerTxn(txnHash, chainId)
      .then(({ parsedTxn, txn }) => {
        setRaffleDetails({
          raffleId: parsedTxn.args.raffleId,
          totalEntries: parsedTxn.args.totalEntries.toLocaleString(),
          totalWinners: parsedTxn.args.count.toLocaleString(),
          participantsUrl: parsedTxn.args.participants,
          contractAddress: txn.to,
          txnHash,
          chainId,
        });
      })
      .catch(e => {});
  }, [raffle?.raffleResult?.txnHash]);

  if (!raffleDetails) return <></>;
  return (
    <Section className='RafflePickerDetails-container' title='ℹ️ Raffle Selection Details'>
      <RaffleWinnerTxnsRow label='Raffle ID' value={raffleDetails.raffleId} />
      <RaffleWinnerTxnsRow label='Total Entries' value={raffleDetails.totalEntries} />
      <RaffleWinnerTxnsRow label='Total Winners' value={raffleDetails.totalWinners} />
      <RaffleWinnerTxnsRow
        label='Participations'
        value={
          <a className='link' href={raffleDetails.participantsUrl} target='_blank'>
            View Participants
          </a>
        }
      />
      <RaffleWinnerTxnsRow label='Contract Address' value={<Address a={raffleDetails.contractAddress} chainId={raffleDetails.chainId} />} />
      <RaffleWinnerTxnsRow label='Winner Selection Transaction' value={<Address first={6} second={-8} a={raffleDetails.txnHash} chainId={raffleDetails.chainId} isTxn={true} />} />
    </Section>
  );
};

const RaffleWinners = () => {
  const [{ raffle }] = useRaffle();
  const winners = raffle?.raffleResult?.winners;

  return (
    <>
      <Grid className='RaffleWinners-container' title='Winners' columns={5}>
        {winners?.map((v, i) => (
          <RaffleWinnerCard key={i} winner={v} />
        ))}
      </Grid>
      <RafflePickerDetails />
    </>
  );
};

export default RaffleWinners;
