import { useContext } from 'react';
import { walletContext } from 'walletconnector';
import EmailPanel from '../components/login/EmailPanel';
import { useModal } from '../components/modal/ModalContext';
import Quiz from '../components/quiz/Quiz';
import { id, resources, types, typesComplex } from '../constants';
import { useEvent } from '../pages/events/event/EventProvider';
import TaskVerifyForm from '../pages/events/event/TaskVerifyForm';
import TaskVerifyResultToast from '../pages/events/event/TaskVerifyResultToast';
import { useGlobalState } from '../state/GlobalStateProvider';
import utils from '../utils';
import { useBackendApi } from './backendHelper';
import { useDiscordHelper } from './discordHelper';
import { useLoginHelper } from './login/loginHelper';
import { useTelegramHelper } from './telegramHelper';
import { useTwitterHelper } from './twitterHelper';

/**
 * this should be used inside events as it uses Event hook
 */
export const useTaskHelper = () => {
  const [{ user, updateUserIdentity }] = useGlobalState();
  const { userLogin } = useLoginHelper();
  const { changeNetwork } = useContext(walletContext);
  const [{ openModal }] = useModal();
  const [{ communityName, community, token, nftProfile, refreshNftProfile }] = useEvent();
  const backendApi = useBackendApi();
  const discordHelper = useDiscordHelper(community);
  const twitterHelper = useTwitterHelper(community);
  const telegramHelper = useTelegramHelper(community);

  const redirect = to => window.open(to, '_blank');

  const handleQuizOpen = task => {
    return openModal({
      className: 'quizPannelBox',
      classNameContent: '',
      hideNavbar: true,
      content: <Quiz eventItem={task} />,
    });
  };

  const openReferral = task => {};

  const openTaskVerifyForm = (task, extra, onSuccess) => {
    return openModal({
      className: 'TaskVerifyForm-container',
      classNameContent: '',
      content: <TaskVerifyForm task={task} extra={extra} onSubmit={onSuccess} verifyTask={(task, extra, onSuccess) => _verifyTask(task, extra, onSuccess)} />,
    });
  };

  const handleEmailOpen = e => {
    openModal({
      title: 'SIGN UP FOR NEWSLETTER',
      description: 'Choose Method',
      className: 'emailPannelBox',
      classNameContent: '',
      content: <EmailPanel postLoginAction={types.postLoginActions.subNewsletter} />,
      showBackButton: true,
    });
  };

  const action = async task => {
    console.log({ task });
    if (!user || !nftProfile) {
      window.mixpanel?.track?.('eventAction', { msg: 'no profile. redirect to mint page' });
      return alert.error('Please create an nft profile');
    }

    window.mixpanel?.track?.('eventAction', { taskType: task.type, taskName: task.name });

    switch (task.type) {
      case typesComplex.EventTypes.ConnectDiscord.name: {
        return discordHelper.joinDiscord({
          guildId: task.connectDiscord?.guildId,
          channelId: task.connectDiscord?.channelId,
        });
      }
      case typesComplex.EventTypes.ConnectTwitter.name: {
        return twitterHelper.followCommunityOnTwitter(task.connectTwitter?.username || community?.links?.twitter, false);
      }
      case typesComplex.EventTypes.ConnectTelegram.name: {
        return telegramHelper.joinTelegramGroup(task.connectTelegram?.link);
      }
      case typesComplex.EventTypes.HoldToken.name: {
        let { chains } = task?.holdToken;
        const chainId = Object.keys(chains)[0];
        try {
          await changeNetwork(chainId);
        } catch (e) {
          // no-op. probably network change errored because wallet doesn't support it
          console.error(e);
        }
        break;
      }
      case typesComplex.EventTypes.HoldNFT.name: {
        const chainId = Object.keys(task?.holdNFT?.chains || {})[0];
        return redirect(task?.holdNFT?.link || task?.holdNFT?.chains?.[chainId]?.collectionUrl);
      }
      case typesComplex.EventTypes.SnapshotVote.name: {
        return redirect(resources.links.snapshot.space(task?.snapshotVote?.spaceId));
      }
      case typesComplex.EventTypes.UniswapLP.name: {
        let uniChains = task?.uniswapLP?.chains;
        let uniTarget = uniChains[Object.keys(uniChains)[0]];
        return redirect(resources.links.uniswap.liquidityAdd(uniTarget[0], uniTarget[1]));
      }
      case typesComplex.EventTypes.PushSubChannel.name: {
        const { chainId, channelId } = task?.pushSubChannel;
        try {
          await changeNetwork(chainId);
        } catch (e) {
          console.error(e);
        }
        return redirect(`https://app.push.org/channels?channel=${channelId}`);
      }
      case typesComplex.EventTypes.StakedInThorSaver.name: {
        return redirect(task?.stakedInThorSaver?.website);
      }
      case typesComplex.EventTypes.TwitterRetweet.name: {
        return twitterHelper.redirectUrlOnTwitter(resources.links.twitter.retweet(task?.twitterRetweet?.tweetId));
      }
      case typesComplex.EventTypes.TwitterLikeAndRetweet.name: {
        return twitterHelper.redirectUrlOnTwitter(resources.links.twitter.like(task?.twitterLikeAndRetweet?.tweetId));
      }
      case typesComplex.EventTypes.TwitterTweet.name: {
        return twitterHelper.redirectUrlOnTwitter(resources.links.twitter.tweet(task.twitterTweet.text));
      }
      case typesComplex.EventTypes.TwitterBio.name: {
        return twitterHelper.redirectUrlOnTwitter(task.twitterBio.link);
      }
      case typesComplex.EventTypes.TwitterName.name: {
        return twitterHelper.redirectUrlOnTwitter(task.twitterName.link);
      }
      case typesComplex.EventTypes.DiscordStageAttend.name: {
        return discordHelper.joinDiscord({ channelId: task?.discordStageAttend?.channelId });
      }
      case typesComplex.EventTypes.DiscordHasRole.name: {
        return discordHelper.joinDiscord({ channelId: task?.discordHasRole?.channelId });
      }
      case typesComplex.EventTypes.Quiz.name: {
        return handleQuizOpen(task);
      }
      case typesComplex.EventTypes.RedirectTask.name: {
        backendApi.communities
          .postRedirectTaskConfirm(communityName, task?.tokenAddr, {
            eventId: task?.id,
            levelId: task?.levelId,
          })
          .catch(e => {});
        break;
      }
      case typesComplex.EventTypes.DiscordMessageCount.name: {
        return discordHelper.joinDiscord({
          guildId: task?.discordMessageCount?.guildId,
          channelId: task?.discordMessageCount?.channelId,
        });
      }
      case typesComplex.EventTypes.DiscordAssignRole.name: {
        try {
          const promise = backendApi.communities.discordAssignRole(communityName, task?.tokenAddr, {
            taskId: task?.id,
          });
          alert.promise(promise, {
            pending: 'Tyring to get role',
            success: 'Role assigned. You can now verify the task',
            error: 'Redirecting to discord',
          });
          await promise;
        } catch (error) {
          discordHelper.joinDiscord({});
        }
        return;
      }
      case typesComplex.EventTypes.MintProfile.name: {
        if (nftProfile) return verify(task);
        // todo: maybe open mint modal?
        return alert.error('Please mint the NFT');
      }
      case typesComplex.EventTypes.ReferralVerify.name: {
        return openReferral(task);
      }
      case typesComplex.EventTypes.OnSiteAttendance.name: {
        return verify(task);
      }
      case typesComplex.EventTypes.MailList.name: {
        return handleEmailOpen();
      }
    }

    return redirect(task[task.type]?.link);
  };

  const verify = async task => {
    if (!nftProfile) return alert.error('No NFT profile');

    // just in case
    await userLogin();

    // some tasks like leaderboard have delay after which the reward can be claimed
    const verifyAfterTime = task?.config?.verifyAfterTime;
    if (verifyAfterTime && Date.now() < verifyAfterTime) return alert.error(`Can only claim after ${utils.countdownString(verifyAfterTime).str.join(' ')}`);

    window.mixpanel?.track?.('handleVerify', { taskName: task?.name });

    let onSuccess = undefined;
    let extra = undefined;
    switch (task?.type) {
      case typesComplex.EventTypes.OnSiteAttendance.name:
        extra = { code: localStorage.getItem(id.storage.attendanceCode) };
        onSuccess = () => localStorage.removeItem(id.storage.attendanceCode);
        break;
    }

    switch (task.type) {
      case typesComplex.EventTypes.DaoWallet.name:
      case typesComplex.EventTypes.HasTransferedAsset.name:
        if (task[task.type].searchByEoa) break;
      case typesComplex.EventTypes.TwitterTweet.name:
        return openTaskVerifyForm(task, extra, onSuccess);
    }

    await _verifyTask(task, extra, onSuccess);
  };

  const _verifyTask = async (task, extra, onSuccess) => {
    const body = {
      claim: {
        event: task,
        tokenId: token.tokenId,
      },
    };
    if (extra) body.extra = extra;
    let promise = backendApi.communities.verifyTask(communityName, token.address, body);
    if (task.type === 'connectTwitter' || task.type === 'twitterRetweet') {
      promise.then(response => {
        updateUserIdentity(response.userIdentity);
        if (onSuccess) onSuccess();
      });
      return;
    }
    try {
      const response = await alert.promise(promise, {
        pending: `Verifying ${task.name}`,
        success: ({ data }) => <TaskVerifyResultToast task={task} claimResult={data} />,
        error: `Could not verify ${task.name}`,
      });
      refreshNftProfile().catch(e => {});
      updateUserIdentity(response.userIdentity);
      if (onSuccess) onSuccess();
    } catch (e) {}
  };

  return { action, verify, openTaskVerifyForm };
};

export default { useTaskHelper };
