import { useMemo } from 'react';
import { useGlobalState } from '../../state/GlobalStateProvider';
import utils from '../../utils';
import ToolTip from '../toolTip/Tooltip';
import './UserProgress.scss';

const UserProgress = ({ taskIds, horizontal = false }) => {
  const [{ userIdentity }] = useGlobalState();

  const { percentage, done, total } = useMemo(() => {
    if (!userIdentity || !taskIds?.length) return { percentage: 0, done: 0, total: 1 };

    const total = taskIds.length;
    const done = taskIds.filter(taskId => userIdentity.claims[utils.bsonIdToBytes12(taskId)]).length;
    const percentage = Math.ceil((done / total) * 100);

    return { percentage, done, total };
  }, [userIdentity, taskIds?.length]);

  if (!userIdentity) return <></>;
  return (
    <ToolTip
      className={`UserProgress ${horizontal ? 'horizontal' : ''}`}
      tipContent={
        <p>
          {done}/{total} tasks done
        </p>
      }
    >
      <div
        className={`UserProgress-container ${horizontal ? 'horizontal' : ''}`}
        style={{
          background: `linear-gradient(${horizontal ? '-90deg,' : ''} var(--bg-color-card) ${100 - percentage}%, var(--text-color-hover) ${100 - percentage}% 100%)`,
        }}
      />
    </ToolTip>
  );
};

export default UserProgress;
