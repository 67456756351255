import { dateMillis } from '../constants';

/**
 * @param {*} storageId id of the cache storage item
 * @param {*} api async function that returns data to be cached
 * @param {*} expireMillis expire time of cachegi
 * @returns
 */
export const cacheCall = async (storageId, api, expireMillis = dateMillis.hour_1 * 6, refreshCache = false) => {
  let cache = JSON.parse(localStorage.getItem(storageId));
  const currentTime = Date.now();
  if (refreshCache || !cache?.data || cache?.expireAt < currentTime) {
    cache = { data: await api() };
    cache.expireAt = currentTime + expireMillis;
    localStorage.setItem(storageId, JSON.stringify(cache));
  }
  return cache.data;
};

export default { cacheCall };
