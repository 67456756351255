import './Toggle.scss';

const Toggle = ({ onChange, checked, id }) => {
  return (
    <div className='toggle-container'>
      <input type='checkbox' class='checkbox' id={`checkbox_${id}`} onChange={onChange} checked={checked} />
      <label for={`checkbox_${id}`} class='checkbox-label'>
        <span class='ball'></span>
      </label>
    </div>
  );
};

export { Toggle };
