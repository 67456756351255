import { useEffect, useState } from "react";

const Typewriter = ({ text, delay, infinite, startDelay = 0  , orange=true}) => {
    const [currentText, setCurrentText] = useState('');
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isStarted, setIsStarted] = useState(false);
  
    useEffect(() => {
      if (!isStarted) {
        const startTimeout = setTimeout(() => {
          setIsStarted(true);
        }, startDelay);
  
        return () => clearTimeout(startTimeout);
      }
  
      let timeout;
  
      if (isStarted && currentIndex <= text.length - 1) {
        timeout = setTimeout(() => {
          setCurrentText(prevText => prevText + text[currentIndex]);
          setCurrentIndex(prevIndex => prevIndex + 1);
        }, delay);
      } else if (infinite && currentIndex === text.length) {
        setTimeout(() => {
          setCurrentIndex(0);
          setCurrentText('');
        }, delay);
      }
  
      return () => clearTimeout(timeout);
    }, [currentIndex, delay, infinite, isStarted, startDelay, text]);
  
    return <span className={orange && 'orange_color'}>{currentText}</span>;
  };

  export default Typewriter