import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { createContainer } from 'react-tracked';
import { useBackendApi } from '../../../helpers/backendHelper';
import { useGlobalState } from '../../../state/GlobalStateProvider';

const useRaffleState = () => {
  const [state, setState] = useState({});
  const [{ user }] = useGlobalState();
  const backendApi = useBackendApi();
  const { raffleName } = useParams();

  useEffect(() => {
    if (!raffleName) return;
    window.mixpanel?.track?.('Raffle page', {
      raffleName,
    });

    backendApi.raffle
      .getSingleRaffle(raffleName)
      .then(raffle => {
        setState(state => ({ ...state, raffle: raffle || null }));
      })
      .catch(e => {
        alert.error('could not get raffle');
        setState(state => ({ ...state, raffle: null }));
      });
  }, [raffleName]);

  useEffect(() => {
    if (!raffleName || !user) return;
    backendApi.raffle
      .getRaffleTicketForUser(raffleName)
      .then(raffleTicket => {
        setState(state => ({ ...state, raffleTicket: raffleTicket || null }));
      })
      .catch(e => {
        setState(state => ({ ...state, raffleTicket: null }));
      });

    backendApi.raffle
      .getRaffleTaskCompleteStatus(raffleName)
      .then(taskComplete => {
        setState(state => ({ ...state, taskComplete: taskComplete || null }));
      })
      .catch(e => {
        setState(state => ({ ...state, taskComplete: null }));
      });
  }, [raffleName, user]);

  const participateInRaffle = () => {
    const promise = backendApi.raffle
      .postRaffleEntry(raffleName)
      .then(raffleTicket => {
        setState(state => ({ ...state, raffleTicket: raffleTicket || null }));
      })
      .catch(e => {
        setState(state => ({ ...state, raffleTicket: null }));
        throw e;
      });
    alert.promise(promise, {
      pending: 'Entering raffle',
      success: 'Raffle entered',
      error: 'Could not enter raffle',
    });
  };

  const claimReward = () => {
    const promise = backendApi.raffle
      .claimReward(raffleName)
      .then(raffle => {
        setState(state => ({ ...state, raffle: raffle || null }));
      })
      .catch(e => {
        throw e;
      });
    alert.promise(promise, {
      pending: 'Claiming reward',
      success: 'Congrats! Reward claimed',
      error: 'Could not claim reward',
    });
  };

  return [{ ...state, participateInRaffle, claimReward }, setState];
};

const { Provider, useTracked } = createContainer(() => useRaffleState());

export const useRaffle = useTracked;
export const RaffleProvider = ({ children }) => <Provider>{children}</Provider>;
export default RaffleProvider;
