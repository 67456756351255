import ButtonApi from '../../../../components/ButtonApi';
import Icons from '../../../../components/icons/Icons';
import { useEvent } from '../EventProvider';
import { useMint } from './MintNftProvider';

const Step1 = () => {
  const [{ isDisabled, chainName, onNext }] = useMint();
  const [{ campaign }] = useEvent();

  return (
    <>
      <p className='mintcaption'>
        <Icons icon={'info'} />{' '}
        <i>
          Mint on {chainName}
          {campaign?.isCosmos && (
            <a href='https://testnet.dashboard.burnt.com/' target='_blank'>
              (Click to Add Funds)
            </a>
          )}
        </i>
      </p>
      <ButtonApi className='primary' onClick={onNext} disabled={isDisabled}>
        Mint Now
      </ButtonApi>
    </>
  );
};

export default Step1;
