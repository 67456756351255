import { useEffect, useState } from "react";
import Select from "react-select";
import utils from "../../../utils";
import './component.scss'
const Dropdown = ({
  id,
  label,
  defaultValues = [],
  options,
  isMulti = false,
  onChange,
  className = "",
  style = {},
}) => {
  const [stateId, setStateId] = useState(utils.getRandomId());

  const updateOptions = (selected) => {
    if (!selected) return;
    let values = selected.value;
    if (isMulti) {
      values = selected.map((item) => item.value);
    }
    document.getElementById(id).selected = values;
    if (onChange) {
      onChange(values);
    }
  };

  const defaultOptions = defaultValues?.map((v) => {
    if (!v?.label) return { value: v, label: v };
    return v;
  });

  useEffect(() => {
    if (defaultOptions) {
      if (isMulti) {
        updateOptions(defaultOptions);
      } else {
        updateOptions(defaultOptions[0]);
      }
    }
    setStateId(utils.getRandomId());
  }, [utils.hashObject(defaultValues)]);

  const colourStyles = {
    control: style?.control ? style?.control : (styles) => ({ ...styles }),
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: "var(--bg-color-darker)",
        borderRadius: "var(--border-radius-small)",
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: "var(--text-color-light)",
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data.color,
      ":hover": {
        color: "var(--text-color-light)",
      },
    }),
  };

  return (
    <div className={`dropdown-container ${className}`}>
      <div className="label">{label}</div>
      <input id={id} hidden={true} readOnly={true} />
      <Select
        key={stateId}
        defaultValue={defaultOptions}
        onChange={updateOptions}
        options={options}
        isMulti={isMulti}
        styles={colourStyles}
        theme={(theme) => ({
          ...theme,
          borderRadius: style?.borderRadius ? style?.borderRadius : "0",
          colors: {
            ...theme.colors,
            primary25: "var(--bg-color-darker)", // option hover color
            primary: "var(--main-color)", // input box border color on selected
            neutral0: style?.neutral0 ? style?.neutral0 : "var(--bg-color)", // input bg color
            neutral80: "var(--text-color)", // tag text color
            neutral20: "var(--border-color)", // border color
            neutral30: "var(--border-color-darker)", // border hover color
          },
        })}
        menuShouldScrollIntoView={false}
        menuPortalTarget={document.body}
      />
    </div>
  );
};

export default Dropdown;
