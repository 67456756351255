import { useCallback, useState } from 'react';
import { useGlobalState } from '../state/GlobalStateProvider';
import { useBackendApi } from './backendHelper';

const usePetcatHelper = () => {
  const backendHelper = useBackendApi();
  const [points, setPoints] = useState();
  const [{ user }] = useGlobalState();
  const [lastEntry, setLastEntry] = useState();
  const [activeCat,setActiveCat]=useState(false)

  const getPoints = useCallback(async () => {
    if (user?._id) {
      let message = {
        userId: user?._id,
        type: 1,
      };
      try {
        let data = await backendHelper.user.getTotalMewPoints(message);
        if (data) {
          setPoints(data);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }, [user, backendHelper]);

  const getLastEntry = useCallback(async () => {
    let message = {
      userId: user?._id,
      type: 1,
    };
    try {
      let data = await backendHelper.user.getLatestMewPointEntry(message);
      if (data) {
        setLastEntry(data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, [user, backendHelper]);

  const addEntry = useCallback(
    async event => {
      event?.stopPropagation();

      if (user?._id) {
        let message = {
          userId: user?._id,
          type: 1,
          points: 1,
        };
        try {
          await backendHelper.user.addMewPointEntry(message);
          setActiveCat(true)

          setTimeout(() => {
            setActiveCat(false)
          }, 15000);
          
          alert.success('Mew Point Claimed');
        await  getPoints();
        await  getLastEntry();

        } catch (err) {
          alert.error('Something went wrong!');
          console.log(err);
        }
      }
    },
    [user, backendHelper],
  );

  return {
    lastEntry,
    addEntry,
    getLastEntry,
    getPoints,
    points,
    activeCat
  };
};

export default usePetcatHelper;
