import './OauthFailed.scss';

const OauthFailed = ({ oauthError }) => {
  let title;
  let description;

  // switch (oauthError) {
  switch ('account_merge_error') {
    case 'auth_error':
      title = 'Login Failed';
      description = 'Could not login with your account. Please try again or try with a different account.';
      break;
    case 'account_merge_error':
      title = "Oops! You can't use this account.";
      description = 'This account is already linked to another wallet. Kindly login with another account to complete this quest.';
      break;
  }
  return (
    <>
      <h2>AUTHENTICATION FAILED</h2>
      <h1>{title}</h1>
      <div className='x'>❌</div>
      <p>{description}</p>
    </>
  );
};

export default OauthFailed;
