import ButtonApi from '../../../../components/ButtonApi';
import Icons from '../../../../components/icons/Icons';
import ToolTip from '../../../../components/toolTip/Tooltip';
import { useEvent } from '../EventProvider';
import { useMint } from './MintNftProvider';

const StepLast = () => {
  const [{ username, suffix, mintFee, feeToken, chainName, isDisabled, mint }] = useMint();
  const [{ campaign }] = useEvent();

  const hasDynamicFee = campaign?.mintFeeConfig?.dynamicPriceOnUsername;

  const caption = mintFee ? (
    <>
      Mint fee of {mintFee} {feeToken} applicable on {chainName}
    </>
  ) : (
    <>Mint on {chainName}</>
  );

  return (
    <>
      <div className='usernameCaption'>
        <h1 className='username ellipse'>
          {username}.{suffix}
        </h1>
        <p className='mintcaption'>
          <ToolTip
            className='dynamic-fee-tooltip'
            tipContent={hasDynamicFee ? <p>This NFT has dynamicClaimToll enabled. The claim toll fee is calculated on the basis of character length.</p> : <></>}
            showOnClick={true}
          >
            <Icons icon={'info'} />
          </ToolTip>
          <i>{caption}</i>
        </p>
      </div>

      <ButtonApi className='primary' onClick={mint} disabled={isDisabled}>
        Mint Now
      </ButtonApi>
    </>
  );
};

export default StepLast;
