import React, { useState, useEffect } from "react";
import { useForm, useFormContext, Controller, FormProvider, useFieldArray, get } from "react-hook-form";
import "./AdminAddEvent.scss";
import ObjectID from "bson-objectid";
import { useBackendApi } from "../../../helpers/backendHelper";
import { types, typesComplex } from "../../../constants";
// import { useBackendApi } from "helpers/restApiHelper";
// import { types, typesComplex } from "Constants";

const getCampaign = async (backendApi, tokenAddr, campaign) => {
  try {
    return await backendApi.communities.tokens.getCollection(tokenAddr, campaign);
  } catch (e) {
    console.error(e);
    return null;
  }
};
const _WatchData = (data) => {
  return (
    <div>
      <h2>Watched Data</h2>
      <br />
      <pre>{JSON.stringify(data, null, 2)}</pre>
    </div>
  );
};

const _generalData = () => {
  const { register } = useFormContext();
  return (
    <div>
      <h1>General Data</h1>
      <h5>Name</h5>
      <input {...register("name")} placeholder="Name" />
      <h5>Caption</h5>
      <input {...register("caption")} placeholder="Caption" />
      <h5>Image</h5>
      <input {...register("image")} placeholder="Image" />
      <h5>Description</h5>
      <textarea {...register("description")} placeholder="description" />
      <h5>Type</h5>
      <select {...register("type")}>
        {Object.entries(types.EventTypes).map(([key, value]) => (
          <option key={key} value={value}>
            {key}
          </option>
        ))}
      </select>
    </div>
  );
};

const _ConfigComponent = () => {
  const { control, register, setValue } = useFormContext();
  const [isTokenBackConfigEnabled, setIsTokenBackConfigEnabled] = useState(false);

  return (
    <div>
      <h1>Config</h1>
      <h5>Name Highlight</h5>
      <input {...register("config.nameHighlight")} placeholder="Name Highlight" />

      <h5>Highlight Color</h5>
      <input {...register("config.highlightColor")} placeholder="Highlight Color" />
      <h5>tweetConfig.text</h5>
      <input {...register("config.tweetConfig.text")} placeholder="Tweet Config Text" />
      <label>Enable Token Back Config</label>
      <input
        type="checkbox"
        checked={isTokenBackConfigEnabled}
        onChange={(e) => setIsTokenBackConfigEnabled(e.target.checked)}
      />

      {isTokenBackConfigEnabled && (
        <div>
          <h2>Token Back Config</h2>
          <h5>Distributor Address</h5>
          <input {...register("config.tokenBackConfig.distributor.address")} placeholder="Distributor Address" />

          <h5>Chain ID</h5>
          <Controller
            control={control}
            name="config.tokenBackConfig.distributor.chainId"
            defaultValue={137} // Set the default value
            render={({ field }) => (
              <select {...field} onChange={(e) => field.onChange(parseInt(e.target.value, 10))}>
                <option value="137">137</option>
                <option value="5000">5000</option>
                <option value="1">1</option>
                <option value="5">5</option>
              </select>
            )}
          />

          <h5>XP Threshold</h5>
          <Controller
            control={control}
            name="config.tokenBackConfig.xpThreshold"
            render={({ field }) => (
              <input
                {...field}
                placeholder="XP Threshold"
                type="number"
                onChange={(e) => field.onChange(e.target.value === "" ? e.target.value : Number(e.target.value))}
              />
            )}
          />

          <h5>Disabled Text</h5>
          <input {...register("config.tokenBackConfig.disabledText")} placeholder="Disabled Text" />

          <h5>Reward Token Symbol</h5>
          <input {...register("config.tokenBackConfig.rewardTokenSymbol")} placeholder="Reward Token Symbol" />
        </div>
      )}
    </div>
  );
};

const _RewardsItems = ({ rewardIndex, eventIndex }) => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `events.${eventIndex}.rewards.${rewardIndex}.items`,
  });

  const [jsonInput, setJsonInput] = useState("");

  const handleAddRewardItem = () => {
    try {
      const jsonData = JSON.parse(jsonInput);
      append(jsonData);
      setJsonInput("");
      alert("Reward item added");
    } catch (error) {
      console.error("Invalid JSON:", error);
    }
  };

  return (
    <div className="rewards-container">
      <h5>Rewards Item (JSON Format)</h5>
      <br />
      {fields.map((itemField, itemIndex) => (
        <div key={itemField.id}>
          <div className="rewardsitem-display">
            <h1>Rewards Item: {itemIndex}</h1>
            {/* {Object.entries(itemField).map(([key, value]) => (
              <p key={key}><strong>{key}:</strong> {value.toString()}</p>
            ))} */}
            <button className="removeButton" type="button" onClick={() => remove(itemIndex)}>
              Remove Item
            </button>
          </div>
        </div>
      ))}

      <textarea
        className="bottom-textarea"
        value={jsonInput}
        onChange={(e) => setJsonInput(e.target.value)}
        placeholder={`Enter JSON for new reward item`}
      />

      <button className="addButton" type="button" onClick={handleAddRewardItem}>
        Add Reward Item
      </button>
    </div>
  );
};

const _Rewards = ({ eventIndex, eventName }) => {
  const { control, register, setValue, watch } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `events.${eventIndex}.rewards`,
  });
  let rewardid = ObjectID().toHexString();

  const nameValue = watch(`events.${eventIndex}.rewards.name`);

  // useEffect(() => { // fix this
  //   if (nameValue) {
  //     setValue(`events.${eventIndex}.rewards.description`, nameValue);
  //   }
  // }, [nameValue, setValue, eventIndex]);
  return (
    <div className="rewards">
      {fields.map((field, index) => (
        <div className="eventRewards" key={field.id}>
          <div>
            <button className="removeButton" type="button" onClick={() => remove(index)}>
              Clear
            </button>
            <h1>Task Rewards</h1>
            <h5>
              Reward for Task : {` ${eventName}`} , Reward index : {index}
            </h5>
            <h5>Id</h5>
            <input
              {...register(`events.${eventIndex}.rewards.${index}.id`)}
              placeholder="Reward ID"
              value={rewardid}
              readOnly
            />
            <h5>isHightLight</h5>
            <Controller
              name={`events.${eventIndex}.rewards.${index}.isHighlight`}
              control={control}
              defaultValue={true} // Set the default value as boolean true
              render={({ field }) => (
                <select {...field} onChange={(e) => field.onChange(e.target.value === "true")}>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              )}
            />

            <h5>Name</h5>
            <select {...register(`events.${eventIndex}.rewards.${index}.name`)}>
              <option value="Points">Points</option>
              <option value="Lootbox">Lootbox</option>
            </select>
            <h5>Description</h5>
            <select {...register(`events.${eventIndex}.rewards.${index}.description`)}>
              <option value="Points">Points</option>
              <option value="Lootbox">Lootbox</option>
            </select>
            <h5>Image URL</h5>
            <input {...register(`events.${eventIndex}.rewards.${index}.image`)} placeholder="image" />
          </div>
          <div className="rewardsitem">
            <_RewardsItems rewardIndex={index} eventIndex={eventIndex} />
          </div>
        </div>
      ))}
      <button
        className="addButton"
        type="button"
        onClick={() => append({ isHighlight: true, id: ObjectID().toHexString() })}
      >
        Add Reward
      </button>
    </div>
  );
};

const _PreviewRewardsComponent = ({ bsonId }) => {
  const { register, control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `eventNames.${bsonId}.preview.rewards`,
  });

  return (
    <div>
      {fields.map((field, index) => (
        <div key={field.id}>
          <button className="removeButton" type="button" onClick={() => remove(index)}>
            Remove Preview Reward
          </button>
          <h1>Preview Rewards for : {bsonId}</h1>
          <input {...register(`eventNames.${bsonId}.preview.rewards.${index}`)} placeholder="ID" />
        </div>
      ))}
      <button
        className="addButton"
        type="button"
        onClick={() => append({ id: "", name: "", description: "", image: "", value: "" })}
      >
        Add Preview Reward
      </button>
    </div>
  );
};

const _PreviewEventNameComponent = ({ bsonId }) => {
  const { register } = useFormContext();
  return (
    <div>
      <h1>Preview Event Name for : {bsonId}</h1>
      <h5>Image URl</h5>
      <input {...register(`eventNames.${bsonId}.preview.image`)} placeholder="image" />
      <h5>bg</h5>
      <input {...register(`eventNames.${bsonId}.preview.bg`)} placeholder="bg" />
      <h5>rewardBy</h5>
      <input {...register(`eventNames.${bsonId}.preview.rewardBy`)} placeholder="rewardBy" />
      <h5>Preview Description</h5>
      <textarea {...register(`eventNames.${bsonId}.preview.description`)} placeholder="Preview Description" />
      <_PreviewRewardsComponent bsonId={bsonId} />
    </div>
  );
};

const _EventNamesComponent = () => {
  const [eventNames, setEventNames] = useState({});
  const [indexCount, setIndexCount] = useState(0);
  const [previewEnabled, setPreviewEnabled] = useState({}); // New state to track previews
  const { register, setValue, unregister } = useFormContext();

  const addEventName = () => {
    const newBsonId = new ObjectID().toHexString();
    setEventNames((prevEventNames) => ({
      ...prevEventNames,
      [newBsonId]: { index: indexCount, name: "", label: "", config: "" },
    }));
    setValue(`eventNames.${newBsonId}.index`, indexCount);
    setValue(`eventNames.${newBsonId}.name`, "");
    setValue(`eventNames.${newBsonId}.label`, "");
    setValue(`eventNames.${newBsonId}.config.nameHighlight`, "");
    setValue(`eventNames.${newBsonId}.dateThresholdMillis`, "");
    setValue(`eventNames.${newBsonId}.xpThreshold`, 0);
    setIndexCount((prevCount) => prevCount + 1);
  };

  const removeEventName = (bsonId) => {
    const updatedEventNames = { ...eventNames };
    delete updatedEventNames[bsonId];
    setEventNames(updatedEventNames);

    unregister(`eventNames.${bsonId}.name`);
    unregister(`eventNames.${bsonId}.label`);
    unregister(`eventNames.${bsonId}.config.nameHighlight`);
    unregister(`eventNames.${bsonId}.dateThresholdMillis`);
    unregister(`eventNames.${bsonId}.xpThreshold`);
  };

  const handleInputChange = (bsonId, field, value) => {
    let processedValue;

    switch (field) {
      case "xpThreshold":
        // Parse as integer
        processedValue = parseInt(value, 10);
        if (isNaN(processedValue)) {
          processedValue = 0;
        }
        break;
      case "dateThresholdMillis":
        if (field === "dateThresholdMillis" && !isNaN(Date.parse(value))) {
          processedValue = new Date(value).getTime();
        } else {
          processedValue = 0;
        }
        break;
      default:
        processedValue = value;
    }

    setEventNames((prevEventNames) => ({
      ...prevEventNames,
      [bsonId]: { ...prevEventNames[bsonId], [field]: processedValue },
    }));
    setValue(`eventNames.${bsonId}.${field}`, processedValue);
  };

  const addPreview = (bsonId) => {
    setPreviewEnabled((prev) => ({ ...prev, [bsonId]: true }));
  };

  return (
    <div>
      {Object.entries(eventNames).map(([bsonId, eventData]) => (
        <div key={bsonId}>
          <button className="removeButton" type="button" onClick={() => removeEventName(bsonId)}>
            Remove Event Name
          </button>
          <h1>Event Name for ID: {bsonId}</h1>

          <h5>Name</h5>
          <input
            placeholder="Name"
            value={eventData.name}
            {...register(`eventNames.${bsonId}.name`)}
            onChange={(e) => handleInputChange(bsonId, "name", e.target.value)}
          />
          <h5>Label</h5>
          <input
            placeholder="Label"
            value={eventData.label}
            {...register(`eventNames.${bsonId}.label`)}
            onChange={(e) => handleInputChange(bsonId, "label", e.target.value)}
          />
          <h5>Config nameHighlight</h5>
          <input
            placeholder="Config"
            value={eventData.config}
            {...register(`eventNames.${bsonId}.config.nameHighlight`)}
            onChange={(e) => handleInputChange(bsonId, "config", e.target.value)}
          />
          <h5>xpThreshold</h5>
          <input
            type="number"
            placeholder="xpThreshold"
            {...register(`eventNames.${bsonId}.xpThreshold`, {
              setValueAs: (v) => (v === "" ? null : parseInt(v)),
            })}
            defaultValue={eventData.xpThreshold || 0}
            onChange={(e) => handleInputChange(bsonId, "xpThreshold", e.target.value)}
          />
          <h5>dateThresholdMillis</h5>
          <input
            type="datetime-local"
            placeholder="YYYY-MM-DDTHH:MM"
            value={
              eventData.dateThresholdMillis ? new Date(eventData.dateThresholdMillis).toISOString().slice(0, -1) : ""
            }
            {...register(`eventNames.${bsonId}.dateThresholdMillis`)}
            onChange={(e) => handleInputChange(bsonId, "dateThresholdMillis", e.target.value)}
          />
          {previewEnabled[bsonId] && <_PreviewEventNameComponent bsonId={bsonId} />}

          <button className="previewButton" type="button" onClick={() => addPreview(bsonId)}>
            Add Preview
          </button>
        </div>
      ))}
      <button className="addButton" type="button" onClick={addEventName}>
        Add Event Name
      </button>
    </div>
  );
};

const _EventsGuide = ({ eventIndex }) => {
  const { control, register } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `events.${eventIndex}.guide`,
  });
  let rewardid = ObjectID().toHexString();

  return (
    <div>
      {fields.map((field, index) => (
        <div key={field.id}>
          <h1>Events Guide data : {index}</h1>
          <input {...register(`events.${eventIndex}.guide.${index}`)} placeholder="Reward ID" />
          <button className="removeButton" type="button" onClick={() => remove(index)}>
            Remove Guide
          </button>
        </div>
      ))}
      <button className="addButton" type="button" onClick={() => append({})}>
        Add Guide
      </button>
    </div>
  );
};

const _Events = ({ event }) => {
  const { control, register, setValue, watch } = useFormContext();
  const [expandedEvent, setExpandedEvent] = useState(null);

  const toggleEventVisibility = (index) => {
    if (expandedEvent === index) {
      setExpandedEvent(null);
    } else {
      setExpandedEvent(index);
    }
  };

  const backendApi = useBackendApi();
  const [campaignNameOptions, setCampaignNameOptions] = useState([]);
  const { fields, append, remove } = useFieldArray({
    control,
    name: "events",
  });
  const getCampaigns = async () => {
    try {
      const eventsPageData = await backendApi.communities.tokens.events.get();
      const { campaign, tokenAddr } = eventsPageData?.events?.[0];
      const campaignData = await getCampaign(backendApi, tokenAddr, campaign);
      setCampaignNameOptions([
        {
          chainId: campaignData.chainId,
          name: campaignData.name,
          commId: campaignData.commId,
          tokenAddr: campaignData.tokenAddr,
        },
      ]);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getCampaigns();
  }, []);

  useEffect(() => {
    if (campaignNameOptions.length > 0 && fields.length > 0) {
      const firstCampaign = campaignNameOptions[0];
      fields.forEach((field, index) => {
        setValue(`events.${index}.campaign`, firstCampaign.name);
        setValue(`events.${index}.tokenAddr`, firstCampaign.tokenAddr);
        setValue(`events.${index}.chainId`, firstCampaign.chainId);
      });
    }
  }, [campaignNameOptions, fields, setValue]);

  const handleDateTimeChange = (eventIndex, field, value) => {
    setValue(`events.${eventIndex}.${field}TimeStr`, value);
    const timestamp = value ? new Date(value).getTime() : null;
    setValue(`events.${eventIndex}.${field}Millis`, timestamp);
  };
  const handleJsonInputChange = (index, jsonString) => {
    try {
      const eventType = watch(`events.${index}.type`);
      const jsonData = JSON.parse(jsonString);

      if (jsonData && jsonData[eventType]) {
        Object.entries(jsonData[eventType]).forEach(([key, value]) => {
          setValue(`events.${index}.${eventType}.${key}`, value);
        });
      }
    } catch (e) {
      console.error(e);
    }
  };
  const keys = event ? Object.keys(event) : [];
  let eventid = ObjectID().toHexString();
  return (
    <div>
      {fields.map((field, index) => (
        <div key={field.id}>
          <h1>Tasks</h1>
          <button className="removeButton" type="button" onClick={() => remove(index)}>
            Remove Event
          </button>

          <h5>levelId index : {index}</h5>
          <select {...register(`events.${index}.levelId`)} placeholder="levelId">
            <option value="">Select id</option>
            {event &&
              Object.keys(event).map((key) => (
                <option key={key} value={key}>
                  {key} - {event[key].name}
                </option>
              ))}
          </select>

          <h5>id</h5>
          <input {...register(`events.${index}.id`)} placeholder="id" value={eventid} readOnly />
          <h5>Name</h5>
          <input {...register(`events.${index}.name`)} placeholder="name" />
          <h5>Campaign: {watch(`events.${index}.campaign`)}</h5>
          <h5>Token Address: {watch(`events.${index}.tokenAddr`)}</h5>
          <h5>Chain ID: {watch(`events.${index}.chainId`)}</h5>
          <h5>Description</h5>
          <textarea {...register(`events.${index}.description`)} placeholder="description" />
          <h5>Image URL</h5>
          <input {...register(`events.${index}.image`)} placeholder="image" />
          <label>Start Date & Time</label>
          <input
            type="datetime-local"
            onChange={(e) => handleDateTimeChange(index, "startDate", e.target.value)}
            placeholder="Start Date & Time"
          />
          <label>End Date & Time</label>
          <input
            type="datetime-local"
            onChange={(e) => handleDateTimeChange(index, "endDate", e.target.value)}
            placeholder="End Date & Time"
          />
          <h1>Set Time Zone</h1>
          <select {...register(`events.${index}.timezone`)}>
            <option value="Asia/Kolkata">Asis/Kolkata</option>
            <option value="Asia/Kathmandu">Asia/Kathmandu</option>
          </select>
          <h1>Event Type</h1>
          <select {...register(`events.${index}.type`)}>
            {Object.entries(typesComplex.EventTypes).map(([key, value]) => (
              <option key={key} value={key}>
                {value.name}
              </option>
            ))}{" "}
          </select>
          <_EventsGuide eventIndex={index} />
          <h5>Event Data (JSON Format)</h5>
          <textarea
            onBlur={(e) => handleJsonInputChange(index, e.target.value)}
            placeholder={`Enter JSON data for ${watch(`events.${index}.type`)}`}
          />
          <h5>Rarity Drop</h5>
          <input
            {...register(`events.${index}.rarityDrop`, {
              setValueAs: (v) => (v === "" ? "" : parseInt(v, 10)),
            })}
            placeholder="Rarity Drop"
            type="number"
            step="1"
          />
          <h5>Config Button Text</h5>
          <input
            {...register(`events.${index}.config.buttonText`)}
            placeholder="Config button text for the Event button"
          />
          <_Rewards eventIndex={index} eventName={watch(`events.${index}.name`)} />
        </div>
      ))}
      <button className="addButton" type="button" onClick={() => append({ rewards: [{}] })}>
        Add Event
      </button>
    </div>
  );
};

const _LevelConfigRewards = ({ eventIndex }) => {
  const { register } = useFormContext();
  return (
    <div>
      <h1>Level Config Rewards</h1>
      <h5>type</h5>
      <input {...register(`levelConfig.levels.${eventIndex}.rewards.type`)} placeholder="type" />
      <h5>image</h5>
      <input {...register(`levelConfig.levels.${eventIndex}.rewards.image`)} placeholder="image" />
      <h5>animation_url</h5>
      <input {...register(`levelConfig.levels.${eventIndex}.rewards.animation_url`)} placeholder="animation_url" />
      <h5>xpThreshold</h5>
      <input
        type="number"
        step="1"
        {...register(`levelConfig.levels.${eventIndex}.rewards.xpThreshold`, {
          setValueAs: (value) => (value === "" ? null : parseInt(value, 10)),
        })}
        placeholder="xpThreshold"
      />
    </div>
  );
};

const _LevelConfig = () => {
  const { register, control } = useFormContext();
  const { append, remove, fields } = useFieldArray({
    control,
    name: "levelConfig.levels",
  });
  return (
    <>
      <h1>Level Config</h1>
      {fields.map((field, index) => (
        <div key={field.id}>
          <button className="removeButton" type="button" onClick={() => remove(index)}>
            Remove Level index : {index}
          </button>
          <h5>Level Index</h5>
          <input
            {...register(`levelConfig.levels.${index}.index`, {
              setValueAs: (value) => (value === "" ? null : parseInt(value, 10)),
            })}
            defaultValue={index}
            readOnly
          />

          <h5>Level Name</h5>
          <input {...register(`levelConfig.levels.${index}.name`)} placeholder="Name" />
          <h5>xpThreshold</h5>
          <input
            type="number"
            step="1"
            {...register(`levelConfig.levels.${index}.xpThreshold`, {
              setValueAs: (value) => (value === "" ? null : parseInt(value, 10)),
            })}
            placeholder="xpThreshold"
          />

          <_LevelConfigRewards rewardIndex={index} />
        </div>
      ))}
      <button className="addButton" type="button" onClick={() => append({})}>
        Add Level Config
      </button>
    </>
  );
};

const _MintTextConfig = () => {
  const { register } = useFormContext();
  return (
    <>
      <h1>Mint Text Config</h1>
      <h5>text</h5>
      <input {...register("mintTextConfig.text")} placeholder="Text" />
      <h5>skill</h5>
      <input {...register("mintTextConfig.skill")} placeholder="Skill" />
    </>
  );
};

const _OverViewConfig = () => {
  const { register } = useFormContext();
  return (
    <div>
      <h1>Overview Config</h1>
      <h5>NameHighlight</h5>
      <input {...register("overview.config.nameHighLight")} placeholder="NameHighlight" />
      {/* <h5>Color</h5>
      <input {...register("overview.config.color")} type="color" placeholder="Color" /> */}
    </div>
  );
};

const _OverViewCards = () => {
  const { register, control } = useFormContext();
  const { append, remove, fields } = useFieldArray({
    control,
    name: "overview.cards",
  });
  return (
    <div>
      <h1>Overview Cards</h1>
      {fields.map((field, index) => (
        <div key={field.id}>
          <h5>Value</h5>
          <input {...register(`overview.cards.${index}.value`)} placeholder="Value" />
          <h5>Description</h5>
          <textarea {...register(`overview.cards.${index}.description`)} placeholder="Description" />
          <button className="removeButton" type="button" onClick={() => remove(index)}>
            Remove Overview Card
          </button>
        </div>
      ))}
      <button className="addButton" type="button" onClick={() => append({})}>
        Add Overview Card
      </button>
    </div>
  );
};

const _OverViewTags = () => {
  const { register, control } = useFormContext();
  const { append, remove, fields } = useFieldArray({
    control,
    name: "overview.tags",
  });
  return (
    <div>
      <h1>Overview Tags</h1>
      {fields.map((field, index) => (
        <div key={field.id}>
          <h5>Overview Tags Value</h5>
          <select {...register(`overview.tags.${index}`)}>
            {Object.entries(types.EventTag).map(([key, value]) => (
              <option key={key} value={value}>
                {key}
              </option>
            ))}
          </select>
          <button className="removeButton" type="button" onClick={() => remove(index)}>
            Remove Overview Tags
          </button>
        </div>
      ))}
      <button className="addButton" type="button" onClick={() => append({})}>
        Add Overview Tags
      </button>
    </div>
  );
};

const _OverView = () => {
  const { register } = useFormContext();
  return (
    <div>
      <h1>Overview</h1>
      <h5>title</h5>
      <input {...register("overview.title")} placeholder="title" />
      <h5>text</h5>
      <input {...register("overview.text")} placeholder="text" />
      <h5>nameSuffix</h5>
      <input {...register("overview.nameSuffix")} placeholder="nameSuffix" />
      <_OverViewConfig />
      <_OverViewTags />
      <_OverViewCards />
    </div>
  );
};

const _InventoryConfig = () => {
  const { register, control } = useFormContext();

  return (
    <div className="inventory-config-container">
      <Controller
        name="inventoryConfig.canEquip"
        control={control}
        defaultValue={true} // Set the default value as needed
        render={({ field }) => (
          <>
            <div className="radio-button-container">
              <input
                type="radio"
                value="true"
                onChange={(e) => field.onChange(e.target.value === "true")}
                checked={field.value === true}
                id="canEquip-true"
              />
              <label htmlFor="canEquip-true">True</label>
            </div>
            <div className="radio-button-container">
              <input
                type="radio"
                value="false"
                onChange={(e) => field.onChange(e.target.value === "true")}
                checked={field.value === false}
                id="canEquip-false"
              />
              <label htmlFor="canEquip-false">False</label>
            </div>
          </>
        )}
      />
    </div>
  );
};

const AdminAddEvent = () => {
  const backendApi = useBackendApi();
  const methods = useForm();
  const { watch } = methods;
  const eventNamesid = watch("eventNames") || {};
  const cleaned_dataWatch = cleanObject(watch());

  const components = {
    _generalData: <_generalData />,
    _ConfigComponent: <_ConfigComponent />,
    _EventNames: <_EventNamesComponent event={eventNamesid} />,
    _Events: <_Events event={eventNamesid} />,
    _LevelConfig: <_LevelConfig />,
    _MintText: <_MintTextConfig />,
    _OverView: <_OverView />,
    _InventoryConfig: <_InventoryConfig />,
    _WatchData: <_WatchData data={cleaned_dataWatch} />,
  };

  const [activeComponent, setActiveComponent] = useState("_generalData");
  const componentKeys = Object.keys(components);
  const isLastComponent = activeComponent === "_WatchData";
  const currentComponentIndex = componentKeys.indexOf(activeComponent);
  const isNextComponentAvailable = currentComponentIndex < componentKeys.length - 1;

  const goToNextComponent = () => {
    if (isNextComponentAvailable) {
      const nextComponentKey = componentKeys[currentComponentIndex + 1];
      setActiveComponent(nextComponentKey);
    }
  };
  const onSubmit = async (data) => {
    alert("Preparing data for submission...");
    const cleanData = cleanObject(data);

    backendApi.communities.tokens.events
      .post(cleanData)
      .then(() => alert.info("Event Added"))
      .catch((error) => {
        console.error("Error submitting data: ", error);
        alert.error("Error in submission");
      });
  };

  function cleanObject(obj) {
    if (!obj || typeof obj !== "object") {
      return obj;
    }
    if (Array.isArray(obj)) {
      return obj
        .map((v) => cleanObject(v))
        .filter((v) => !(v == null || v === "" || (typeof v === "object" && Object.keys(v).length === 0)));
    }
    return Object.keys(obj).reduce((acc, key) => {
      const value = cleanObject(obj[key]);
      if (value == null || value === "" || (typeof value === "object" && Object.keys(value).length === 0)) {
        return acc;
      }
      acc[key] = value;
      return acc;
    }, {});
  }

  return (
    <div className="AdminAddEvent-layout">
      <div className="sidebar">
        {componentKeys.map((key) => (
          <button key={key} onClick={() => setActiveComponent(key)}>
            {key}
          </button>
        ))}
      </div>
      <div className="formcontainer">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            {componentKeys.map((key) => (
              <div key={key} style={{ display: activeComponent === key ? "block" : "none" }}>
                {components[key]}
              </div>
            ))}
            {isLastComponent ? (
              <input type="submit" value="Submit" />
            ) : (
              <button type="button" onClick={goToNextComponent}>
                Next
              </button>
            )}
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default AdminAddEvent;
