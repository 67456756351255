// src/AnimatedButton.js
import React, { useState } from 'react';
import './AnimatedButton.scss';

const AnimatedButton = ({ text, onClick }) => {
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setIsClicked(true);
    setTimeout(() => {
      setIsClicked(false);
      onClick();
    }, 500); // delay for 2 seconds
  };

  return (
    <button className={`animated-button ${isClicked ? 'clicked' : ''}`} onClick={handleClick}>
      {isClicked ? (
        <span className='arrow'>
          <svg xmlns='http://www.w3.org/2000/svg' width='26' height='23' viewBox='0 0 26 23' fill='none'>
            <path
              d='M25.4357 12.2638C26.0214 11.678 26.0214 10.7283 25.4357 10.1425L15.8897 0.596523C15.3039 0.0107365 14.3542 0.0107365 13.7684 0.596523C13.1826 1.18231 13.1826 2.13206 13.7684 2.71784L22.2537 11.2031L13.7684 19.6884C13.1826 20.2742 13.1826 21.2239 13.7684 21.8097C14.3542 22.3955 15.3039 22.3955 15.8897 21.8097L25.4357 12.2638ZM0 12.7031H24.375V9.70312H0V12.7031Z'
              fill='#FF5506'
            />
          </svg>
        </span>
      ) : (
        text
      )}
    </button>
  );
};

export default AnimatedButton;
