import objectHash from 'object-hash';
import { v4 as uuidv4 } from 'uuid';
import { chains } from 'walletconnector';
import { cosmosChains, dateMillis, resources } from './constants';
const limitApi = {};
/**
 * use when accessing tracked state[objectName] in useEffect
 * @param {*} api callback should handle all state manipulation and not expect anything in return
 */
export const limitedApi = (lockName, api = () => {}) => {
  if (limitApi[lockName]) return;

  limitApi[lockName] = true;
  api().finally(() => (limitApi[lockName] = false));
};

export const largeText = text => {
  if (Math.random() > 0.5) return text;

  return Array(text.length).fill(text).join(' ');
};

export const numberShort = number => {
  let result;
  const v = Math.round(number).toString();
  if (v.length < 4) {
    result = `${number}`;
  } else if (v.length < 7) {
    result = `${(number / 1000).toFixed(2)}K`;
  } else if (v.length < 10) {
    result = `${(number / 1000000).toFixed(2)}M`;
  } else if (v.length < 13) {
    result = `${(number / 1000000000).toFixed(2)}B`;
  } else {
    result = `${(number / 1000000000000).toFixed(2)}T`;
  }

  result = result.replace(/\.00([KMBT]?)$/, '$1');
  result = result.replace(/(\.\d)0([KMBT]?)$/, '$1$2');

  return result;
};

export const getFileUrl = url => {
  if (!url) return undefined;
  if (url?.startsWith('ipfs://')) return resources.img.ipfs(url);
  return url;
};

// bson is of 12 bytes, so simple append 0x to it
export const bsonIdToBytes12 = bsonObjectId => {
  if (!bsonObjectId || bsonObjectId.length !== 24) return undefined;
  return `0x${bsonObjectId}`;
};
export const getRandomId = () => {
  return uuidv4();
};

export const tweetTemplateParser = (text, dynamicVariables = {}) => {
  let result = text;
  const { mercleReferral, communityReferral, communityEventsPage, openseaLink } = dynamicVariables;
  if (mercleReferral) {
    result = result.replacell('<<mercle_referral>>', mercleReferral);
  }
  if (communityReferral) {
    result = result.replaceAll('<<community_referral>>', communityReferral);
  }
  if (communityEventsPage) {
    result = result.replaceAll('<<community_events_page>>', communityEventsPage);
  }
  if (openseaLink) {
    result = result.replaceAll('<<opensea_link>>', openseaLink);
  }

  result = encodeURIComponent(result);
  return result;
};

export const decodeBase64ToObj = base64Str => {
  if (!base64Str) return undefined;
  return JSON.parse(window.atob(base64Str));
};

export const countdown = (_dateMillis, maxUnit) => {
  let millis = _dateMillis - Date.now();
  const ago = millis < 0;

  millis = Math.abs(millis);

  const W = dateMillis.week_1;
  const D = dateMillis.day_1;
  const H = dateMillis.hour_1;
  const M = dateMillis.min_1;
  const S = 1000;

  const weeks = (millis / W) | 0;
  const days = ((millis - weeks * W) / D) | 0;
  let hours = ((millis - weeks * W - days * D) / H) | 0;
  const mins = ((millis - weeks * W - days * D - hours * H) / M) | 0;
  const secs = ((millis - weeks * W - days * D - hours * H - mins * M) / S) | 0;

  if (maxUnit === 'hour' || maxUnit === 'H') {
    hours = (millis / H) | 0;
  }

  let countdownDateObj = { weeks, days, hours, mins, secs };

  let validUnit = Object.entries(countdownDateObj)?.find(time => time[1] > 0)?.[0];

  return { ago, ...countdownDateObj, validUnit };
};

export const countdownString = dateMillis => {
  const _countdown = countdown(dateMillis);
  let str = [];
  if (_countdown.weeks) str.push(`${_countdown.weeks} Week${_pluralize(_countdown.weeks)}`);
  if (_countdown.days) str.push(`${_countdown.days} Day${_pluralize(_countdown.days)}`);
  if (_countdown.hours) str.push(`${_countdown.hours} Hour${_pluralize(_countdown.hours)}`);
  if (_countdown.mins) str.push(`${_countdown.mins} Min${_pluralize(_countdown.mins)}`);
  if (_countdown.secs) str.push(`${_countdown.secs} Sec${_pluralize(_countdown.secs)}`);
  return { str: str.slice(0, 2), ago: _countdown.ago };
};

const _pluralize = count => (count == 1 ? '' : 's');

export const isValidEmail = email => {
  if (!email) return false;
  return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
};

export const isValidUrl = url => {
  if (!url) return false;
  return /^http(s)?:\/\/.*$/.test(url);
};

export const getElementValue = (id, valueSelector) => {
  const element = document.getElementById(id);
  if (valueSelector) return element?.[valueSelector];
  return element?.value;
};

export const isEmpty = value => {
  if (value == null) return true;
  if (typeof value === 'string' || Array.isArray(value)) return value.length === 0;
  if (typeof value === 'object') return Object.keys(value).length === 0;
  return !value;
};

export const isLightTheme = () => {
  const theme = localStorage.getItem('theme');
  return theme === 'light';
};

export const stringValidator = ({ str, noSpace = false, minLength = 1, maxLength }) => {
  if (!str) {
    return {
      isValid: false,
      errors: ['invalid string'],
    };
  }
  const errors = [];

  if (noSpace && /\s/.test(str)) {
    errors.push('String must not contain spaces.');
  }

  if (str.length < minLength) {
    errors.push(`String must be at least ${minLength} characters long.`);
  }

  if (maxLength !== undefined && str.length > maxLength) {
    errors.push(`String must be no more than ${maxLength} characters long.`);
  }

  return {
    isValid: errors.length === 0,
    errors,
  };
};

export const getChainDetails = chainId => {
  const allChains = { ...chains, ...cosmosChains };
  // not doing values cuz it might be a large object, keys list is smaller
  return allChains[Object.keys(allChains).find(chainName => allChains[chainName].id == chainId)];
};

export const hashObject = obj => {
  return objectHash(obj || {});
};
export function getRandomColor(text) {
  // A simple hash function
  let hash = 0;
  for (let i = 0; i < text.length; i++) {
    hash = text.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += ('00' + value.toString(16)).slice(-2);
  }

  // Ensure the color is a valid 6-character hex color
  if (color.length !== 7) {
    color = '#000000'; // default to black if something goes wrong
  }

  return color;
}

export function formatDate(timestamp) {
  const date = new Date(timestamp);
  const day = date.getDate(); // Day of the month
  const month = date.getMonth() + 1; // Months are zero-based
  const year = date.getFullYear().toString().slice(-2); // Last two digits of the year
  let hours = date.getHours(); // Hours (0-23)
  let minutes = date.getMinutes(); // Minutes (0-59)

  // Pad minutes with leading zero if needed
  minutes = minutes < 10 ? '0' + minutes : minutes;

  // Format the date string
  return `${day}-${month}-${year} ${hours}:${minutes}`;
}

export function isPastTimestamp(timestamp) {
  let currentTime = Date.now();
  return currentTime > timestamp;
}

export function formatTimestamp(timestamp) {
  let date = new Date(timestamp);
  let formattedDate = date.toLocaleString(); // Converts to a human-readable string
  return formattedDate;
}

export default {
  limitedApi,
  largeText,
  numberShort,
  getFileUrl,
  bsonIdToBytes12,
  tweetTemplateParser,
  decodeBase64ToObj,

  countdown,
  countdownString,
  isValidEmail,
  isValidUrl,
  getElementValue,

  isEmpty,
  isLightTheme,
  getRandomId,
  isPastTimestamp,
  getChainDetails,
  stringValidator,
  hashObject,
  getRandomColor,
  formatDate,
  formatTimestamp,
};
