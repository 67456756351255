import React, { useEffect, useState } from 'react';
import { MBADGE_LEAGUE, resources } from '../../../constants';
import { useBackendApi } from '../../../helpers/backendHelper';
import { useEthersHelper } from '../../../helpers/ethersHelper';
import { useGlobalState } from '../../../state/GlobalStateProvider';
import utils, { limitedApi } from '../../../utils';
import Icons from '../../icons/Icons';
import { Spinner } from '../../loaders/Spinner';
import LoginButton from '../../login/LoginButton';
import NftPreview from '../../nft/NftPreview';
import SimpleSlider from '../../slider/SimpleSlider';
import { MbadgeBanner } from './Banners';
import './MbadgeClaim.scss';

const MbadgeClaim = () => {
  const [data, setData] = useState();
  const [state, setState] = useState({});
  const [tokenID, setTokenId] = useState();
  const [total, setTotal] = useState();
  const backendApi = useBackendApi();
  const ethersHelper = useEthersHelper();
  const [{ user }] = useGlobalState();
  const [isClaimEligible, setIsClaimEligible] = useState(false);

  const openseaLink = resources.links.opensea(state?.token?.chainId, state?.token?.address, tokenID);

  const tweetText = utils.tweetTemplateParser('' || `Finally, I claimed my Hands of Support NFT from @0xmercle 😭 <<opensea_link>>`, {
    openseaLink,
  });

  function calculateShine(userMbadges, userDefiXP) {
    let currentLevel = null;
    let nextLevel = null;

    for (let i = 0; i < MBADGE_LEAGUE.length; i++) {
      if (userMbadges >= MBADGE_LEAGUE[i]?.mbadges && userDefiXP >= MBADGE_LEAGUE[i]?.defiXP) {
        currentLevel = MBADGE_LEAGUE[i];
        nextLevel = MBADGE_LEAGUE[i + 1] || null;
      } else {
        break;
      }
    }

    let shinePoints;
    let level;
    if (nextLevel) {
      shinePoints = Math.min(userMbadges, nextLevel?.mbadges) * 100 + Math.min(userDefiXP, nextLevel?.defiXP);
      level = { mbadges: Math.min(userMbadges, nextLevel?.mbadges), defiXP: Math.min(userDefiXP, nextLevel?.defiXP) };
    } else {
      shinePoints = userMbadges * 100 + userDefiXP;
      level = { mbadges: userMbadges, defiXP: userDefiXP };
    }
    setTotal({
      level: currentLevel ? currentLevel.name : 'NONE',
      points: shinePoints,
      currentLevel: level,
    });
    return currentLevel ? currentLevel.name : 'NONE';
  }

  const getData = async () => {
    try {
      const response = await backendApi.user.getAllHOSStats();
      if (response) {
        setData(response?.stats || {});
        setIsClaimEligible(response?.isClaimEnabled);
        const level = calculateShine(response?.stats?.totalMbadgeCount, response?.stats?.totalDefiXp);
        const commData = await backendApi.user.getHOSCommunity(level);
        if (commData) {
          let token = {
            address: commData?.campaign?.tokenAddr,
            chainId: commData?.campaign?.chainId,
          };
          setState(s => ({ ...s, community: commData?.community, campaign: commData?.campaign, token: token }));
        }
      }
    } catch (error) {
      console.log({ error });
      setState(s => ({ ...s, community: {}, campaign: {} }));
    }
  };

  const getNftProfile = () => {
    if (!user) return;
    if (!state.token?.address) return;
    if (state.nftProfile) return;

    limitedApi('nftProfile', () =>
      ethersHelper
        .getNftProfileTokenId(state?.community?.name, user?.address, state?.token?.address, state?.token?.chainId)
        .then(({ tokenId }) => {
          setTokenId(tokenId);
          setState(s => ({ ...s, token: { ...s?.token, tokenId } }));
          backendApi.communities
            .getNftProfile(state?.community?.name, state.token.address, tokenId)
            .then(nftProfile => {
              setState(s => ({ ...s, nftProfile: nftProfile || null }));
              return;
            })
            .catch(e => {
              setState(s => ({ ...s, nftProfile: null }));
              throw e;
            });
        })
        .catch(e => {
          setState(s => ({ ...s, nftProfile: null }));
        }),
    );
  };

  useEffect(() => {
    getNftProfile();
  }, [user, state.token?.address]);

  useEffect(() => {
    getData();
  }, [user]);

  if (!total?.level || !data || !user)
    return (
      <div className='mbadge_slider'>
        <div className='banner_layout'>
          <div className='layout_1'>{!user ? <LoginButton /> : <Spinner />}</div>
        </div>
      </div>
    );
  if (!isClaimEligible)
    return (
      <div className='mbadge_slider'>
        <div className='banner_layout'>
          <div className='layout_1'>
            <h1>Due to an server outage we have paused the claiming of Hands of Support. Please follow our discord for getting the latest updates on the issue.</h1>
          </div>
        </div>
      </div>
    );

  return (
    <>
      {state?.nftProfile ? (
        <div className='mbadge_slider'>
          <div className='banner_layout'>
            <div className='layout_1'>
              <div className='preview_container'>
                <NftPreview className='nft' src={state?.nftProfile?.animation_url} />
                <div className='nft_action'>
                  <div className='button_container'>
                    <h2>
                      {' '}
                      {state?.nftProfile?.name} #{tokenID}
                    </h2>
                    <p>{state?.nftProfile?.description}</p>
                  </div>
                  <div className='action_button'>
                    <button className='primary'>
                      <a className='primary' href={openseaLink} target='_blank'>
                        {' '}
                        <Icons size={'1.5rem'} icon={'store'} /> Marketplace
                      </a>
                    </button>
                    <button className='primary'>
                      <a className='primary' href={resources.links.twitter.tweet(tweetText)} target='_blank'>
                        {' '}
                        <Icons size={'1.5rem'} icon={'share'} /> Share on X
                      </a>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <SimpleSlider
            className='mbadge_slider'
            showArrows={false}
            children={MbadgeBanner}
            autoplayDuration={100}
            hasScroll={false}
            autoPlay={false}
            canScroll={false}
            showDots={false}
          >
            {MbadgeBanner.map((Banner, i) => (
              <Banner key={i} data={data} COMMUNITY_NAME={state?.community?.name} state={state} getNftProfile={getNftProfile} total={total} />
            ))}
          </SimpleSlider>
        </>
      )}
    </>
  );
};

export default MbadgeClaim;
