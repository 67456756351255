import React, { useEffect, useRef, useState } from 'react';
import './SimpleSlider.scss';

const SimpleSlider = ({ className = '', children, slideGap = 10, autoplayDuration = 5000, showArrows = true, canScroll = true, autoPlay = true, showDots = true }) => {
  const containerRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const currentIndexRef = useRef(0);
  const hoverRef = useRef(false);

  const scrollToIndex = i => {
    setCurrentIndex(i);
    containerRef.current.scrollTo({
      left: i * containerRef.current.clientWidth + slideGap,
      top: 0,
      behavior: 'smooth',
    });
  };

  const onPrev = () => {
    currentIndexRef.current = Math.max(0, currentIndexRef.current - 1);
    scrollToIndex(currentIndexRef.current);
  };

  const onNext = () => {
    if (currentIndexRef.current >= (children?.length || 0) - 1) return scrollToIndex(0);
    currentIndexRef.current = Math.min(currentIndexRef.current + 1, (children?.length || 0) - 1);
    scrollToIndex(currentIndexRef.current);
  };

  const setHover = isHover => {
    hoverRef.current = isHover;
  };

  useEffect(() => {
    const handleScroll = () => {
      if (!containerRef.current) return;

      const scrollLeft = containerRef.current.scrollLeft;
      const containerWidth = containerRef.current.clientWidth + slideGap;
      const indexValue = Math.round(scrollLeft / containerWidth);

      if (indexValue == currentIndexRef.current) return;

      currentIndexRef.current = indexValue;
      setCurrentIndex(indexValue);
    };

    const container = containerRef.current;
    container.addEventListener('scroll', handleScroll);

    return () => container.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (!autoPlay) return;
    if (children.length == 1) return;
    const i = setInterval(() => {
      if (hoverRef.current) return;
      onNext();
    }, autoplayDuration);
    return () => clearInterval(i);
  }, []);

  return (
    <section className={`SimpleSlider-container ${className}`} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      {currentIndex > 0 && showArrows && children.length > 1 ? (
        <button className='sliderNav left' onClick={onPrev}>
          ‹
        </button>
      ) : (
        <></>
      )}
      {currentIndex < (children?.length || 0) - 1 && showArrows && children.length > 1 ? (
        <button className='sliderNav right' onClick={onNext}>
          ›
        </button>
      ) : (
        <></>
      )}
      <div ref={containerRef} className={`slider-content no-scrollbar`} style={{ gap: `${slideGap}px`, overflowX: canScroll ? 'auto' : 'hidden' }}>
        {children.map((child, i) => (
          <div key={i} className='slide'>
            {/* {child} */}
            {React.cloneElement(child, { ...child.props, onNext, onPrev })}
          </div>
        ))}
      </div>
      {children.length > 1 && showDots ? (
        <div className='slider-dots'>
          {children.map((x, i) => (
            <button key={i} onClick={() => scrollToIndex(i)} className={`slider-dot ${i == currentIndex ? 'primary' : ''}`}>
              {' '}
            </button>
          ))}
        </div>
      ) : (
        <></>
      )}
    </section>
  );
};

export default SimpleSlider;
