

import { Outlet } from "react-router";
import { Link } from "react-router-dom";


import "./adminContainer.scss";
import { useContext, useEffect } from "react";
// import { dashboardContext } from "../events/providers/DashboardProvider";
import { useLoginHelper } from "../../helpers/login/loginHelper";
import { useGlobalState } from "../../state/GlobalStateProvider";
import EventProvider, { useEvent } from "../events/event/EventProvider";
import NotFound404 from "../NotFound404";

const AdminContainer = () => {
  const [{user}]=useGlobalState()
  const [{ community }] = useEvent();
//   const { showLeftRightPanel } = useContext(dashboardContext);
  const { userLogin } = useLoginHelper();

//   useEffect(() => {
//     showLeftRightPanel(false);
//   }, []);

  const login = async () => {
    try {
      await userLogin({ connectorId: "injected", isInvasive: true });
    } catch (e) {
      console.error(e);
      alert.error("could not login");
    }
  };

  if (!user) {
    return <button onClick={login}>Login</button>;
  }

  if (!community?.admins?.[user._id]) return <NotFound404 />;

  return (
    <div className="adminContainer-container">
    
      <div className="links">
        <Link to={`/${community?.name}/admin/settings`}>Settings</Link>
        <Link to={`/${community?.name}/admin/createCollection`}>Collections</Link>
        <Link to={`/${community?.name}/admin/rewardItemHelper`}>Reward Item Helper</Link>
        <Link to={`/${community?.name}/admin/verifyTaskSubmissions`}>Task Submissions Verification</Link>
        <Link to={`/${community?.name}/admin/eventAnalytics`}>Event Analytics</Link>
        <Link to={`/${community?.name}/admin/addEvent`}>Add Event</Link>
      </div>
      <Outlet />
    
    </div>
  );
};
const AdminPanel=()=>{
  return (
    <EventProvider>
      <AdminContainer />
    </EventProvider>
  )
}

export default AdminPanel;
