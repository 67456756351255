import React from 'react';
import Icons from '../icons/Icons';
import './ToggleTheme.scss';

const ToggleTheme = ({ onChange, checked, key }) => {
  return (
    <div className='toggle-theme-container' key={key}>
      <input type='checkbox' class='theme-checkbox' id='theme-checkbox' onChange={onChange} checked={checked} />
      <label for='theme-checkbox' class='checkbox-label-theme'>
        {<Icons icon={checked ? 'light_mode' : 'dark_mode'} />}
      </label>
    </div>
  );
};

export default ToggleTheme;
