import { useNavigate } from 'react-router-dom';
import ButtonApi from '../../../components/ButtonApi';
import { CardsLoader } from '../../../components/Skeleton/CardsLoader';
import SimpleSlider from '../../../components/slider/SimpleSlider';
import { useEvent } from './EventProvider';
import './RaffleBanners.scss';

const RaffleBanners = () => {
  const [{ communityRaffles }] = useEvent();
  const navigate = useNavigate();

  if (communityRaffles === undefined) return <CardsLoader columns={1} rows={1} columnHeight={200} />;
  if (!communityRaffles || !communityRaffles.length) return <></>;

  return (
    <SimpleSlider slideGap={20} showArrows={false}>
      {communityRaffles.map((banner, index) => (
        <div className='raffle-banner' key={`${banner.displayName}-${index}`}>
          <div className='raffle-banner_content'>
            <h1>{banner.displayName}</h1>
            <p>{banner.description}</p>
          </div>
          <ButtonApi
            className='primary raffle-banner-button'
            onClick={() => {
              navigate(`/raffles/${banner.name}`);
            }}
          >
            View Details
          </ButtonApi>
        </div>
      ))}
    </SimpleSlider>
  );
};

export { RaffleBanners };
