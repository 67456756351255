import { useParams } from 'react-router-dom';
import { types } from '../../constants';
import utils from '../../utils';
import { useBackendApi } from '../backendHelper';
import { useLoginHelper } from './loginHelper';

export const useOauthHelper = () => {
  const backendApi = useBackendApi();
  const { communityName } = useParams();
  const loginHelper = useLoginHelper();

  const getLoginState = async (loginVia, postLoginAction = types.postLoginActions.none, postLoginRedirect) => {
    const redirectUrl = `${window.location.href}${postLoginAction ? `?action=${postLoginAction}` : ''}`;
    const loginState = await backendApi.getLoginState({
      redirectUrl,
      commName: communityName || 'mercle',
      loginVia,
      postLoginRedirect,
      onError: window.location.href,
    });
    return loginState.url;
  };

  // after successful login with oauth api, call this to get access token set to cookies from backend
  const getLoginCookies = async loginData => {
    const params = utils.decodeBase64ToObj(loginData);

    // cookie set using universal-cookie is not being sent in fetch request, so requesting backend to set it instead
    try {
      const user = await backendApi.getCookies({
        auth: params.cookies.auth.token,
        ref: params.cookies.ref.token,
      });
      if (user) {
        return await loginHelper.postProcessUserLogin(user, params.loginVia);
      }
    } catch (e) {
      console.error(e);
    }
    return false;
  };

  return {
    getLoginState,
    getLoginCookies,
  };
};
