import { ethers } from 'ethers';
import { useModal } from '../../../components/modal/ModalContext';
import { typesComplex } from '../../../constants';
import utils from '../../../utils';
import './TaskVerifyForm.scss';

const inputId = index => `tvf-input-${index}`;

const Inputs = ({ task }) => {
  switch (task.type) {
    case typesComplex.EventTypes.DaoWallet.name:
    case typesComplex.EventTypes.HasTransferedAsset.name:
      return <input id={inputId(0)} placeholder='Enter wallet address' />;
    case typesComplex.EventTypes.TwitterTweet.name:
      return <input id={inputId(0)} placeholder='Enter tweet url' />;
  }

  return <input id={inputId(0)} placeholder='Enter wallet address' />;
};

const Form = ({ task, extra = {}, onSuccess, verifyTask }) => {
  const [{ closeModal }] = useModal();

  const onClick = () => {
    const _extra = { ...extra };
    switch (task.type) {
      case typesComplex.EventTypes.DaoWallet.name:
      case typesComplex.EventTypes.HasTransferedAsset.name:
        const walletAddress = utils.getElementValue(inputId(0))?.trim();
        if (!walletAddress || !ethers.isAddress(walletAddress)) return alert.error('Invalid Wallet Address');

        _extra.walletAddress = ethers.getAddress(walletAddress);
        break;
      case typesComplex.EventTypes.TwitterTweet.name:
        const tweet = /\/status\/(\d+)/g.exec(utils.getElementValue(inputId(0))?.trim());
        if (!tweet || !(Array.isArray(tweet) && tweet.length > 1)) return alert.error('Invalid tweetId');
        _extra.tweetId = tweet[1];
    }

    verifyTask(task, _extra, () => {
      if (onSuccess) onSuccess();
      closeModal();
    });
  };
  return (
    <>
      <Inputs task={task} />
      <footer>
        <button className='primary' onClick={onClick}>
          Submit
        </button>
      </footer>
    </>
  );
};

/**
 * this will not have access to event context as it is will mostly be opened in a modal
 */
const TaskVerifyForm = ({ task, extra, onSuccess, verifyTask }) => {
  return (
    <>
      <h2>Task Verification</h2>
      <h1>{task.name}</h1>
      <Form task={task} extra={extra} onSuccess={onSuccess} verifyTask={verifyTask} />
    </>
  );
};

export default TaskVerifyForm;
