import { useRef, useState } from 'react';
import './ButtonApi.scss';

const ButtonApi = props => {
  const loading = useRef(false); // extra guard to prevent duplicate clicks
  const [isLoading, setIsLoading] = useState(Boolean(props?.isLoading));

  const onClick = async (event) => {
    if (loading.current) return alert.error('Please wait for request to complete');
    loading.current = true;
    setIsLoading(loading.current);
    if(props.stopPropagation){
      event.stopPropagation()
    }

    try {
      await props.onClick();
    } catch (e) {}

    loading.current = false;
    setIsLoading(loading.current);
  };

  return (
    <button {...props} className={`ButtonApi-container ${props.className}`} onClick={onClick} disabled={props.disabled || isLoading}>
      {props.children}
      {isLoading ? <div class='spinner'></div> : <></>}
    </button>
  );
};

export default ButtonApi;
