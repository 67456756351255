import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useEthersHelper } from '../../helpers/ethersHelper';
import { useGlobalState } from '../../state/GlobalStateProvider';
import UserProgress from '../user/UserProgress';
import './NftCard.scss';
import NftPreview from './NftPreview';

const NftCard = ({ community, campaign, event }) => {
  const [{ user }] = useGlobalState();
  const [isCollected, setIsCollected] = useState(false);
  const ethersHelper = useEthersHelper();

  useEffect(() => {
    if (!user) return;

    const tokenAddress = campaign?.tokenAddr;
    const chainId = campaign?.chainId;

    ethersHelper
      .getNftProfileTokenId(community?.name, user?.address, tokenAddress, chainId)
      .then(() => {
        setIsCollected(true);
      })
      .catch(e => {});
  }, [user]);

  return (
    <Link className='nft-card-container card' to={`/${community?.name}/event`}>
      <NftPreview thumbnail={true} template={campaign?.template} collected={isCollected} />
      <div className='content'>
        <div className='info ellipse'>
          <h1 className='ellipse'>{event?.name || community?.displayName || community?.name}</h1>
          <footer>
            <p>
              {event?.events?.length} Tasks • {event?.overview?.tags?.length} Reward
            </p>
          </footer>
        </div>
        <UserProgress taskIds={event?.events.map(x => x?.id || x)} />
      </div>
    </Link>
  );
};

export { NftCard };
