import ButtonApi from '../../../../components/ButtonApi';
import Icons from '../../../../components/icons/Icons';
import ToolTip from '../../../../components/toolTip/Tooltip';
import { useEvent } from '../EventProvider';
import { useMint } from './MintNftProvider';

const SetUsername = () => {
  const [{ isDisabled, suffix, username, setUsername, saveUsername, mintFee, feeToken }] = useMint();
  const [{ campaign }] = useEvent();

  const hasDynamicFee = campaign?.mintFeeConfig?.dynamicPriceOnUsername;
  const hasFee = hasDynamicFee || campaign?.mintFeeConfig?.amount;

  return (
    <>
      <div className='set-name-caption-container'>
        <p className='mintcaption'>
          <ToolTip
            className='dynamic-fee-tooltip'
            tipContent={
              hasDynamicFee ? (
                <p>
                  The community treasury and the project are both supported by this dynamicClaim toll, which allows users to avoid paying a fixed fee. You have the ability to
                  change it by adjusting the length of your name.
                </p>
              ) : (
                <></>
              )
            }
            showOnClick={true}
          >
            <Icons icon={'info'} />
          </ToolTip>
          <i>Enter your NFT's name</i>
        </p>
        {hasFee ? (
          <p className='mintcaption'>
            Claim Toll: {username?.length ? mintFee : '—'} {feeToken}
          </p>
        ) : (
          <></>
        )}
      </div>
      <div className='username-container'>
        <input placeholder='Enter your username' onChange={setUsername} value={username} />
        <div className='suffix'>{suffix}</div>
      </div>
      <ButtonApi className='primary' onClick={saveUsername} disabled={isDisabled}>
        Set Name
      </ButtonApi>
    </>
  );
};

export default SetUsername;
