import React, { useEffect, useRef, useState } from 'react';

const AnimateComponent = ({ className = '', children, show, enterAnimation, exitAnimation, animationDurationMillis = 250, onExitComplete, onClick, style }) => {
  const [mountState, setMountState] = useState(false);
  const [exitAnimationTimeout, setExitAnimationTimeout] = useState();

  useEffect(() => {
    if (show) {
      setMountState(true);
      if (exitAnimationTimeout) {
        clearTimeout(exitAnimationTimeout);
        setExitAnimationTimeout(undefined);
      }
    } else {
      // unmount component after animation exit ends
      setExitAnimationTimeout(
        setTimeout(() => {
          setMountState(false);
          if (onExitComplete) onExitComplete();
        }, animationDurationMillis),
      );
    }
  }, [show]);

  const handleClick = e => {
    e.stopPropagation();
    if (onClick) onClick();
  };

  // unmount component
  if (!mountState) return <></>;

  return (
    <div className={`${className} ${show ? enterAnimation : exitAnimation}`} style={{ ...style, animationDuration: `${animationDurationMillis}ms` }} onClick={handleClick}>
      {children}
    </div>
  );
};

/**
 * todo: disallow state toggle midway of animation
 */
const AnimateComponentTriggered = ({
  className,
  children,
  defaultState = true,
  enterAnimation = 'slideUpOverlay',
  exitAnimation = 'slideDownOverlay',
  animationDurationMillis = 250,
  onExitComplete,
  onClick,
  style = {},
}) => {
  // true to enter, false to exit
  const [_animationState, _setAnimationShow] = useState(defaultState);
  const animationState = useRef(_animationState);
  const setAnimationShow = v => {
    animationState.current = v;
    _setAnimationShow(v);
  };

  useEffect(() => {
    setAnimationShow(defaultState);
  }, [defaultState]);

  return (
    <AnimateComponent
      className={className}
      show={animationState.current}
      enterAnimation={enterAnimation}
      exitAnimation={exitAnimation}
      animationDurationMillis={animationDurationMillis}
      onExitComplete={onExitComplete}
      onClick={onClick}
      style={style}
    >
      {children}
    </AnimateComponent>
  );
};

export default AnimateComponentTriggered;
