import { useGlobalState } from '../../state/GlobalStateProvider';
import { useBackendApi } from '../backendHelper';

export const useEmailLoginHelper = community => {
  const backendApi = useBackendApi();
  const [{ mercleCommunity }] = useGlobalState();

  const loginInvasive = async ({ email, postLoginAction }) => {
    const promise = backendApi.getAccessTokenForWallet({
      email,
      communityId: community?._id || mercleCommunity?._id,
      redirectUrl: `${window.location.href}${postLoginAction ? `?action=${postLoginAction}` : ''}`,
      onError: window.location.href,
    });
    return await alert.promise(promise, {
      pending: 'Sending link',
      success: 'Link sent to your email',
      error: 'Could not send email',
    });
  };

  return {
    loginInvasive,
  };
};
