import './Spinner.scss';

const Spinner = ({ size = 10, text, margin, className }) => {
  return (
    <div className='spinner-container' style={{ margin }}>
      <div class='spinner' style={{ height: size, width: size }}></div>
      {text ? <p>{text}</p> : <></>}
    </div>
  );
};

export { Spinner };
