import { Link } from 'react-router-dom';
import { isLightTheme } from '../utils';
import './NotFound404.scss';

const NotFound404 = () => {
  const img = isLightTheme() ? 'https://dskhnex0aho1n.cloudfront.net/public/404light.png' : 'https://dskhnex0aho1n.cloudfront.net/public/404dark.png';

  return (
    <div className='NotFound404-container'>
      <img src={img} />
      <h1>Huh?</h1>
      <p>The page you're looking for doesn't exist. Explore other projects that exist!</p>
      <Link className='button primary' to='/'>
        Explore
      </Link>
    </div>
  );
};

export default NotFound404;
