import Chart from 'chart.js/auto';
import React, { useEffect, useRef, useState } from 'react';
import { Toggle } from '../../components/toggle/Toggle';
import { formatDate, getRandomColor } from '../../utils';
import useAnalyticsHelper from './analyticsHelper';
import TimeFilter from './filterComponent';
import './graph.scss';

const TotalMintFeeChart = ({ labels, mintCounts, showMintFee, totalMintFeeNative, distribution, customTooltip }) => {
  const chartRef = useRef(null);
  const [chartInstance, setChartInstance] = useState(null);

  useEffect(() => {
    if (!labels || !mintCounts || !totalMintFeeNative) return;
    let datasets;
    if (distribution) {
      datasets = Object.keys(mintCounts).map(community => {
        const data = labels.map(label => mintCounts[community][label] || 0);
        return {
          label: community,
          data: data,
          fill: true,
          borderColor: getRandomColor(community),
          backgroundColor: getRandomColor(community),
        };
      });
    } else {
      datasets = Object.keys(distribution ? mintCounts : totalMintFeeNative).map((currency, index) => {
        const data = labels.map(date => totalMintFeeNative[currency][date] || 0);
        return {
          label: currency,
          data: data,
          backgroundColor: getRandomColor(currency),
          borderColor: getRandomColor(currency),
          borderWidth: 1,
        };
      });
    }

    const data = {
      labels: labels.map(item => formatDate(item)),
      datasets: datasets,
    };

    const options = {
      responsive: true,
      plugins: {
        title: {
          display: true,
          text: 'Total Mint fess Currency wise',
          font: {
            weight: 'bold',
            size: 24,
          },
        },
        legend: {
          display: true,
          position: 'top',
        },
        tooltip: {
          position: 'nearest',
          enabled: false,
          external: context => customTooltip(context, data),
        },
        legend: {
          position: 'top',
        },
      },
      scales: {
        x: {
          title: {
            display: true,
            text: 'Date',
          },
          stacked: true, // Stack the bars for better visibility
        },
        y: {
          title: {
            display: true,
            text: showMintFee ? 'Mint Fee' : 'Number of Mints',
          },
          stacked: true, // Stack the bars for better visibility
        },
      },
    };

    if (chartInstance) {
      chartInstance.destroy();
    }

    const ctx = chartRef.current.getContext('2d');
    const newChartInstance = new Chart(ctx, {
      type: 'bar',
      data: data,
      options: options,
    });

    setChartInstance(newChartInstance);
  }, [labels, mintCounts, showMintFee]);

  return <canvas ref={chartRef} />;
};

function MintFeeGraphs() {
  const { getAnalyticsData, onFilterChange, count, setCount, tokenAddress, handleChange, userAddress, time, customTooltip } = useAnalyticsHelper();
  const [labels, setLabels] = useState();
  const [mintCounts, setMintCounts] = useState();
  const [showDistribution, setShowDistribution] = useState(false);
  const [totalMintFeeNative, setTotalMintFeeNative] = useState();

  async function getData() {
    try {
      const response = await getAnalyticsData(true);
      setLabels(response?.labels);
      setMintCounts(response?.mintCounts);
      setTotalMintFeeNative(response?.totalMintFeeNative);
    } catch (error) {}
  }

  useEffect(() => {
    getData();
  }, [count, time, showDistribution]);

  return (
    <div className='charts_graph'>
      <div className='filters'>
        <TimeFilter onFilterChange={onFilterChange} />
        <div className='toggle'>
          Show Distribution
          <Toggle onChange={() => setShowDistribution(!showDistribution)} checked={showDistribution} id={'analytics_fee_distribution'} />
        </div>
      </div>
      <TotalMintFeeChart
        labels={labels}
        mintCounts={mintCounts}
        showMintFee={true}
        totalMintFeeNative={totalMintFeeNative}
        distribution={showDistribution}
        customTooltip={customTooltip}
      />
    </div>
  );
}

export default MintFeeGraphs;
