import { useState } from 'react';
import { useBackendApi } from '../../helpers/backendHelper';

const useAnalyticsHelper = () => {
  const backendHelper = useBackendApi();
  const [time, setTime] = useState({ time: '1 hour', period: '1 day' });
  const [count, setCount] = useState(false);
  const [tokenAddress, setTokenAddress] = useState('');
  const [userAddress, setUserAddress] = useState('');

  const onFilterChange = async filter => {
    switch (filter.type) {
      case '1H':
        setTime({ time: '1 hour', period: '1 week' });
        break;
      case '1D':
        setTime({ time: '1 day', period: '1 week' });
        break;
      case '1W':
        setTime({ time: '1 week', period: '1 month' });
        break;
      default:
        break;
    }
  };
  async function getAnalyticsData(showMintFee) {
    try {
      const query = {
        time: time?.time,
        period: time?.period,
        count,
        tokenAddress,
        showMintFee,
        userAddress,
      };

      const response = await backendHelper.getAnalyticsData(query);
      return response;
    } catch (error) {
      console.log(error);
    }
  }

  const customTooltip = (context, data) => {
    const tooltipModel = context.tooltip;

    // Tooltip Element
    let tooltipEl = document.getElementById('chartjs-tooltip');

    // Create element on first render
    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.id = 'chartjs-tooltip';
      tooltipEl.innerHTML = '<table></table>';
      document.body.appendChild(tooltipEl);
    }

    // Hide if no tooltip
    if (tooltipModel.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }

    // Set caret position
    tooltipEl.classList.remove('above', 'below', 'no-transform');
    if (tooltipModel.yAlign) {
      tooltipEl.classList.add(tooltipModel.yAlign);
    } else {
      tooltipEl.classList.add('no-transform');
    }

    // Set Text
    if (tooltipModel.body) {
      const titleLines = tooltipModel.title || [];
      const bodyLines = tooltipModel.body.map(b => b.lines);

      let innerHtml = '<thead>';

      titleLines.forEach(function (title) {
        innerHtml += '<tr><th>' + title + '</th></tr>';
      });
      innerHtml += '</thead><tbody>';

      // Get index of hovered bar
      const dataIndex = tooltipModel.dataPoints[0].dataIndex;

      // Calculate total count for the hovered bar
      let totalCount = 0;

      // Divide data into columns
      const columns = 3;
      let rows = '';
      for (let i = 0; i < data.datasets.length; i += columns) {
        rows += '<tr>';
        for (let j = 0; j < columns; j++) {
          const datasetIndex = i + j;
          if (datasetIndex < data.datasets.length) {
            const dataset = data.datasets[datasetIndex];
            const value = dataset.data[dataIndex];
            if (value > 0) {
              rows += `<td style="padding-right: 16px;">${dataset.label}: ${value}</td>`;
              totalCount += value;
            } else {
              rows += '<td style="padding-right: 16px;"></td>';
            }
          }
        }
        rows += '</tr>';
      }

      innerHtml += rows;
      innerHtml += `<tr><td colspan="${columns}" style="padding-top: 10px; font-weight: bold;">Total Count: ${totalCount}</td></tr>`;
      innerHtml += '</tbody>';

      const tableRoot = tooltipEl.querySelector('table');
      tableRoot.innerHTML = innerHtml;
    }

    const position = context.chart.canvas.getBoundingClientRect();

    // Display, position, and set styles for tooltip
    tooltipEl.style.opacity = 1;
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
    tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
    tooltipEl.style.padding = '12px';
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.background='black'
  };

  return { getAnalyticsData, onFilterChange, count, setCount, tokenAddress, userAddress, time, customTooltip };
};

export default useAnalyticsHelper;
