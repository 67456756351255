import { useEffect, useState } from 'react';
import utils from '../utils';

const useCountdown = (targetDate = Date.now()) => {
  const [countDown, setCountDown] = useState(utils.countdownString(targetDate));

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(utils.countdownString(targetDate));
    }, 1000);

    return () => clearInterval(interval);
  }, [targetDate]);

  return { time: `${countDown.str?.join(' ')}${countDown.ago ? ' ago' : ''}`, ago: countDown.ago };
};

export { useCountdown };
