import { useCallback } from 'react';
import ButtonApi from '../ButtonApi';
import { useModal } from '../modal/ModalContext';

const QuizResult = ({ quiz, quizResult, onRetry }) => {
  // const { closeModal } = useContext(modalContex);
  const [{ closeModal }] = useModal();
  const requiredCorrectQuestion = useCallback(() => {
    if (!quiz || !quizResult) {
      return 0;
    }
    let percent = Number(quiz?.passmark);
    let requiredCorrect = (Number(quiz?.qna?.length) * percent) / 100;
    return Math.ceil(requiredCorrect);
  }, [quiz, quizResult]);
  const pass = (
    <div className='pass_container'>
      <h2>Your Score</h2>
      <h1 className='score'>
        {quizResult.score}/{quizResult.total}
      </h1>
      <p>Congratulations you passed the quiz 🎉🎉.</p>
      <button
        className='completeButton mintButton'
        onClick={() => {
          closeModal();
          // onPass();
        }}
      >
        Claim Your Reward Now
      </button>
    </div>
  );
  const fail = (
    <>
      <img className='quiz_retry_img' src={'https://dskhnex0aho1n.cloudfront.net/public/retryhug.png'} />
      <div className='quiz_retry_child'>
        <h2>{(quizResult.retryCount || 0) < quiz.retry ? 'Try again!' : ''}</h2>
        <h2>You did not get a score to pass the quiz.</h2>
      </div>
      <div className='quiz_retry_child'>
        <h2 className='score'>
          Your score : {quizResult.score}/{quizResult.total}
        </h2>
        <h2>
          Required passing score : {requiredCorrectQuestion()}/{quizResult.total}
        </h2>
      </div>

      {quiz.retry && (quizResult.retryCount || 0) < quiz.retry ? (
        <ButtonApi className='primary' onClick={onRetry}>
          Retry
        </ButtonApi>
      ) : (
        <div className='pass_container'>
          <p>You tried your best. Good Luck with your next task.</p>
          <button className='completeButton mintButton' onClick={closeModal}>
            Return to Tasks
          </button>
        </div>
      )}
    </>
  );
  return <div className='quizresult_container'>{quizResult.isPassed ? pass : fail}</div>;
};
export default QuizResult;
