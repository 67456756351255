import Chart from 'chart.js/auto';
import 'chartjs-adapter-moment';
import React, { useEffect, useRef, useState } from 'react';
import { Toggle } from '../../components/toggle/Toggle';
import { formatDate, getRandomColor } from '../../utils';
import useAnalyticsHelper from './analyticsHelper';
import TimeFilter from './filterComponent';
import './graph.scss';
import MintFeeGraphs from './MintFeeGraph';

const MintChart = ({ labels, mintCounts, customTooltip }) => {
  const chartRef = useRef(null);
  const [chartInstance, setChartInstance] = useState(null);

  useEffect(() => {
    if (!labels || !mintCounts) return;

    const datasets = Object.keys(mintCounts).map(community => {
      const data = labels.map(label => mintCounts[community][label] || 0);
      return {
        label: community,
        data: data,
        fill: true,
        borderColor: getRandomColor(community),
        backgroundColor: getRandomColor(community),
      };
    });

    const data = {
      labels: labels.map(item => formatDate(item)),
      datasets: datasets,
    };

    const options = {
      responsive: true,
      plugins: {
        title: {
          display: true,
          text: 'Number of Mints per Community',
        },
        legend: {
          display: true,
          position: 'top',
        },
        tooltip: {
          position: 'nearest',
          enabled: false,
          external: context => customTooltip(context, data),
        },

        legend: {
          position: 'bottom',
        },
      },
      scales: {
        x: {
          title: {
            display: true,
            text: 'Date',
          },
          stacked: true, // Stack the bars for better visibility
        },
        y: {
          title: {
            display: true,
            text: 'Number of Mints',
          },
          stacked: true, // Stack the bars for better visibility
        },
      },
    };

    if (chartInstance) {
      chartInstance.destroy();
    }

    const ctx = chartRef.current.getContext('2d');
    const newChartInstance = new Chart(ctx, {
      type: 'bar',
      data: data,
      options: options,
    });

    setChartInstance(newChartInstance);
  }, [labels, mintCounts]);

  return <canvas className='graph_canvas' ref={chartRef} />;
};

function MintTotalChart({ labels }) {
  const chartRef = useRef(null);
  const [chartInstance, setChartInstance] = useState(null);

  function prepareChartData(data) {
    // Extract labels and count data from the input
    const labels = data.map(item => item?.timebucket?.split('T')[0]);
    const counts = data.map(item => item?.count);

    // Prepare the dataset
    const chartData = {
      labels: labels,
      datasets: [
        {
          label: 'Counts Over Time',
          data: counts,
          backgroundColor: getRandomColor('Counts Over Time'),
          borderColor: getRandomColor('Counts Over Time'),
          fill: true,
        },
      ],
    };

    return chartData;
  }

  useEffect(() => {
    if (!labels) return;
    const chartData = prepareChartData(labels);
    const options = {
      responsive: true,
      plugins: {
        title: {
          display: true,
          text: 'Totol Number of Mints',
        },
        legend: {
          display: true,
          position: 'top',
        },
      },
      scales: {
        x: {
          title: {
            display: true,
            text: 'Date',
          },
          stacked: true, // Stack the bars for better visibility
        },
        y: {
          title: {
            display: true,
            text: 'Total Number of Mints',
          },
          stacked: true, // Stack the bars for better visibility
        },
      },
    };

    if (chartInstance) {
      chartInstance.destroy();
    }

    const ctx = chartRef.current.getContext('2d');
    const newChartInstance = new Chart(ctx, {
      type: 'bar',
      data: chartData,
      options: options,
    });
    setChartInstance(newChartInstance);
  }, [labels]);
  return <canvas ref={chartRef} />;
}

function Graph() {
  const { getAnalyticsData, onFilterChange, count, setCount, customTooltip, time } = useAnalyticsHelper();
  const [labels, setLabels] = useState();
  const [mintCounts, setMintCounts] = useState();

  async function getData() {
    try {
      const response = await getAnalyticsData(false);
      setLabels(response?.labels);
      setMintCounts(response?.mintCounts);
    } catch (error) {}
  }

  useEffect(() => {
    getData();
  }, [count, time]);

  return (
    <div className='charts_graph'>
      <div className='filters'>
        <TimeFilter onFilterChange={onFilterChange} />
        <div className='toggle'>
          Show Distribution
          <Toggle onChange={() => setCount(!count)} checked={count} id={'analytics_count_distribution'} />
        </div>
      </div>
      <div className='analytics_graph'>
        {!count && <MintChart labels={labels} mintCounts={mintCounts} customTooltip={customTooltip} />}
        {count && <MintTotalChart labels={labels} />}
        <MintFeeGraphs />
      </div>
    </div>
  );
}
export default Graph;
