import { Link } from 'react-router-dom';
import { useWindowSize } from 'usehooks-ts';
import { apis, resources } from '../../constants';
import { useLoginHelper } from '../../helpers/login/loginHelper';
import { useGlobalState } from '../../state/GlobalStateProvider';
import Address from '../Address';
import Icons from '../icons/Icons';
import ToolTip from '../toolTip/Tooltip';
import './UserDropdown.scss';

const Menu = () => {
  const [{ user }] = useGlobalState();
  const { userLogout } = useLoginHelper();

  const copyAddress = () => {
    alert.info('Address copied', { autoClose: 200 });
    window.navigator.clipboard.writeText(user.address);
  };

  return (
    <>
      <button className='copy' onClick={copyAddress}>
        <Icons icon={'copy'} /> Copy Address
      </button>
      <Link className='userdropdown_each' to='/me'>
        <Icons icon={'profile'} /> Profile
      </Link>
      <Link to='/settings'>
        <Icons icon={'setting'} /> Settings
      </Link>
      <Link to={apis.backend.getMercleDiscordInvite()} target='_black'>
        <Icons icon={'contact'} /> Contact Us
      </Link>
      <button className='logout' onClick={userLogout}>
        <Icons icon={'logout'} /> Logout
      </button>
    </>
  );
};

const UserDropdown = () => {
  const [{ user }] = useGlobalState();
  const { width } = useWindowSize();

  return (
    <ToolTip className='UserDropdown-container' tipContent={<Menu />} showOnClick={true}>
      <img src={resources.img.getAvatar(user.address)} />
      {width <= 600 ? <></> : <Address a={user.address} redirect={false} />}
    </ToolTip>
  );
};

export default UserDropdown;
