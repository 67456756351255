import { types } from '../constants';
import { useGlobalState } from '../state/GlobalStateProvider';
import { useBackendApi } from './backendHelper';
import { useOauthHelper } from './login/oauthHelper';

export const useDiscordHelper = community => {
  const [{ user }] = useGlobalState();
  const oauthHelper = useOauthHelper();
  const backendApi = useBackendApi();

  const loginDiscord = async redirectUrl => {
    if (user?.discord?.id) return true;
    const oauthUrl = await oauthHelper.getLoginState(types.loginVia.discord, types.postLoginActions.joinDiscord, redirectUrl);
    window.open(oauthUrl, '_blank');
    return false;
  };

  const joinDiscord = async ({ guildId, channelId }) => {
    if (!community?.discord?.guildId) return;
    try {
      const { redirectUrl } = await backendApi.communities.redirectToJoinDiscordUrl(community?.name, guildId, channelId);
      if (await loginDiscord(redirectUrl)) {
        window.open(redirectUrl, '_blank');
      } else {
        alert.info('Please login with Discord');
      }
    } catch (e) {
      console.error(e);
      alert.error('Something went wrong');
    }
  };

  const connectDiscordBot = communityName => {
    // todo: await discord login invasive
    backendApi.communities
      .discordBotIntegrationUrl(communityName)
      .then(({ url }) => {
        window.location.href = url;
      })
      .catch(e => {
        console.error(e);
        // alert.error("could not connect discord bot");
      });
  };

  return {
    loginDiscord,
    joinDiscord,
    admin: {
      connectDiscordBot,
    },
  };
};

export default { useDiscordHelper };
