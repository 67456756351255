import "./AdminVerifyTaskSubmissions.scss";
import React, { useEffect, useState, useContext } from "react";

import dayjs from "dayjs";
// import Address from "components/Address";
import Input from "../components/Input";
import Dropdown from "../components/Dropdown";
import { ethers } from "ethers";
import { useBackendApi } from "../../../helpers/backendHelper";
import { resources } from "../../../constants";
import { useModal } from "../../../components/modal/ModalContext";
import SubmitListPannel from "../components/SubmitListPanel";
import Address from "../../../components/Address";
import utils from "../../../utils";
import ButtonApi from "../../../components/ButtonApi";
import EventProvider, { useEvent } from "../../events/event/EventProvider";

const AdminVerifyTaskSubmission = () => {
  const backendApi = useBackendApi();
  const [data, setData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [{ openModal }] = useModal();

  const openDetailModal = (data) => {
    openModal({
      className: "SubmitListPannelBox",
      classNameContent: "",
      content: <SubmitListPannel refreshData={fetchData} data={data}></SubmitListPannel>,
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setIsFetching(true);
      const res = await backendApi.communities.tokens.events.getSubmissionTasks();
      setData(res);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsFetching(false);
    }
  };

  const tdArray = ["Task Name", "Wallet Address", "Date", "Username", "Status", ""];

  return (
    <section className="AdminVerifyTaskSubmissions-container">
      <h1>Admin VerifyTask Submissions</h1>
      <div className="api-cards">
        <div className="searchInputBox">
          <input type="text" />
          <button>filters</button>
        </div>
        <table className="tableBox">
          <colgroup>
            <col className="col-200" />
            <col />
            <col />
            <col className="col-input" />
            <col className="col-date" />
            <col className="col-edit" />
          </colgroup>
          <thead>
            <tr>
              {tdArray.map((e) => (
                <td key={e}>{e}</td>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((item, i) => (
              <tr key={item._id}>
                <td>{item?.eventName}</td>
                <td>
                  <Address a={item?.userAddress} />
                </td>
                <td>{dayjs(item?.cAt).format("DD MMM YYYY HH:mm")}</td>
                <td>{item?.userName}</td>
                <td>
                  <span className={`state ${item?.status}`}>{item?.status}</span>
                </td>
                <td>
                  <img
                    onClick={() => openDetailModal(item)}
                    className="actionButton"
                    src={resources?.img?.actionButton}
                    alt=""
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="pageBox">
          <div className="pageAction">
            <img className="arrowButton" src={resources?.img?.arrowLeft} alt="" />
            <img className="arrowButton rightArrow" src={resources?.img?.arrowLeft} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

const AdminIssueReward = () => {
  
  const backendApi = useBackendApi();
  const [{ event, token }] = useEvent();
  const issueReward = async () => {
    try {
      const taskId = utils.getElementValue("id.adminIssueReward.taskId", "selected");
      const userAddress = utils.getElementValue("id.adminIssueReward.userAddress");

      if (!taskId) return alert.error("Invalid Task Id");
      if (!ethers.isAddress(userAddress)) return alert.error("Invalid user address");

      alert.info("Issuing Reward");
      await backendApi.communities.tokens.events.adminIssueReward(token.address, {
        taskId,
        userAddress: ethers.getAddress(userAddress),
      });
      alert.success("Reward issued");
    } catch (e) {
      console.error(e);
      alert.error("Couldn't issue reward");
    }
  };
  const taskOptions = event?.events?.map((task) => ({
    label: task.name,
    value: task.id,
  }));

  return (
    <section>
      <h1>Issue Reward</h1>
      <Dropdown id="id.adminIssueReward.taskId" label="Task" options={taskOptions} />
      <Input id="id.adminIssueReward.userAddress" label="User Address" />
      <ButtonApi onClick={issueReward}>Issue Reward</ButtonApi>
    </section>
  );
};

const AdminVerifyTaskSubmissions = () => {
  return (
    <>
    

      <AdminIssueReward />
      <AdminVerifyTaskSubmission />
   
    </>
  );
};

export default AdminVerifyTaskSubmissions;
