import Skeleton from 'react-loading-skeleton';
import Grid from '../../../components/Grid';
import { CardsLoader } from '../../../components/Skeleton/CardsLoader';
import SimpleSlider from '../../../components/slider/SimpleSlider';
import NotFound404 from '../../NotFound404';
import './Raffle.scss';
import RaffleDetails from './Raffle/RaffleDetails';
import RaffleTasks from './Raffle/RaffleTasks';
import RaffleWinners from './Raffle/RaffleWinners';
import RaffleProvider, { useRaffle } from './RaffleProvider';

const RaffleRewardItemImg = () => {
  const [{ raffle }] = useRaffle();
  const raffleRewards = raffle?.reward?.rewardItems;

  if (raffleRewards === undefined) return <CardsLoader rows={1} columns={1} className={'RewardItems-image-container-loader'} />;
  if (!raffleRewards?.length) return <></>;
  return (
    <SimpleSlider className='RewardItems-container'>
      {raffleRewards?.map((v, i) => (
        <div className='raffleNft-Preview'>
          <img key={i} src={v.rewardNameImage} />
          <a href={v.rewardExternal} className='ellipse' target='_blank'>
            {v.rewardName} <span className='tokenId'>#{v.rewardTokenId}</span>
          </a>
        </div>
      ))}
    </SimpleSlider>
  );
};

const Raffle = () => {
  const [{ raffle }] = useRaffle();
  const raffleWinners = raffle?.raffleResult?.winners;

  if (raffle === undefined) return <Skeleton />;
  if (!raffle) return <NotFound404 />;
  return (
    <div className='Raffle-container'>
      <Grid className='raffledetails' columns={2} minWidth={300}>
        <RaffleRewardItemImg />
        <RaffleDetails raffleWinners={raffleWinners} />
      </Grid>

      {raffleWinners ? <RaffleWinners /> : <RaffleTasks />}
    </div>
  );
};

const P = () => (
  <RaffleProvider>
    <Raffle />
  </RaffleProvider>
);

export default P;
