import './Tooltip.scss';
import { useEffect, useRef, useState } from 'react';
import { useModal } from '../modal/ModalContext';
import { useWindowSize } from 'usehooks-ts';

const ToolTip = ({ className = '', children, tipContent, showOnClick = false, contentClassName , stopPropagation}) => {
  const [{ openModal }] = useModal();
  const { width } = useWindowSize();
  const [show, setShow] = useState(false);
  const tooltipRef = useRef();

  const onClick = (event) => {
    if (stopPropagation) {
      event.stopPropagation()
    }
    if (!showOnClick) return;
    if (width >= 600) return setShow(s => !s);
    openModal({ className, classNameContent: 'tooltip-content', content: tipContent });
  };

  const handleClickOutside = event => {
    if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
      setShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className={`ToolTip-container ${className}  ${showOnClick ? 'showOnClick' : 'hoverable'}`} onClick={onClick}>
      {children}
      <div className={`tooltip-content ${contentClassName} ${show ? '' : 'not-show'} `} ref={tooltipRef}>
        {tipContent}
      </div>
    </div>
  );
};

export default ToolTip;