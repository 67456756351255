import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';
import { useWindowSize } from 'usehooks-ts';
import Grid from '../../../components/Grid';
import { CardsLoader } from '../../../components/Skeleton/CardsLoader';
import { useBackendApi } from '../../../helpers/backendHelper';
import { useCountdown } from '../../../hooks/useCountDown';
import './Raffles.scss';

const Countdown = ({ timeMillis }) => {
  const { time } = useCountdown(timeMillis);

  return <>{time}</>;
};

const RaffleCard = ({ raffle }) => {
  const { width } = useWindowSize();
  const timeMillis = Date.now() < raffle.startDateMillis ? raffle.startDateMillis : raffle.endDateMillis;
  const hasEnded = Date.now() > raffle.endDateMillis || raffle.raffleStatus == 'ended';

  return (
    <Link className='RaffleCard-container card' to={`/raffles/${raffle.name}`}>
      <div className='img' style={{ backgroundImage: `url("${raffle.image}")` }} />

      <h2>
        <span className={hasEnded ? 'ended' : 'live'}>•</span>
        {hasEnded ? 'Raffle Ended' : <Countdown timeMillis={timeMillis} />}
      </h2>
      <h1 className='ellipse'>{raffle.displayName}</h1>
      {width <= 800 ? <></> : <p>{raffle.description}</p>}
    </Link>
  );
};

const Raffles = ({ title = 'All Raffles', columns = 4, rows, limit = 24, isLive = true, className }) => {
  const backendApi = useBackendApi();
  const [raffles, setRaffles] = useState();
  const { ref: listEndRef, inView } = useInView();
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [liveRaffles, setLiveRaffles] = useState(isLive);
  function handleRaffleChange() {
    setLiveRaffles(!liveRaffles);
  }
  useEffect(() => {
    const skip = (page - 1) * limit;
    backendApi.raffle
      .getRaffles(liveRaffles, skip, limit)
      .then(raffles => {
        if (raffles.length == 0) setLiveRaffles(false);
        if (raffles.length == 0 || raffles.length < limit) setHasMore(false);
        setRaffles(_raffles => [...(_raffles || []), ...raffles]);
      })
      .catch(e => {
        setRaffles(null);
      });
  }, [page]);

  useEffect(() => {
    const skip = (page - 1) * limit;
    backendApi.raffle
      .getRaffles(liveRaffles, skip, limit)
      .then(raffles => {
        console.log({ raffles });
        if (raffles.length == 0 || raffles.length < limit) setHasMore(false);
        setRaffles(raffles);
      })
      .catch(e => {
        setRaffles(null);
      });
  }, [liveRaffles]);

  useEffect(() => {
    if (inView && hasMore) setPage(page + 1);
  }, [inView]);

  if (raffles === undefined) return <CardsLoader rows={3} columns={columns} columnHeight={320} />;

  return (
    <>
      <Grid
        className={`Raffles-container ${className || ''}`}
        columns={columns}
        rows={rows}
        title={title}
        toggle={{ title: `${liveRaffles ? 'Live' : 'Ended'}`, required: true, isChecked: liveRaffles, onChange: handleRaffleChange }}
      >
        {raffles?.map((v, i) => (
          <RaffleCard key={i} raffle={v} />
        ))}
      </Grid>

      {raffles.length == 0 ? <p className='no-raffle-text'>No {liveRaffles ? 'Live' : 'Ended'} Raffles</p> : <></>}

      <div ref={listEndRef} className='infinite-scroll-observer'></div>
    </>
  );
};

export default Raffles;
