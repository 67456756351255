import { useEffect, useState } from 'react';
import Grid from '../../components/Grid';
import { CardsLoader } from '../../components/Skeleton/CardsLoader';
import Icons from '../../components/icons/Icons';
import { useBackendApi } from '../../helpers/backendHelper';
import { useGlobalState } from '../../state/GlobalStateProvider';
import utils, { isEmpty } from '../../utils';
import './MyStats.scss';

const StatCard = ({ stat }) => {
  return (
    <div className='StatCard-container'>
      <h1>{utils.numberShort(stat.value)}</h1>
      <div className='content'>
        {stat.icon ? stat.icon : <></>}
        <p className='ellipse'>{stat.title}</p>
      </div>
    </div>
  );
};

const MyStats = ({}) => {
  const [{ user }] = useGlobalState();
  const backendApi = useBackendApi();
  const [stats, setStats] = useState();

  useEffect(() => {
    if (!user) return;
    backendApi.user
      .getUserStats()
      .then(res => {
        if (isEmpty(res)) {
          setStats(null);
          return;
        }
        setStats(res);
      })
      .catch(e => console.error(e));
  }, [user]);

  if (!user || stats === null) return <></>;
  if (!stats) return <CardsLoader rows={1} columns={5} columnHeight={150} />;
  return (
    <Grid className='MyStats-container no-scrollbar' title='Your Stats' columns={6} rows={1}>
      <StatCard
        stat={{
          icon: <Icons icon={'done'} />,
          value: stats?.totalTasksDone || 0,
          title: 'Tasks done',
        }}
      />
      <StatCard
        stat={{
          icon: <Icons icon={'star'} />,
          value: stats?.totalXp || 0,
          title: 'Total XP',
        }}
      />
      <StatCard
        stat={{
          icon: <Icons icon={'graph'} />,
          value: stats?.totalXpDefi || 0,
          title: 'De-Fi XP',
        }}
      />
      <StatCard
        stat={{
          icon: <Icons icon={'social'} />,
          value: stats?.totalXpSocial || 0,
          title: 'Social XP',
        }}
      />
      <StatCard
        stat={{
          icon: <Icons icon={'community'} />,
          value: stats?.totalXpCommunity || 0,
          title: 'Community XP',
        }}
      />
      <StatCard
        stat={{
          icon: <Icons icon={'referral'} />,
          value: stats?.referralCount || 0,
          title: 'Referrals',
        }}
      />
    </Grid>
  );
};

export default MyStats;
