import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { createContainer } from 'react-tracked';
import { id } from '../../../constants';
import { useBackendApi } from '../../../helpers/backendHelper';
import { useEthersHelper } from '../../../helpers/ethersHelper';
import { useGlobalState } from '../../../state/GlobalStateProvider';
import { limitedApi } from '../../../utils';

const useEventState = () => {
  const [{ user }] = useGlobalState();
  const ethersHelper = useEthersHelper();
  const backendApi = useBackendApi();

  const [state, setState] = useState({});
  let { communityName } = useParams();
  if (!communityName) {
    communityName = window.location.pathname?.slice(1); // applicable for og-nft path
    communityName = communityName.replace(/\/$/, '');
  }

  const getAllLiveRaffle = communityName => {
    backendApi.raffle
      .getRaffles(true, undefined, undefined, communityName)
      .then(raffles => {
        setState(s => ({ ...s, communityRaffles: raffles || null }));
      })
      .catch(e => {
        setState(s => ({ ...s, communityRaffles: null }));
      });
  };

  useEffect(() => {
    window.addEventListener('logout', logout);
  }, []);

  useEffect(() => {
    if (!communityName) return;
    getAllLiveRaffle(communityName);
  }, [communityName]);

  useEffect(() => {
    if (!communityName) return;
    if (state.event) return;
    window.mixpanel?.track?.('Event page', {
      communityName,
    });

    backendApi.communities
      .get(communityName)
      .then(community => {
        setState(s => ({ ...s, community: community || null }));
      })
      .catch(e => {
        setState(s => ({ ...s, community: null }));
      });

    backendApi.communities
      .getEvent(communityName)
      .then(event => {
        const token = event ? { address: event?.events?.[0]?.tokenAddr, chainId: event?.events?.[0]?.chainId } : null;
        setState(s => ({ ...s, event: event || null, token }));
      })
      .catch(e => {
        setState(s => ({ ...s, event: null }));
      });
  }, [communityName]);

  useEffect(() => {
    if (!state.token?.address) return;
    if (state.campaign) return;

    const campaignName = state.event.events?.[0]?.campaign;
    limitedApi(id.storage.campaign(campaignName), () =>
      backendApi.communities
        .getCampaign(communityName, state.token.address, campaignName)
        .then(campaign => {
          setState(s => ({ ...s, campaign: campaign || null }));
        })
        .catch(e => {
          setState(s => ({ ...s, campaign: null }));
        }),
    );
  }, [state.token?.address]);

  useEffect(() => {
    if (!user) return;
    if (!state.token?.address) return;
    if (state.nftProfile) return;
    if (state?.campaign?.isCosmos) return;

    limitedApi('nftProfile', () =>
      ethersHelper
        .getNftProfileTokenId(communityName, user?.address, state.token.address, state.token.chainId, true, state?.campaign?.isCosmos)
        .then(({ tokenId }) => {
          setState(s => ({ ...s, token: { ...s.token, tokenId } }));
          const promise = backendApi.communities
            .getNftProfile(communityName, state.token.address, tokenId)
            .then(nftProfile => {
              console.log({ nftProfile });
              setState(s => ({ ...s, nftProfile: nftProfile || null }));
              return;
            })
            .catch(e => {
              setState(s => ({ ...s, nftProfile: null }));
              throw e;
            });

          alert.promise(promise, {
            pending: 'Loading your profile',
            success: 'Profile loaded',
            error: 'Could not get profile',
          });
        })
        .catch(e => {
          setState(s => ({ ...s, nftProfile: null }));
          console.log(e);
        }),
    );
  }, [user, state.token?.address]);

  // this will throw error. need to catch where every its being called
  const refreshNftProfile = async (fetchTokenId = false) => {
    let tokenId = state.token.tokenId;
    if (fetchTokenId) {
      const token = await ethersHelper.getNftProfileTokenId(communityName, user?.address, state.token.address, state.token.chainId, true, state.campaign?.isCosmos);
      tokenId = token.tokenId;
      setState(s => ({ ...s, token: { ...s.token, tokenId } }));
    }

    return backendApi.communities
      .getNftProfile(communityName, state.token.address, tokenId)
      .then(nftProfile => {
        setState(s => ({ ...s, nftProfile: nftProfile || null }));
      })
      .catch(e => {
        setState(s => ({ ...s, nftProfile: null }));
        throw new Error('Could not get profile');
      });
  };

  const logout = () => {
    setState(s => ({ ...s, nftProfile: undefined, token: { ...s.token, tokenId: undefined }, isEdit: undefined }));
  };

  const setIsEdit = v => setState(s => ({ ...s, isEdit: v }));

  return [{ ...state, communityName, refreshNftProfile, logout, setIsEdit }, setState];
};

const { Provider, useTracked } = createContainer(() => useEventState());

export const useEvent = useTracked;
export const EventProvider = ({ children }) => <Provider>{children}</Provider>;
export default EventProvider;
