import React, { useContext, useEffect, useState } from 'react';
import { walletContext } from 'walletconnector';
import ButtonApi from '../../components/ButtonApi';
import { useBackendApi } from '../../helpers/backendHelper';
import { useEthersHelper } from '../../helpers/ethersHelper';
import { useGlobalState } from '../../state/GlobalStateProvider';
import './Referals.scss';

const ETH_CURRENCY_SYMBOL = 'ETH';
const MATIC_CURRENCY_SYMBOL = 'MATIC';

const statsTemplate = {
  mintTxns: [],
  rewardsEarned: {
    ETH: 0,
    MATIC: 0,
  },
  rewardsClaimed: {
    ETH: 0,
    MATIC: 0,
  },
  mintCount: 0,
};

const getFormattedUTCString = date => {
  return `${date.getUTCFullYear()}/${date.getUTCMonth()}/${date.getUTCDay()} ${date.getUTCHours()}:${date.getUTCMinutes()} UTC`;
};

const formatAmount = (amount, precision = 6) => {
  return Math.round(amount * Math.pow(10, precision)) / Math.pow(10, precision);
};

const Referrals = () => {
  const backend = useBackendApi();
  const [stats, setStats] = useState(statsTemplate);
  const [{ user }] = useGlobalState();
  const { changeNetwork } = useContext(walletContext);
  const ethersHelpers = useEthersHelper();

  const fetchStats = async () => {
    if (!user) {
      setStats(statsTemplate);
      return;
    }
    const res = await backend.user.getReferralStats();
    setStats(res);
  };

  useEffect(() => {
    fetchStats().catch(e => {
      console.log('Fetch err : ', e);
      setStats(statsTemplate);
    });
  }, [user]);

  const claim = async currency => {
    const alertId = alert.loading('Claiming');
    try {
      const { contractAddress, domain, message, sign, chainId } = await backend.user.getClaimPayload(currency);
      await changeNetwork(chainId);
      const contract = ethersHelpers.getReferralRewardDistributor(contractAddress);
      const txn = await contract.claim(domain, message, sign);
      await txn.wait();

      alert.update(alertId, {
        render: 'Claim Sucess',
        type: 'success',
        isLoading: false,
        autoClose: 5000,
        closeButton: true,
      });

      fetchStats().catch(e => {
        console.log('Fetch err : ', e);
        setStats(statsTemplate);
      });
    } catch (error) {
      const alertConfig = {
        render: 'Transaction rejected',
        type: 'error',
        isLoading: false,
        autoClose: 5000,
        closeButton: true,
      };

      if (error.message?.includes?.('user reject')) alertConfig.render = 'Transaction rejected';
      else if (error?.message?.includes?.('insufficient funds')) alertConfig.render = 'Insufficient Balance';
      else if (error?.message?.includes?.('estimateGas')) alertConfig.render = 'Gas estimation failed. Please ensure you have sufficient balance or try again after few seconds.';
      else if (error?.message != '400') alertConfig.render = error?.message;
      else alertConfig.render = 'Something went wrong. Please try again after sometime';

      alert.update(alertId, alertConfig);
      console.log(error);
    }
  };

  return (
    <div className='referal_main_container'>
      <div className='description_container'>
        <div className='description'>
          <h1>Invite To Earn!</h1>
          <p className='text'>
            Earn rewards by simply sharing your referral link with your friends! Every time they mint a Mercle NFT using your referral link, you get a share of the mint fee. This
            Isn’t just a one-time thing, as your link can be reused for earning incentives from multiple invitees. Referrals apply to all dynamic NFTs and MBADGEs.
            <a href='https://mercle.xyz/read/referral-rewards'> Read more here </a>
          </p>
        </div>
        <img src='https://dskhnex0aho1n.cloudfront.net/public/referralBanner.png' />
      </div>

      <div className='claim_container'>
        <div>
          <h2>Available to claim</h2>
          {stats.mintCount > 20 && (
            <p className='reward-limit-text'>
              Congrats! You have more than 20 referrals! Since the reward claim is still in beta mode, we request you to create a ticket on discord for claiming these rewards.
            </p>
          )}
        </div>

        <div className='claims'>
          <div className='each_tile '>
            <div className='img-and-amount'>
              <img src='https://dskhnex0aho1n.cloudfront.net/public/arbitrum.svg' />
              <div className='amount'>
                <span>{+stats.rewardsEarned[ETH_CURRENCY_SYMBOL] ? formatAmount(+stats.rewardsEarned[ETH_CURRENCY_SYMBOL]) : 0}</span>
                ETH
              </div>
            </div>

            <ButtonApi disabled={!+stats.rewardsEarned[ETH_CURRENCY_SYMBOL]} className='button' onClick={() => claim('ETH')}>
              {' '}
              Claim
            </ButtonApi>
          </div>
          <div className='each_tile '>
            <div className='img-and-amount'>
              <img src='https://dskhnex0aho1n.cloudfront.net/public/polygon.svg' />
              <div className='amount'>
                <span>{+stats.rewardsEarned[MATIC_CURRENCY_SYMBOL] ? formatAmount(+stats.rewardsEarned[MATIC_CURRENCY_SYMBOL]) : 0}</span>
                MATIC
              </div>
            </div>

            <ButtonApi className='button' disabled={!+stats.rewardsEarned[MATIC_CURRENCY_SYMBOL]} onClick={() => claim('MATIC')}>
              Claim
            </ButtonApi>
          </div>
        </div>
      </div>

      <div className='stats_container'>
        <div className='stats'>
          <h2>Your reward stats</h2>
          <div className='each_stat'>
            <span>{stats.mintCount || 0}</span>
            <div className='stats-footer'>Verified Mints</div>
          </div>
          <div className='each_stat'>
            <span>{+stats.rewardsClaimed[ETH_CURRENCY_SYMBOL] ? formatAmount(+stats.rewardsClaimed[ETH_CURRENCY_SYMBOL]) : 0}</span>
            <div className='stats-footer'>Total ETH Earned</div>
          </div>
          <div className='each_stat'>
            <span>{+stats.rewardsClaimed[MATIC_CURRENCY_SYMBOL] ? formatAmount(+stats.rewardsClaimed[MATIC_CURRENCY_SYMBOL]) : 0}</span>
            <div className='stats-footer'>Total MATIC Earned</div>
          </div>
        </div>

        <div className='table-container'>
          <h2>History of Invites</h2>
          <table className='invite-table'>
            <tr className='table-header'>
              <th>#</th>
              <th>Referred</th>
              <th>NFT</th>
              <th>Crypto</th>
              <th>Timestamp</th>
            </tr>
            {stats.mintTxns.slice(0, 10).map((txn, index) => {
              return (
                <tr>
                  <td>{index + 1}</td>
                  <td>{txn.referred_user ? txn.referred_user.slice(0, 5) + '...' + txn?.referred_user.slice(38, txn.referred_user.length) : ''}</td>
                  <td>{txn.campaign || ''}</td>
                  <td>{txn.currency || ''}</td>
                  <td>{+txn?.timestamp ? getFormattedUTCString(new Date(+txn.timestamp)) : ''}</td>
                </tr>
              );
            })}
          </table>
        </div>
      </div>
    </div>
  );
};

export default Referrals;
