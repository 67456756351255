import { chainexplorer } from '../constants';
import './Address.scss';

const Address = ({ a, chainId = 1, isTxn, first = 5, second = -4, redirect = true }) => {
  if (!a) return <></>;

  const address = a.substr(0, first) + '…' + a.substr(second);
  return (
    <a className='Address link' href={redirect ? chainexplorer[chainId](a, isTxn) : undefined} target='_blank'>
      {address}
    </a>
  );
};

export default Address;
