import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Grid from '../../components/Grid';
import { CardsLoader } from '../../components/Skeleton/CardsLoader';
import { NftCard } from '../../components/nft/NftCard';
import { useBackendApi } from '../../helpers/backendHelper';
import './TopTasks.scss';

const TopTasks = () => {
  const backendApi = useBackendApi();
  const [data, setData] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    backendApi.communities
      .getTopCommunities('mbadge', 0, 4)
      .then(setData)
      .catch(() => setData(null));
  }, []);

  if (data === undefined) return <CardsLoader rows={1} columns={4} columnHeight={200} />;
  if (!data?.top?.length) return <></>;
  return (
    <Grid
      className='TopTasks-container'
      title='MBadges'
      sectionAction={{
        label: 'View More',
        action: () => {
          navigate(`/mbadges`);
        },
      }}
      columns={4}
      rows={1}
    >
      {data?.top?.slice(0, 4)?.map((top, i) => (
        <NftCard campaign={data.campaigns?.[top.commId]} community={data.communities?.[top.commId]} event={data.events?.[top.commId]} />
      ))}
    </Grid>
  );
};

export default TopTasks;
