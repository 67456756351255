import { useContext } from 'react';
import { walletContext } from 'walletconnector';
import LoginPanel from '../../components/login/LoginPanel';
import { useModal } from '../../components/modal/ModalContext';
import { id } from '../../constants';
import { useGlobalState } from '../../state/GlobalStateProvider';
import { useBackendApi } from '../backendHelper';

export const useLoginHelper = () => {
  const [{ user, setUser, logout }] = useGlobalState();
  const { getUserAddress, getSigner, disconnect } = useContext(walletContext);
  const backendApi = useBackendApi();
  const [{ openModal, closeModal }] = useModal();

  // will throw on exception
  const userLogin = async ({ connectorId, isInvasive = false } = {}) => {
    if (isInvasive && !getUserAddress()) {
      try {
        await new Promise((res, rej) => {
          openModal({
            className: 'loginPanel',
            classNameContent: '',
            content: <LoginPanel onWalletConnect={res} onWalletConnectError={() => rej('Could not login')} />,
            onClose: rej,
          });
        });
      } catch (e) {
        console.error(e);
        throw e;
      } finally {
        closeModal();
      }
    }

    await walletLogin({ connectorId, isInvasive });
  };

  const walletLogin = async ({ connectorId, isInvasive = false }) => {
    if (isInvasive || !user) {
      window.mixpanel?.track?.('userLogin', {
        url: window.location.href,
        connectorId,
        msg: `requesting login sign to ${connectorId}`,
      });

      const message = 'Log into Mercle';

      const sign = await getSigner().signMessage(message);
      const _user = await backendApi.login({
        account: getUserAddress(),
        message,
        sign,
        onError: window.location.href,
      });
      setUser(_user);

      window.mixpanel?.identify?.(_user.address);
      window.mixpanel?.track?.('userLogin', {
        url: window.location.href,
        connectorId,
        isInvasive,
        success: true,
      });

      const referralCode = localStorage.getItem(id.storage.referralCode);
      if (referralCode) backendApi.user.useReferralCode({ code: referralCode }).finally(() => localStorage.removeItem(id.storage.referralCode));

      return _user;
    }
    return user;
  };

  const userLogout = () => {
    logout();
    backendApi.logout().catch(e => console.error(e));
    localStorage.removeItem(id.storage.loginVia);
    localStorage.removeItem(id.storage.userStats);

    disconnect().catch(e => console.error(e));
  };

  const postProcessUserLogin = async user => {
    if (!user) return false;
    setUser(user);
    return true;
  };

  return { userLogin, userLogout, postProcessUserLogin };
};
