import { ethers } from 'ethers';
import { useEffect, useState } from 'react';
import { types } from '../../constants';
import { useBackendApi } from '../../helpers/backendHelper';
import { useCommunityHelper } from '../../helpers/communityHelper';
import { useDiscordHelper } from '../../helpers/discordHelper';
import { useEthersHelper } from '../../helpers/ethersHelper';
import { useGlobalState } from '../../state/GlobalStateProvider';
import utils from '../../utils';
import { useEvent } from '../events/event/EventProvider';
import Dropdown from './components/Dropdown';
import Input from './components/Input';
import OnboardingDefaultDiscordChannel from './discord/OnboardingDefaultDiscordChannel';

const CommunityLinks = () => {
  const [{ community }, setState] = useEvent();
  const backendApi = useBackendApi();
  const { getCommunity } = useCommunityHelper();
  const update = async () => {
    const website = document.getElementById('id.input.settings.links.website').value?.trim();
    const twitter = document.getElementById('id.input.settings.links.twitter').value?.trim();
    const telegram = document.getElementById('id.input.settings.links.telegram').value?.trim();
    const telegramUrl = document.getElementById('id.input.settings.links.telegramUrl').value?.trim();
    const discord = document.getElementById('id.input.settings.links.discord').value?.trim();
    const mail = document.getElementById('id.input.settings.links.mail').value?.trim();
    const links = {
      website: website?.indexOf('//') > -1 ? website.split('//')[1] : website,
      twitter: twitter?.indexOf('//') > -1 ? twitter.split('//')[1] : twitter,
      telegram: telegram?.indexOf('//') > -1 ? telegram.split('//')[1] : telegram,
      telegramUrl: telegramUrl?.indexOf('//') > -1 ? telegramUrl.split('//')[1] : telegramUrl,
      discord: discord?.indexOf('//') > -1 ? discord.split('//')[1] : discord,
      mail,
    };
    try {
      await backendApi.communities.updateLinks(links);
      getCommunity().then(res => [setState(s => ({ ...s, community: res }))]);

      alert.success('Links updated');
      return;
    } catch (e) {
      console.error(e);
    }
    alert.error('Cannot update links');
    return;
  };
  if (!community) return;
  return (
    <>
      <Input label='Website' id={'id.input.settings.links.website'} defaultValue={community.links?.website} />
      <Input label='Twitter Handle' id={'id.input.settings.links.twitter'} defaultValue={community.links?.twitter} />
      <Input label='Telegram Url' id={'id.input.settings.links.telegramUrl'} defaultValue={community.links?.telegramUrl} />
      <Input label='Telegram Id' id={'id.input.settings.links.telegram'} defaultValue={community.links?.telegram} />
      <Input label='Discord' id={'id.input.settings.links.discord'} defaultValue={community.links?.discord} />
      <Input label='Mail' id={'id.input.settings.links.mail'} defaultValue={community.links?.mail} />
      <button className='btn btn-info btn-base mt-2' onClick={update}>
        Update
      </button>
    </>
  );
};

const AdminSettings = () => {
  const backendApi = useBackendApi();
  const [{ user }] = useGlobalState();
  const [{ community, communityName }, setState] = useEvent();
  const { admin } = useDiscordHelper();
  const ethersHelper = useEthersHelper();
  const [isMercleAdmin, setIsMercleAdmin] = useState(false);
  const { getCommunity } = useCommunityHelper();
  const [allRaffles, setAllRaffles] = useState([]);
  const [ipfsURL, setIpfsURL] = useState('');
  useEffect(() => {
    getMercleCommunity();
    getCommunity().then(res => {
      setState(s => ({ ...s, community: res }));
    });
  }, []);

  const getMercleCommunity = async () => {
    try {
      const community = await backendApi.communities.get('mercle');
      if (community?.admins[user._id]) {
        setIsMercleAdmin(true);
      }
    } catch (err) {
      console.log('error getting mercle community');
    }
  };

  const uploadLogo = () => {
    alert.info('uploading logo');
    const imageFile = utils.getElementValue('communityLogo', 'files')?.[0];
    const imageFd = new FormData();
    imageFd.append('file', imageFile);
    imageFd.append('name', utils.getRandomId());
    imageFd.append('task', types.fileUploadTask.avatar);
    backendApi.communities
      .uploadFileAdmin(imageFd)
      .then(() => {
        alert.success('Logo updated');
      })
      .catch(e => {
        console.error(e);
        alert.error('Could not update logo');
      });
  };

  const updateAdmins = () => {
    const admins = utils
      .getElementValue('inputUpdateAdmins')
      ?.trim()
      ?.split('\n')
      .map(v => v.trim());
    backendApi.communities
      .updateAdmins(admins)
      .then(() => {
        alert.success('admins updated');
        getCommunity().then(res => {
          setState(s => ({ ...s, community: res }));
        });
      })
      .catch(e => {
        console.error(e);
        alert.error('could not update admins');
      });
  };

  const resetMyUserData = () => {
    const tokenId = utils.getElementValue('id.input.admin.testing.resetMyData.tokenId');
    const tokenAddress = utils.getElementValue('id.input.admin.testing.resetMyData');
    if (!tokenId) return alert.error('Token Id required');
    if (!tokenAddress) return alert.error('Token address required');
    if (!ethers.isAddress(tokenAddress)) return alert.error('Invalid token address');
    backendApi.communities.testing
      .restMyNftProfile(tokenAddress, { tokenId })
      .then(() => {
        alert.success('Nft data reset');
      })
      .catch(e => {
        alert.error('Could not reset Nft data');
        console.error(e);
      });
  };

  const deleteMyNftProfile = () => {
    const tokenId = utils.getElementValue('id.input.admin.testing.resetMyData.tokenId');
    const tokenAddress = utils.getElementValue('id.input.admin.testing.resetMyData');
    if (!tokenAddress) return alert.error('Token address required');
    if (!ethers.isAddress(tokenAddress)) return alert.error('Invalid token address');
    backendApi.communities.testing
      .deleteNftProfile(tokenAddress, { tokenId })
      .then(() => {
        alert.success('Nft data reset');
      })
      .catch(e => {
        alert.error('Could not reset Nft data');
        console.error(e);
      });
  };

  const getAllRaffles = async () => {
    backendApi.raffle
      .getAllRaffle()
      .then(allRaffle => {
        setAllRaffles(allRaffle);
      })
      .catch(err => {
        console.log('errorGetting Raffle', err);
      });
  };

  useEffect(() => {
    getAllRaffles();
  }, []);

  const getRaffleEntriesIPFS = async () => {
    const raffleName = utils.getElementValue('id.input.settings.raffle.PickRaffleWinner', 'selected');
    try {
      alert.info('Uploading participants to IPFS');
      const ipfs = await backendApi.raffle.getRaffleEntriesIPFS(raffleName);
      setIpfsURL(ipfs);
      alert.success('Participants uploaded to IPFS');
    } catch (e) {
      console.error(e);
      alert.error('Could not set raferral entries in ipfs');
    }
  };

  const pickWinner = async () => {
    try {
      const raffleName = utils.getElementValue('id.input.settings.raffle.PickRaffleWinner', 'selected');
      const winnerCount = utils.getElementValue('id.input.admin.raffle.fileInput');
      const raffleId = allRaffles.find(raffle => raffle.name === raffleName)._id;

      if (!winnerCount) return alert.error('winner count cannot be 0');

      const rafflePickerContract = await ethersHelper.getRafflePicker('0x8160b10babac3B99117E509bb49bDa990ffE2416');

      await ethersHelper.changeNetwork(137);

      const txn = await rafflePickerContract.pickWinners(
        utils.bsonIdToBytes12(raffleId.toString()), // 0xbsonId of raffle
        ipfsURL.raffleEntriesCount, // total participants
        parseInt(winnerCount), // number of winners to pick
        getRandomSeed(), // random seed
        ipfsURL.uploadedFile, //uploaded file in ipfs
      );
      console.log('pickWinners::txn', txn);

      alert.info('Tracking txn');
      await backendApi.raffle.pickWinner(raffleName, txn.hash);

      const receipt = await txn.wait();
      console.log('pickWinners::receipt', receipt);

      alert.success('Pick Winners completed');
    } catch (e) {
      console.error(e);
      alert.error('Could not pick winner');
    }
  };

  const getRandomSeed = () => {
    return Math.floor(Math.random() * 1000000000);
  };

  if (!community) return <>Loading...</>;

  return (
    <>
      <section>
        <h1>Connect Discord Server</h1>
        <button onClick={() => admin.connectDiscordBot(communityName)}>Connect Discord to server</button>
        <br />
        <OnboardingDefaultDiscordChannel />
      </section>
      <section>
        <h1>Community Logo</h1>
        <img src={community.avatar} />
        <input id='communityLogo' type='file' accept='.svg,.png,.jpg,.jpeg' />
        <button onClick={uploadLogo}>Upload Logo</button>
      </section>
      <section>
        <h1>Community Links</h1>
        <CommunityLinks />
      </section>
      <section>
        <h1>Reset My NFT and Linked Accounts (github, discord, email)</h1>
        <Input label='Token Id' id='id.input.admin.testing.resetMyData.tokenId' />
        <Input label='Token Address' id='id.input.admin.testing.resetMyData' />
        <button onClick={resetMyUserData}>Rest My Data</button>
        <button onClick={deleteMyNftProfile}>Delete Nft Profile</button>
      </section>
      <section>
        <h1>Update Admins</h1>
        <textarea id='inputUpdateAdmins' defaultValue={Object.keys(community?.admins).join('\n')} />
        <button onClick={updateAdmins}>Update Admins</button>
      </section>

      {isMercleAdmin && (
        <section>
          <h1>PickWinner Raffle</h1>
          <Dropdown
            id={'id.input.settings.raffle.PickRaffleWinner'}
            label='Select the raffle'
            options={allRaffles?.map(raffle => {
              return {
                label: (
                  <div>
                    {raffle.name} [{raffle._id}]
                  </div>
                ),
                value: raffle.name,
              };
            })}
          />
          <h5>Raffle entries IPFS:{ipfsURL?.uploadedFile}</h5>
          <button onClick={getRaffleEntriesIPFS}>set raffle entries in ipfs</button>
          <Input label='Winner Count' id='id.input.admin.raffle.fileInput' />
          <button onClick={pickWinner}>Pick Winner </button>
        </section>
      )}
    </>
  );
};

export default AdminSettings;
