import { useContext } from 'react';
import './CollapsingDiv.scss';
import CollapsingDivProvider, { collapsingDivContext } from './CollapsingDivProvider';

const CollapsingDiv = ({ className = '', header, content, ref }) => {
  const { collapseContentDivRef, toggleCollapse } = useContext(collapsingDivContext);

  return (
    <div className={`collapsingDiv-container ${className}`} ref={ref}>
      <header onClick={toggleCollapse}>{header}</header>
      <div ref={collapseContentDivRef} className='content'>
        {content}
      </div>
    </div>
  );
};

const CollapsingDivContainer = ({ key, className = '', defaultValue = true, header, content, ref }) => {
  return (
    <CollapsingDivProvider key={key} defaultValue={defaultValue} content={content}>
      <CollapsingDiv className={className} header={header} content={content} ref={ref} />
    </CollapsingDivProvider>
  );
};

export default CollapsingDivContainer;
