
import { useNavigate, useParams } from "react-router";
import { useBackendApi } from "./backendHelper";
import { useGlobalState } from "../state/GlobalStateProvider";
import utils from "../utils";


export const useCommunityHelper = () => {
  const [{ user }] = useGlobalState();
  const backendApi = useBackendApi();
  const navigate = useNavigate();
  const { communityName } = useParams();

  const createCommunity = async () => {
    if (!user) return alert.error("Not logged in");

    alert.info("Creating your community");
    const name = utils.getElementValue("id.input.onboarding.createCommunity.name")?.trim();
    const displayName = utils.getElementValue("id.input.onboarding.createCommunity.dispalyName")?.trim();

    if (!name) return alert.error("Community domain required");
    if (!displayName) return alert.error("Community name required");

    if (name.length < 4) return alert.error("Community name should be atleast 4 letters");

    try {
      const communityExists = await backendApi.communities.get(name , true);
      if (communityExists) {
        alert.error("Community already exists, please choose another name", { autoClose: 15000 });
        return;
      }
    } catch (e) {
      console.error(`communityExists check `, e);
    }

    try {
      const res = await backendApi.communities.create({ name, displayName });
      if (res) {
        alert.success("Your community has been created!");
        navigate(`/${name}`);
      } else {
        alert.error("Could not create community");
      }
    } catch (e) {
      console.error(e);
      alert.error("Could not create community");
      return;
    }
  };

  const getCommunity = async () => {
    try {
      const community = await backendApi.communities.get(communityName);
    
      alert.success("community updated");
      return community || null;
    } catch (error) {
      alert.error("could not get community");
      
    }
  };

  return { createCommunity, getCommunity };
};

export default {
  useCommunityHelper,
};
