import { Abstraxion, useModal as useAbstraxtionModal } from '@burnt-labs/abstraxion';
import React from 'react';
import ButtonApi from '../../../../components/ButtonApi';
import LoginButton from '../../../../components/login/LoginButton';
import { useCosmos } from '../../../../helpers/cosmosHelper';
import { useGlobalState } from '../../../../state/GlobalStateProvider';

const ConnectWallet = () => {
  const [showAbstraxion, setShowAbstraxion] = useAbstraxtionModal();
  const [{ user }] = useGlobalState();
  const [{ isConnected, getUserSignAndVerify, handleClose }] = useCosmos();

  if (!user) return <LoginButton />;
  return (
    <>
      {!isConnected ? (
        <ButtonApi className='primary' onClick={() => setShowAbstraxion(true)} isLoading={showAbstraxion} key='xion-connect-btn'>
          Connect Cosmos Wallet
        </ButtonApi>
      ) : (
        <ButtonApi className='primary' onClick={getUserSignAndVerify} key='xion-login-btn'>
          Login with Xion
        </ButtonApi>
      )}
      <Abstraxion onClose={handleClose} />
    </>
  );
};

export default ConnectWallet;
