import { createContext, useMemo, useState } from 'react';
import { useBackendApi } from '../../../helpers/backendHelper';
import utils from '../../../utils';
import { useEvent } from './EventProvider';

export const pfpBuilderContext = createContext();

const PfpBuilderProvider = ({ children }) => {
  const [{ communityName, event, nftProfile, token, refreshNftProfile, setIsEdit }] = useEvent();
  const backendApi = useBackendApi();
  const [activeCategory, setActiveCategory] = useState();
  const [selectedItems, setSelectedItems] = useState({});

  const { allItems, categories, categoriesMap, inventory } = useMemo(() => {
    if (!nftProfile?.properties?.XP) return {};

    const xpClaims = nftProfile?.properties?.XP?.claims || {};
    const currentEquipped = new Set(nftProfile?.properties?.equipped || []);

    const inventory = {};
    const selectedItems = {};

    Object.keys(xpClaims).forEach(claimEventId => {
      xpClaims[claimEventId].attested.message.claims?.forEach(r =>
        r?.items?.forEach(item => {
          if (item.type != 'inventoryLayer') return;
          if (currentEquipped.has(item.id)) selectedItems[item.value] = item;
          inventory[item.id] = item;
        }),
      );
    });

    const categoriesMap = {};
    const allItems = [];

    const _addRewardItem = v => {
      if (!categoriesMap[v.description]) categoriesMap[v.description] = [];
      categoriesMap[v.description].push(v);
      allItems.push(v);
    };

    event.events.forEach(task => {
      task.rewards[1]?.items?.forEach(_addRewardItem);
      // some recurring tasks have different structures so update accordingly
      task[task.type]?.assets?.rules?.forEach(rule => {
        if (!rule.items) return;
        rule.items.forEach(_addRewardItem);
      });
    });

    setSelectedItems(selectedItems);

    return {
      allItems,
      categories: Object.keys(categoriesMap).sort((a, b) => categoriesMap[a]?.[0]?.value - categoriesMap[b]?.[0]?.value),
      categoriesMap,
      inventory,
    };
  }, [nftProfile?.properties?.XP]);

  const equip = item => {
    if (item.type != 'inventoryLayer') return;
    const _selectedItems = { ...selectedItems };

    const itemId = utils.bsonIdToBytes12(item.id);
    const isSelected = _selectedItems[item.value]?.id == itemId;
    if (isSelected) {
      delete _selectedItems[item.value];
    } else {
      _selectedItems[item.value] = { ...item, id: itemId };
      // handle incompatible layers
      item.incompatible?.forEach(layerIndex => delete _selectedItems[layerIndex]);
    }
    setSelectedItems(_selectedItems);
  };

  const save = () => {
    const promise = backendApi.communities
      .savePfpImage(communityName, token.address, {
        tokenId: token.tokenId,
        rewardItemIds: Object.values(selectedItems).map(item => item.id),
      })
      .then(refreshNftProfile)
      .then(() => setIsEdit(false))
      .then(() => 'NFT saved')
      .catch(e => {
        throw e;
      });

    alert.promise(promise, {
      pending: 'Saving NFT',
      success: { render: ({ data }) => data },
      error: { render: ({ data }) => data?.message || 'Could not save NFT' },
    });
  };

  const cancel = () => {
    setIsEdit(false);
  };

  return (
    <pfpBuilderContext.Provider
      value={{
        allItems,
        inventory,
        selectedItems,
        categories,
        categoriesMap,
        activeCategory,
        setActiveCategory,
        equip,
        save,
        cancel,
      }}
    >
      {children}
    </pfpBuilderContext.Provider>
  );
};

export default PfpBuilderProvider;
