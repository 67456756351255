import { types } from '../../constants';
import { useEmailLoginHelper } from '../../helpers/login/emailLoginHelper';
import utils from '../../utils';
import { useModal } from '../modal/ModalContext';
import './EmailPanel.scss';

const EmailPanel = ({ postLoginAction = types.postLoginActions.none }) => {
  const { loginInvasive } = useEmailLoginHelper();
  const [{ closeModal }] = useModal();

  const sendMagicLink = async () => {
    const email = utils.getElementValue('input.emailPanel')?.trim();
    if (!email || !utils.isValidEmail(email)) return alert.error('Invalid email');

    try {
      await loginInvasive({ email, postLoginAction });
      closeModal();
    } catch (e) {}
  };

  return (
    <>
      <h2>AUTHENTICATE YOURSELF</h2>
      <h1>Email Verification</h1>
      <input id='input.emailPanel' placeholder='Enter your email' />
      <footer>
        <button className='primary' onClick={sendMagicLink}>
          Send verification link
        </button>
      </footer>
    </>
  );
};

export default EmailPanel;
