import { useEffect } from 'react';
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import './App.scss';
import MbadgeClaim from './components/Mbadge/Claim/MbadgeClaim';
import { ReferralTracker } from './components/ReferralTracker';
import Footer from './components/footer/Footer';
import OauthFailed from './components/login/OauthFailed';
import { useModal } from './components/modal/ModalContext';
import Navbar from './components/navbar/Navbar';
import { CosmosEventProvider } from './helpers/cosmosHelper';
import { useOauthHelper } from './helpers/login/oauthHelper';
import NotFound404 from './pages/NotFound404';
import AdminContainer from './pages/admin/AdminContainer';
import AdminVerifyTaskSubmissions from './pages/admin/AdminVerifyTaskSubmissions/AdminVerifyTaskSubmissions';
import AdminAddEvent from './pages/admin/addEvent/AdminAddEvent';
import AdminSettings from './pages/admin/adminSettings';
import AdminIpfsFileUploader from './pages/admin/adminUploader/AdminIpfsFileUploader';
import AdminCreateCollection from './pages/admin/collection/adminCollection';
import CommunityCreate from './pages/admin/communityCreate/communityCreate';
import EventAnalytics from './pages/admin/eventAnalytics/EventAnalytics';
import Graph from './pages/analytics/graph';
import AllEvents from './pages/dashboard/AllEvents';
import Dashboard from './pages/dashboard/Dashboard';
import { Mbadges } from './pages/dashboard/Mbadges';
import Event from './pages/events/event/Event';
import Raffle from './pages/events/raffle/Raffle';
import Raffles from './pages/events/raffle/Raffles';
import Referrals from './pages/referals/Referals';
import Me from './pages/user/Me';
import Settings from './pages/user/Settings';
// import { Abstraxion } from '@burnt-labs/abstraxion';
// import EventAnalytics from "./pages/admin/eventAnalytics/EventAnalytics";

export const AppProvider = () => {
  const [{ openModal }] = useModal();
  const oauthHelper = useOauthHelper();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const init = async () => {
      const oauthData = searchParams?.get('oauthData');
      const postLoginAction = searchParams?.get('action');
      const oauthError = searchParams?.get('error');

      if (oauthData) {
        try {
          await oauthHelper.getLoginCookies(oauthData);
        } catch (e) {}
      }

      if (oauthData || postLoginAction || oauthError) navigate(location?.pathname, true);

      if (oauthError)
        openModal({
          className: 'oauthFailed-container',
          content: <OauthFailed oauthError={oauthError} />,
        });
    };

    init().catch(e => {});
  }, []);

  return (
    <div className='App'>
      <Navbar />
      <Outlet />
      <Footer />
    </div>
  );
};

function RedirectToEvent() {
  const { communityName } = useParams();
  return <Navigate to={`/${communityName}/event`} replace />;
}

const App = () => {
  return (
    <Routes>
      <Route element={<AppProvider />}>
        <Route path='/' element={<Dashboard />} />
        <Route path='/mbadges' element={<Mbadges />} />
        <Route path='/allevents' element={<AllEvents />} />
        <Route path='/campaignType/mbadge' element={<Navigate replace to='/mbadges' />} />
        <Route path=':communityName' element={<RedirectToEvent />} />
        <Route path='/c/create' element={<CommunityCreate />} />
        {/* <Route path='/ognfttest' element={<OgNftPage />} /> */}
        {/* todo: redirect to communityName */}

        <Route path=':communityName/event' element={<Event />} />

        {/* todo: redirect to communityName */}
        <Route path=':communityName/events' element={<Event />} />
        <Route path='/raffles' element={<Raffles columns={2} className={'raffles-page'} />} />
        <Route path='/raffles/:raffleName' element={<Raffle />} />
        <Route path='/me' element={<Me />} />
        <Route path='/rewards' element={<Raffle />} />
        <Route path='/claim' element={<MbadgeClaim />} />
        <Route
          path='/settings'
          element={
            <CosmosEventProvider>
              <Settings />
            </CosmosEventProvider>
          }
        />
        <Route path='/r/:code' element={<ReferralTracker />} />
        <Route path='/referral' element={<Referrals />} />

        <Route element={<AdminContainer />} path='/:communityName/admin'>
          <Route path='/:communityName/admin/analytics' element={<Graph />} />
          <Route path='/:communityName/admin/settings' element={<AdminSettings />} />
          <Route path='/:communityName/admin/createCollection' element={<AdminCreateCollection />} />
          <Route path='/:communityName/admin/verifyTaskSubmissions' element={<AdminVerifyTaskSubmissions />} />
          <Route path='/:communityName/admin/eventAnalytics' element={<EventAnalytics />} />
          <Route path='/:communityName/admin/addEvent' element={<AdminAddEvent />} />
          <Route path='/:communityName/admin/rewardItemHelper' element={<AdminIpfsFileUploader />} />
        </Route>
        <Route path='*' element={<NotFound404 />} />
      </Route>
    </Routes>
  );
};

export default App;
