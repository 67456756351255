import React from 'react';
import './Footer.scss';
const Footer = () => {
  return (
    <div className='footer_container'>
      <div className='social_links'>
        <a href='https://twitter.com/0xmercle' target='_blank'>
          Twitter
        </a>
        <a href='https://backend.mercle.xyz/misc/mercleDiscordInvite' target='_blank'>
          Discord
        </a>
      </div>
      <div className='footer_line'>Made with ❤️ by Mercle intern</div>
    </div>
  );
};

export default Footer;
