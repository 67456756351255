import { useLocation } from 'react-router-dom';
import { apis, dateMillis, id } from '../constants';
import { _getVanillan, _post, get, post, put, putFormData } from './fetchHelper';

export const useBackendApi = () => {
  const location = useLocation();
  // paths are not being resolved by useParams where context is not inside route components
  const communityName = location.pathname.split('/')?.[1];

  const cacheRequest = async (storageId, api, expireMillis = 6 * 60 * 60 * 1000, resetCache) => {
    let cache = JSON.parse(localStorage.getItem(storageId));
    const currentTime = Date.now();
    if (!cache?.data || cache?.expireAt < currentTime || resetCache) {
      cache = { data: await api() };
      cache.expireAt = currentTime + expireMillis;
      localStorage.setItem(storageId, JSON.stringify(cache));
    }
    return cache.data;
  };

  return {
    login: async body => post(apis.backend.login(), body),
    logout: async () => _post(apis.backend.logout()),
    refreshAccessToken: async () => post(apis.backend.refreshAccessToken()),
    getAccessTokenForWallet: async body => _post(apis.backend.login(), body),
    getCookies: async body => post(apis.backend.loginGetCookies(), body),
    getLoginState: async body => post(apis.backend.getLoginState(), body),

    getAllBanners: async () => cacheRequest('banner', () => get(apis.backend.get_allBanners())),
    getAnalyticsData: async body => post(apis.backend.getAnalyticsData(), body),

    user: {
      getIdentityClaims: async () => get(apis.backend.getUserIdentityClaims()),
      disconnectAccount: async body => post(apis.backend.disconnectAccount(), body),
      getUserStats: async () => cacheRequest(id.storage.userStats, () => get(apis.backend.getUserStats()), dateMillis.hour_1),
      getUserActivity: async () => get(apis.backend.getUserActivity()),
      setUserName: async body => post(apis.backend.setUserName(), body),
      checkUserName: async username => get(apis.backend.checkUserName(username)),
      getReferralCode: async url => get(apis.backend.getReferralCode(url)),
      getReferralStats: async () => get(apis.backend.getReferralStats()),
      getClaimPayload: async currency => get(apis.backend.getClaimPayload(currency)),
      useReferralCode: async body => post(apis.backend.useReferralCode(), body),
      getReferralRedirectUrl: async code => get(apis.backend.getReferalRedirectUrl(code)),
      getNftProfiles: async () => cacheRequest(id.storage.nftProfiles, () => get(apis.backend.getNftProfiles()), dateMillis.hour_1),
      addMewPointEntry: async data => post(apis.backend.addMewPointEntry(), data),
      getLatestMewPointEntry: async data => get(apis.backend.getLatestMewPointEntry(data)),
      getTotalMewPoints: async data => get(apis.backend.getTotalMewPoints(data)),
      getOgNftEligibilityData: async () => get(apis.backend.getOgNftEligibilityData()),
      getOgTrackingData: async () => get(apis.backend.OgTrackingData()),
      postOgTrackingData: async body => post(apis.backend.OgTrackingData(), body),
      trackExtraReferral: async (code, body) => post(apis.backend.trackExtraReferral(code), body),
      getAllHOSStats: async () => get(apis.backend.getAllHOSStats()),
      getHOSCommunity: async level => get(apis?.backend?.getHOSCommunityData(level)),
    },
    cosmos: {
      getXionTokenId: async (tokenAddress , xionAddress) => get(apis.backend.xionTokenId(tokenAddress , xionAddress)),
    },

    communities: {
      get: async (communityName, resetCache) => cacheRequest(id.storage.community(communityName), () => get(apis.backend.getCommunity(communityName)), null, resetCache),
      getTopCommunities: async (tag, skip, limit, getAll) =>
        cacheRequest(`topCommunities:${tag}:${skip}:${limit}:${getAll}`, () => get(apis.backend.topCommunities(tag, skip, limit, getAll))),
      create: async body => post(apis.backend.post_createCommunity(), body),
      getForTokens: async tokens => post(apis.backend.get_communitiesForTokens(), { tokens }),
      updateAdmins: async body => put(apis.backend.put_updateAdmins(communityName), body),
      uploadFileAdmin: async fd => putFormData(apis.backend.put_uploadFileAdmin(communityName), fd),
      updateLinks: async body => put(apis.backend.put_updateLinks(communityName), body),
      // event
      getEvent: async communityName => cacheRequest(id.storage.event(communityName), () => get(apis.backend.getEvents(communityName))),
      getAllEvents: async (skip, limit) => cacheRequest(`${id.storage.allEvents}_limit:${limit}_skip:${skip}`, () => get(apis.backend.allEvents(skip, limit))),
      savePfpImage: (communityName, tokenAddress, body) => post(apis.backend.savePfpImage(communityName, tokenAddress), body),
      verifyTask: (communityName, tokenAddress, body) => {
        let task = body?.claim?.event;
        if (task.type === 'connectTwitter' || task.type === 'twitterRetweet') {
          return new Promise((resolve, reject) => {
            post(apis.backend.verifyTask(communityName, tokenAddress), body).catch(e => console.log(e));

            let timoutPeriod = Math.floor(Math.random() * (180 - 150 + 1) + 150) * 1000;
            localStorage.setItem(task.id, JSON.stringify(Date.now() + timoutPeriod));
            setTimeout(() => {
              resolve(true);
            }, [timoutPeriod]);
          });
        } else {
          return post(apis.backend.verifyTask(communityName, tokenAddress), body);
        }
      },

      // campaign
      getCampaign: async (communityName, tokenAddress, campaignName) =>
        cacheRequest(id.storage.event(campaignName), () => get(apis.backend.getCampaign(communityName, tokenAddress, campaignName))),
      getNftProfile: async (communityName, tokenAddress, tokenId) => get(apis.backend.getNftProfile(communityName, tokenAddress, tokenId)),
      getOffchainTokenDetails: async (communityName, tokenAddress) => get(apis.backend.getOffchainTokenDetails(communityName, tokenAddress)),
      getOnchainTokenDetails: async (communityName, tokenAddress, chainId) => get(apis.backend.getOnChainTokenDetails(communityName, tokenAddress, chainId)),
      getReferralCommunityData: async referralCode => get(apis.backend.getReferralCommunityData(referralCode)),

      // mint nft
      mintToken: async (communityName, tokenAddress, campaignName, userAddress, username, referralCode, offChainToOnChain) =>
        get(apis.backend.getMintToken(communityName, tokenAddress, campaignName, userAddress, username, referralCode, offChainToOnChain)),

      // task actions
      discordAssignRole: (communityName, tokenAddress, body) => post(apis.backend.discordAssignRole(communityName, tokenAddress), body),
      postRedirectTaskConfirm: async (communityName, tokenAddress, body) => post(apis.backend.redirectTaskConfirm(communityName, tokenAddress), body),

      // admin
      trackTokenCreation: async (communityName, body) => post(apis.backend.trackTokenCreation(communityName), body),
      createOffchainToken: async (communityName, body) => post(apis.backend.createOffchainToken(communityName), body),

      //discord
      redirectToJoinDiscordUrl: (communityName, guildId, channelId) => get(apis.backend.get_redirectToJoinDiscordUrl(communityName, guildId, channelId)),
      discordBotIntegrationUrl: communityName => get(apis.backend.get_discordBotIntegrationUrl(communityName)),
      tokens: {
        initializeCollection: async (campaignName, body) => post(apis.backend.initializeCollection(communityName, campaignName), body),
        trackTokenCreation: async body => post(apis.backend.trackTokenCreation(communityName), body),
        createOffchainToken: async body => post(apis.backend.createOffchainToken(communityName), body),
        getCollection: async (tokenAddress, campaignName) => get(apis.backend.getCollection(communityName, tokenAddress, campaignName)),

        events: {
          get: async () => cacheRequest(id.storage.event(communityName), () => get(apis.backend.getEvents(communityName))),
          post: async body => post(apis.backend.getEvents(communityName), body),
          getQuiz: async (communityName, campaignName, quizId) => get(apis.backend.getQuiz(communityName, campaignName, quizId)),
          getQuizResult: async (communityName, campaignName, quizId) => get(apis.backend.getQuizResult(communityName, campaignName, quizId)),
          submitQuizAnswer: async (communityName, campaignName, body) => post(apis.backend.post_submitQuizAnswer(communityName, campaignName), body),
          getSubmissionTasks: async () => get(apis.backend.getSubmissionTasks(communityName)),
          verifySubmissionTasks: async body => put(apis.backend.verifySubmissionTasks(communityName), body),
          assignRole: (tokenAddress, body) => post(apis.backend.postAssignRole(communityName, tokenAddress), body),
          adminIssueReward: async (tokenAddress, body) => post(apis.backend.post_adminIssueReward(communityName, tokenAddress), body),
        },
        adminAnalytics: {
          getUserStats: async (tokenAddress, searchText, page, refreshCache = false, exportCsv = false) =>
            get(apis.backend.getUserStats(communityName, tokenAddress, searchText, page, refreshCache, exportCsv)),
          getMintStats: async tokenAddress => get(apis.backend.getMintStats(communityName, tokenAddress)),
          getTasksDoneStats: async tokenAddress => get(apis.backend.getTasksDoneStats(communityName, tokenAddress)),
          getTasksDone: async (tokenAddress, searchText, page, refreshCache, exportCsv = false) =>
            get(apis.backend.getTasksDone(communityName, tokenAddress, searchText, page, refreshCache, exportCsv)),

          getTasksSummary: async tokenAddress => get(apis.backend.getTasksSummary(communityName, tokenAddress)),
          getTasksBreakdown: async (tokenAddress, taskId, page = 0) => get(apis.backend.getTaskBreakdown(communityName, tokenAddress, taskId, page)),
          getUsersSummary: async (tokenAddress, body) => post(apis.backend.getUsersSummary(communityName, tokenAddress), body),
          getUsersBreakdown: async (tokenAddress, tokenId, userAddress, page = 0) => get(apis.backend.getUserBreakdown(communityName, tokenAddress, tokenId, userAddress, page)),
        },
      },
      discord: {
        discordBotIntegrationUrl: () => get(apis.backend.get_discordBotIntegrationUrl(communityName)),
        getGuildChannels: () => get(apis.backend.get_guildChannels(communityName)),
        getDefaultJoinChannel: () => get(apis.backend.get_defaultJoinChannel(communityName)),
        setDefaultJoinChannel: body => post(apis.backend.post_defaultJoinChannel(communityName), body),
        redirectToJoinDiscordUrl: (guildId, channelId) => get(apis.backend.get_redirectToJoinDiscordUrl(communityName, guildId, channelId)),
      },
      misc: {
        uploadFile: async fd => putFormData(apis.backend.put_uploadFileV2(), fd),
        getFileType: async fileUrl => get(apis.backend.get_fileType(fileUrl)),
        updatePowerloomEmail: async oldEmail => post(apis.backend.post_updatePowerloomEmail(oldEmail)),
        // metaTxn: {
        //   biconomy: {
        //     isEnabled: () => get(apis.backend.get_isBiconomyEnabled(communityName)),
        //     getApiKey: () => get(apis.backend.get_biconomyApiKey(communityName)),
        //   },
        // },
        mercleDiscordInvite: async () => get(apis.backend.getMercleDiscordInvite()),
        getAnnouncement: async () => get(apis.backend.getAnnouncement()),
      },
      testing: {
        restMyNftProfile: async (tokenAddress, body) => post(apis.backend.test.resetMyNftProfile(communityName, tokenAddress), body),
        deleteNftProfile: async (tokenAddress, body) => post(apis.backend.test.deleteMyNftProfile(communityName, tokenAddress), body),
      },
    },
    raffle: {
      getAllRaffle: async () => get(apis.backend.get_allRaffle()),
      getRaffles: async (isLive, skip, limit, communityName) =>
        cacheRequest(`raffles:${isLive}:${skip}:${limit}${communityName ? `:${communityName}` : ''}`, () => get(apis.backend.get_raffles(isLive, skip, limit, communityName))),
      getSingleRaffle: async raffleName => get(apis.backend.get_singleRaffle(raffleName)),
      getRaffleTaskCompleteStatus: async raffleName => get(apis.backend.get_raffleTaskCompleteStatus(raffleName)),
      getRaffleTicketForUser: async raffleName => get(apis.backend.get_raffleTicketForUser(raffleName)),
      postRaffleEntry: async (raffleName, body = {}) => post(apis.backend.post_raffleEntry(raffleName), body),
      getRaffleEntriesIPFS: async raffleName => get(apis.backend.get_raffleEntriesIFPS(raffleName)),
      pickWinner: async (raffleName, txnHash) => post(apis.backend.post_rafflePickWinner(raffleName, txnHash)),
      claimReward: async raffleName => get(apis.backend.get_raffleClaimReward(raffleName)),
    },
  };
};

export const useGelatoApi = () => {
  return {
    getTxn: taskId => _getVanillan(apis.gelato.getTxn(taskId)),
  };
};
