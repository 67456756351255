import "./component.scss";

const Input = (props) => {
  return (
    <div className={`input-container ${props.className}`}>
      {props.hideLabel ? <></> : <div className="label">{props.label}</div>}
      {props.type == "area" ? <textarea {...props} /> : <input {...props} />}
    </div>
  );
};

export default Input;
