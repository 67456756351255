import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Grid from '../../components/Grid';
import { CardsLoader } from '../../components/Skeleton/CardsLoader';
import { useBackendApi } from '../../helpers/backendHelper';
import './EasyTasks.scss';

const EasyTaskCard = ({ data }) => {
  return (
    <Link className='EasyTaskCard-container card' to={`${data?.community?.name}/event/#${data?.top?.taskId || ''}`}>
      <img src={data.community?.avatar || data.top?.image} />
      <div className='content'>
        <h1 className='ellipse'>{data.top.title || data.event.name}</h1>
        <p>{data?.event?.overview?.tags?.length} Reward</p>
      </div>
    </Link>
  );
};

const EasyTasks = ({}) => {
  const backendApi = useBackendApi();
  const [data, setData] = useState();

  useEffect(() => {
    backendApi.communities
      .getTopCommunities('task', 0, 16)
      .then(setData)
      .catch(() => setData(null));
  }, []);

  if (data === undefined) return <CardsLoader rows={2} columns={5} columnHeight={128} />;
  if (!data?.top?.length) return <></>;
  return (
    <Grid className='EasyTasks-container no-scrollbar' title='Easy Tasks' columns={5} rows={2} minWidth={200}>
      {data?.top?.slice(0, 16)?.map((top, i) => (
        <EasyTaskCard
          key={i}
          data={{
            top: top,
            community: data.communities[top.commId],
            event: data.events[top.commId],
            campaign: data.campaigns[top.commId],
          }}
        />
      ))}
    </Grid>
  );
};

export default EasyTasks;
