import { useEffect, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import AnimateComponentTriggered from '../AnimateComponentTriggered';
import Icons from '../icons/Icons';
import './Modal.scss';
import { useModal } from './ModalContext';

const Modal = () => {
  const [{ isOpen, contentConfig, closeModal, clearModal }] = useModal();
  const [bottomSwip, setBottomSwip] = useState(0);
  const animeDuration = 200;
  const config = {
    delta: 10, // min distance(px) before a swipe starts. *See Notes*
    preventScrollOnSwipe: false, // prevents scroll during swipe (*See Details*)
    trackTouch: true, // track touch input
    trackMouse: true, // track mouse input
    rotationAngle: 0, // set a rotation angle
    swipeDuration: Infinity, // allowable duration of a swipe (ms). *See Notes*
    touchEventOptions: { passive: true }, // options for touch listeners (*See Details*)
  };
  const handlers = useSwipeable({
    onSwiped: () => {
      if (bottomSwip < -30) {
        closeModal();
        setTimeout(() => {
          setBottomSwip(0);
        }, animeDuration + 100);
      } else {
        setBottomSwip(0);
      }
    },
    onSwiping: eventData => {
      setBottomSwip(Math.min(-eventData?.deltaY, 0));
    },
    ...config,
  });
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
    return () => {
      document.body.classList.remove('modal-open');
    };
  }, [isOpen]);

  return (
    <AnimateComponentTriggered
      className={`modal-container-backdrop ${contentConfig?.hideNavbar ? 'hideNavbar' : ''}`}
      animationDurationMillis={animeDuration}
      defaultState={isOpen}
      enterAnimation='backdropEnterOverlay'
      exitAnimation='backdropExitOverlay'
      onClick={closeModal}
      onExitComplete={clearModal}
      style={{ bottom: bottomSwip || 0 }}
    >
      <AnimateComponentTriggered
        className={`modal-container ${contentConfig.className}`}
        enterAnimation={contentConfig.enterAnimation}
        exitAnimation={contentConfig.exitAnimation}
        animationDurationMillis={animeDuration}
        defaultState={isOpen}
      >
        {window.innerWidth < 800 ? (
          <button className='mobile-closeBtn' onClick={closeModal} {...handlers} />
        ) : (
          <button className='toolbar-closeBtn' onClick={closeModal}>
            <Icons icon={'close'} />
          </button>
        )}
        <div className={`modalContent-container ${contentConfig.classNameContent}`}>{contentConfig.content}</div>
      </AnimateComponentTriggered>
    </AnimateComponentTriggered>
  );
};

export default Modal;
