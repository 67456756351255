import { useEffect, useState } from "react";
import { ethers } from "ethers";

// import { deployed, resources, types } from "Constants";
// import { useEthersHelper } from "helpers/ethersHelper";
// import { useBackendApi } from "helpers/restApiHelper";
// import { useTokenCreation } from "helpers/tokenHelper";
// import { useCommunityHelper } from "helpers/communityHelper";
// import utils from "helpers/utils";

import "./adminCollection.scss";
import { useBackendApi } from "../../../helpers/backendHelper";
import { deployed, resources, types } from "../../../constants";
import utils from "../../../utils";
import { useGlobalState } from "../../../state/GlobalStateProvider";
import { useEthersHelper } from "../../../helpers/ethersHelper";
import { useCommunityHelper } from "../../../helpers/communityHelper";
import Input from "../components/Input";
import Dropdown from "../components/Dropdown";
import { useTokenCreation } from "../../../helpers/tokenHelper";
import { useEvent } from "../../events/event/EventProvider";

// import Dropdown from "components/Dropdown";
// import Input from "components/Input";

const ToggleSwitch = ({ label, initialValue, onToggleChange, campaignName }) => {
  const [isChecked, setIsChecked] = useState(initialValue);

  const handleChange = () => {
    const newCheckedState = !isChecked;
    setIsChecked(newCheckedState);
    if (onToggleChange && campaignName) {
      onToggleChange(campaignName, newCheckedState, label);
    }
  };
  useEffect(() => {
    setIsChecked(initialValue);
  }, [initialValue, label]);

  return (
    <div className="toggle-container">
      <h1>{label}</h1>
      <label className="switch">
        <input type="checkbox" checked={isChecked} onChange={handleChange} />
        <span className="slider round"></span>
      </label>
    </div>
  );
};

const fetchCampaignData = async (backendApi, tokenAddr, campaign) => {
  try {
    return await backendApi.communities.tokens.getCollection(tokenAddr, campaign);
  } catch (e) {
    console.error(e);
    return null;
  }
};

const AddOtpWhitelist = () => {
  const backendApi = useBackendApi();
  const [campaignNameOptions, setCampaignNameOptions] = useState([]);
  const [hasEnded, setHasEnded] = useState(false);
  const [isEmailOtp, setIsEmailOTP] = useState(false);
  const [requireEmail, setRequireEmail] = useState(false);
  const [isSponsored, setIsSponsored] = useState(false);
  const getCampaigns = async () => {
    try {
      const eventsPageData = await backendApi.communities.tokens.events.get();
      const { campaign, tokenAddr } = eventsPageData?.events?.[0];
      if (!campaign && !tokenAddr) return alert.error("could not fetch campaign");

      const campaignData = await fetchCampaignData(backendApi, tokenAddr, campaign);
      if (campaignData) {
        setCampaignNameOptions([
          {
            label: campaignData.name,
            value: campaignData.name,
            hasEnded: campaignData.hasEnded,
            isEmailOtp: campaignData.isEmailOtp,
            requireEmail: campaignData.requireEmail,
            isSponsored: campaignData.isSponsored,
          },
        ]);
        setHasEnded(campaignData.hasEnded);
        setIsEmailOTP(campaignData.isEmailOtp);
        setRequireEmail(campaignData.requireEmail);
        setIsSponsored(!campaignData.isSponsored);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getCampaigns();
  }, []);

  const handleToggleChange = async (campaignName, isActive, toggleValue) => {
    const toggleActionMap = {
      hasEnded: setHasEnded,
      isEmailOtp: setIsEmailOTP,
      requireEmail: setRequireEmail,
      isSponsored: setIsSponsored,
    };
    try {
      const updateState = toggleActionMap[toggleValue];
      if (updateState) {
        updateState(isActive);
        await backendApi.communities.tokens.updateCampaignData(campaignName, {
          [toggleValue]: isActive,
        });
        alert.success("Update successful", toggleValue);
      } else {
        console.error("Unknown toggleValue:", toggleValue);
      }
    } catch (e) {
      console.error("[handleToggleChange] Error updating campaign status:", e);
      alert.error("Update failed", e.message);
    }
  };

  const addOtpWhitelist = async () => {
    try {
      const emails = utils
        .getElementValue("id.input.admin.addOtpWhitelist.otpWhitelistEmails")
        ?.trim()
        ?.split("\n")
        ?.map((v) => v.trim());
      const campaignName = utils.getElementValue("id.input.admin.addOtpWhitelist.campaginName", "selected");
      if (!emails.length) return alert.error("Emails required");
      if (!campaignName) return alert.error("Campaign Name required");

      alert.info("Adding Emails");
      await backendApi.communities.tokens.addMintOtpWhitelist(campaignName, { emails });
      alert.success("Emails added");
    } catch (e) {
      console.error(e);
      alert.error("Could not add Emails");
    }
  };
  const addMintFeeConfig = async () => {
    const campaignName = campaignNameOptions[0].value;
    try {
      const mintFeeConfigAmount = utils.getElementValue("mintFeeConfigAmount");
      const mintFeeConfigTokenAddress = utils.getElementValue("mintFeeConfigTokenAddress");
      const mintFeeConfigTokenChainId = utils.getElementValue("mintFeeConfigTokenChainId");
      const mintFeeConfigTokenDecimals = utils.getElementValue("mintFeeConfigTokenDecimals");
      const mintFeeConfigTresuryAddr = utils.getElementValue("mintFeeConfigTresuryAddr");
      alert.info("Adding Mint Fee Config");
      const res = {
        mintFeeConfig: {
          amount: parseFloat(mintFeeConfigAmount),
          tokenAddress: mintFeeConfigTokenAddress,
          chainId: parseInt(mintFeeConfigTokenChainId),
          decimals: parseInt(mintFeeConfigTokenDecimals),
          treasuryAddress: mintFeeConfigTresuryAddr,
        },
      };
      await backendApi.communities.tokens.updateCampaignData(campaignName, res);
      alert.success("Mint Fee Config added");
    } catch (e) {
      console.error(e);
      alert.error("Could not add Mint Fee Config");
    }
  };

  return (
    <section>
      <Input id="id.input.admin.addOtpWhitelist.otpWhitelistEmails" label="Whitelist Emails For Mint" type="area" />
      <Dropdown id="id.input.admin.addOtpWhitelist.campaginName" label="Campagin" options={campaignNameOptions} />
      <button
        onClick={() => {
          alert.info("Fetching Campaings");
          getCampaigns().then(() => alert.success("Campaings Fetch"));
        }}
      >
        Get Campaigns
      </button>
      <button onClick={addOtpWhitelist}>Add OTP Whitelist</button>

      <br />

      <Input id="mintFeeConfigAmount" label="Mint Fee Amount" />
      <Input id="mintFeeConfigTokenAddress" value={"native"} label="Mint Fee Token Address" />
      <Input id="mintFeeConfigTokenChainId" label="Mint Fee Token Chain Id" />
      <Input id="mintFeeConfigTokenDecimals" label="Mint Fee Token Decimals" />
      <Input id="mintFeeConfigTresuryAddr" label="Mint TreasuryAddress" />
      <br />

      <button onClick={addMintFeeConfig}>Add Mint Fee Config</button>
      <section>
        <ToggleSwitch
          label="hasEnded"
          initialValue={hasEnded}
          onToggleChange={handleToggleChange}
          campaignName={campaignNameOptions[0]?.value}
        />

        <ToggleSwitch
          label="isEmailOtp"
          initialValue={isEmailOtp}
          onToggleChange={handleToggleChange}
          campaignName={campaignNameOptions[0]?.value}
        />
        <ToggleSwitch
          label="requireEmail"
          initialValue={requireEmail}
          onToggleChange={handleToggleChange}
          campaignName={campaignNameOptions[0]?.value}
        />
        <ToggleSwitch
          label="isSponsored"
          //if isSponsored is false then it will be displayed else isSponsored will not exist in the database
          initialValue={isSponsored}
          onToggleChange={handleToggleChange}
          campaignName={campaignNameOptions[0]?.value}
        />
      </section>
    </section>
  );
};

const AdminCreateCollection = () => {
  const backendApi = useBackendApi();
  const [{community} , setState]=useEvent()
 

  const { getUserAddress, getChainId, walletConnect, changeNetwork, getMembershipNFT } = useEthersHelper();
  const { createERC721 } = useTokenCreation();

  const [collectionDetails, setCollectionDetails] = useState();
  const [campaignDetails, setCampaignDetails] = useState();
  const [tokenAddress, setTokenAddress] = useState();

  const { getCommunity } = useCommunityHelper();

  useEffect(() => {
    

    const templateCollectionDetails = {
      template: {
        name: "LayeredImageCard",
        config: {
          imageUrlLayers: [],
        },
      },
    };

    const _collectionDetails = {
      name: `Everything Music onPolygon`,
      description: `Evolving nft for ${community?.displayName}`,
      symbol: `MUSICON`,
      quickInviteConfig: {
        isEnabled: false,
        count: 0,
      },
      isCampaign: true,
      isProfile: true,
      isOpenMint: false,
      isTradable: false,
      matchAny: true,
      whitelist: {},
      introConfig: {
        faq: {
          label: `What is Everything Music #onPolygon?`,
          values: [
            {
              image: `${resources.links.s3.getS3Link()}/public/icon.svg`,
              value: `Everything Music #onPolygon is a celebration of music on Polygon. It highlights web3 native artists, developers, tools, and communities that contribute to its vibrant scene.`,
            },
            {
              image: `${resources.links.s3.getS3Link()}/public/icon(1).svg`,
              value: `Complete the musical tasks and follow along the campaign to unlock XP and build your very own progressive musical SBT.`,
            },
            {
              image: `${resources.links.s3.getS3Link()}/public/icon(2).svg`,
              value: `With five different stages, take your SBT from lofi to rock star to unlock rewards!`,
            },
          ],
        },
        rewards: {
          label: "MEMBERS REWARDS OFFERED BY COMMUNITY",
          values: [
            {
              image: `${resources.links.s3.getS3Link()}/public/Glass+Left.svg`,
              value: "Gain early access, OG roles, and limited edition NFT",
            },
            {
              image: `${resources.links.s3.getS3Link()}/public/Glass+Left(1).svg`,
              value: "Get your music and NFTs promoted on official platforms ",
            },
            {
              image: `${resources.links.s3.getS3Link()}/public/Glass+Left(2).svg`,
              value: "Unlock SWAG, and access new features.",
            },
            {
              image: `${resources.links.s3.getS3Link()}/public/Glass+Left(3).svg`,
              value: "Own a share of songs or NFTs,",
            },
          ],
        },
        welcome: {
          label: `Celebrating Everything Music\n#onPolygon`,
          highlight: "Music",
          highlightColor:
            "background: linear-gradient(90.04deg, #BFB171 27.52%, #8BD67B 56.59%, #4EB2C9 69.12%, #C663C5 99.99%);",
          description: `"To ensure the highest level of security and authenticity, please connect with the wallet you used during registration. Verify your identity and get ready to rock the NFT music event like never before!"`,
        },
      },
      ...templateCollectionDetails,
    };

    setCollectionDetails(_collectionDetails);
  }, [community]);

  const uploadTemplateImageToIpfs = async () => {
    // await walletConnect();
    const imageFile = utils.getElementValue("inputImagePicker", "files")[0];
    if (!imageFile) return alert.error("please select file");

    const imageFd = new FormData();
    imageFd.append("file", imageFile);
    imageFd.append("name", "image");
    imageFd.append("task", types.fileUploadTask.nftResource);

    backendApi.communities.misc
      .uploadFile(imageFd)
      .then(({ filePath }) => {
        const url = utils.getFileUrl(filePath);
        const _collectionDetails = { ...collectionDetails };
        _collectionDetails.template.config.imageUrlLayers.push(url);
        setCollectionDetails(_collectionDetails);
        console.log("file uploaded", collectionDetails);
        alert.success("file uploaded");
      })
      .catch((e) => {
        console.error(e);
        alert.error("could not upload file");
      });
  };

  const setCollectionName = () => {
    const collectionName = utils.getElementValue("id.input.adminCreateCollection.collectionName");
    if (!collectionName) return;

    setCollectionDetails({ ...collectionDetails, name: collectionName });
  };
  const setCollectionSymbol = () => {
    const collectionSymbol = utils.getElementValue("id.input.adminCreateCollection.collectionSymbol");
    if (!collectionSymbol) return;

    setCollectionDetails({ ...collectionDetails, symbol: collectionSymbol });
  };
  const setCollectionDescription = () => {
    const collectionDescription = utils.getElementValue("id.input.adminCreateCollection.collectionDescription");
    if (!collectionDescription) return;

    setCollectionDetails({ ...collectionDetails, description: collectionDescription });
  };
  const setUsernameSuffix = () => {
    const usernameSuffix = utils.getElementValue("id.input.adminCreateCollection.usernameSuffix")?.trim();
    if (!usernameSuffix) return;
    if (usernameSuffix.indexOf(" ") > -1) return alert.error("spaces not allowed in username suffix");
    setCollectionDetails({ ...collectionDetails, usernameSuffix });
  };

  const setIntroFaq = () => {
    //todo: handle this
  };

  const initializeCollection = () => {
    if (collectionDetails.template && !collectionDetails.template?.config?.imageUrlLayers?.length)
      return alert.error("please upload image to ipfs");
    backendApi.communities.tokens
      .initializeCollection(collectionDetails.name, collectionDetails)
      .then((initializedDampaignDetails) => {
        setCampaignDetails(initializedDampaignDetails);
        alert.success("Campaign initialized");
      })
      .catch((e) => {
        alert.error("Could not initialize campaign");
        console.error(e);
      });
  };
  const deployCollection = () => {
    if (!campaignDetails) return alert.error("please initialize campaign first");
    createERC721({
      communityId: community._id,
      chainId: getChainId(),
      name: collectionDetails.name,
      symbol: collectionDetails.symbol,
      description: collectionDetails.description,
      walletDetails: { type: types.wallet.wallet, address: getUserAddress() },
      tokenType: types.token.erc721,
      isCampaign: true,
      isProfile: true,
      isOpenMint: collectionDetails.isOpenMint,
      isTradable: collectionDetails.isTradable,
      profileData: {
        matchAny: true,
        whitelist: [],
        campaign: campaignDetails,
      },
    })
      .then((tokenAddress) => {
        alert.success("collection deployed");
        
        setTokenAddress(tokenAddress);
        getCommunity().then((res)=>{
         setState((s)=>({...s,community:res }))
        })
      })
      .catch((e) => {
        console.error(e);
        alert.error("could not deploy collection" ,);
      });
  };

  const getCollection = async () => {
    try {
      const eventsPageData = await backendApi.communities.tokens.events.get();
      const { campaign, tokenAddr } = eventsPageData?.events?.[0];
      const collection = await fetchCampaignData(backendApi, tokenAddr, campaign);
      if (collection) {
        const { description, name, symbol, usernameSuffix } = collection;
        setCollectionDetails({ ...collection });
        setElementValue("id.input.adminCreateCollection.collectionName", name);
        setElementValue("id.input.adminCreateCollection.collectionSymbol", symbol);
        setElementValue("id.input.adminCreateCollection.collectionDescription", description);
        setElementValue("id.input.adminCreateCollection.usernameSuffix", usernameSuffix);
      }
      alert.success("collection fetched");
    } catch (error) {
      console.error(error);
      alert.error("could not fetch collection");
    }
  };

  const setElementValue = (id, value) => {
    if (!utils.getElementValue(id)) {
      utils.setElementValue(id, null, value);
    }
  };

  const createOffchainToken = () => {
    backendApi.communities.tokens
      .createOffchainToken({
        name: collectionDetails.name,
        symbol: collectionDetails.symbol,
        description: collectionDetails.description,
        creator: getUserAddress(),
        tokenType: types.token.offchain,
        isCampaign: true,
        isProfile: true,
        campaignId: campaignDetails._id,
      })
      .then(() => {
        alert.success("Offchain Token Created");
      })
      .catch((e) => {
        alert.error("Could not create offchain token");
        console.error(e);
      });
  };

  const createOffchainTokenMintAfterTask = () => {
    backendApi.communities.tokens
      .createOffchainToken({
        name: collectionDetails.name,
        symbol: collectionDetails.symbol,
        description: collectionDetails.description,
        creator: getUserAddress(),
        tokenType: types.token.offchain,
        isCampaign: true,
        isProfile: true,
        campaignId: campaignDetails._id,
      })
      .then(() => {
        alert.success("Offchain Token Created");
        if (!campaignDetails) return alert.error("please initialize campaign first");
        createERC721({
          communityId: community._id,
          chainId: getChainId(),
          name: collectionDetails.name,
          symbol: collectionDetails.symbol,
          description: collectionDetails.description,
          walletDetails: { type: types.wallet.wallet, address: getUserAddress() },
          tokenType: types.token.erc721,
          isCampaign: true,
          isProfile: true,
          isOpenMint: collectionDetails.isOpenMint,
          isTradable: collectionDetails.isTradable,
          profileData: {
            matchAny: true,
            whitelist: [],
            campaign: campaignDetails,
          },
          saveAs: "onChainToken",
        })
          .then((tokenAddress) => {
            alert.success("collection deployed");
            setTokenAddress(tokenAddress);
          })
          .catch((e) => {
            console.error(e);
            alert.error("could not deploy collection");
          });
      })
      .catch((e) => {
        alert.error("Could not create offchain token");
        console.error(e);
      });
  };

  const grantRole = async () => {
    const tokenAddress = utils.getElementValue("id.input.admin.claimIssuer.tokenAddress", "selected");
    const walletAddress = utils.getElementValue("id.input.admin.claimIssuer.walletAddress");
    const role = utils.getElementValue("id.input.admin.claimIssuer.role", "selected");

    if (!tokenAddress) return alert.error("tokenAddress required");
    if (!ethers.isAddress(walletAddress)) return alert.error("invalid walletAddress");
    if (!role) return alert.error("role required");

    const token = community.tokens[tokenAddress];

    const roleHash =
      role == "DEFAULT_ADMIN_ROLE"
        ? ethers.keccak256(ethers.toUtf8Bytes(""))
        : ethers.keccak256(ethers.toUtf8Bytes(role));

    await changeNetwork(token.chainId);

    const nftContract = getMembershipNFT(tokenAddress);

    try {
      alert.info("Granting role");
      const txn = await nftContract.grantRole(roleHash, walletAddress);

      console.log(txn);
      const receipt = await txn.wait();
      console.log(receipt);
      alert.success("Role granted");
    } catch (e) {
      console.error(e);
      alert.error("Could not grant role");
    }
  };
if (!community) return 
  return (
    <>
      <section>
        <input id="inputImagePicker" type="file" accept=".png,.jpg,.jpeg" />
        <button onClick={uploadTemplateImageToIpfs}>Upload Image to IPFS</button>
        <input id="id.input.adminCreateCollection.collectionName" />
        <button onClick={setCollectionName}>Set Collection Name</button>
        <input id="id.input.adminCreateCollection.collectionSymbol" />
        <button onClick={setCollectionSymbol}>Set Collection Symbol</button>
        <textarea id="id.input.adminCreateCollection.collectionDescription" />
        <button onClick={setCollectionDescription}>Set Collection Description</button>
        <input id="id.input.adminCreateCollection.usernameSuffix" />
        <button onClick={setUsernameSuffix}>Set Username Suffix</button>
        <br />
        <br />
        <button onClick={initializeCollection}>Initialize Collection</button>
        <br />
        <br />
        <button onClick={deployCollection}>Deploy Collection</button>
        <br />
        <br />
        <button onClick={getCollection}>Get Collection</button>
      </section>

      <section>
        <h1>Offchain Token</h1>
        <button onClick={createOffchainToken}>Create Offchain Collection</button>
        <h1>Mint after task</h1>
        <button onClick={createOffchainTokenMintAfterTask}>Create Mint after Collection</button>
      </section>

      <section>
        <h1>Add Claim Issuer</h1>
        <button onClick={getCommunity}>fetch community</button>
        <p>
          <b>Server Wallet Address: CLAIM_ISSUER_ROLE</b>
          <textarea value={JSON.stringify(deployed.mercle.ClaimIssuer, null, 4)} style={{ height: "140px" }} />
          <br />
          <b>Mercle Forwarder Address: MINTER_ROLE</b>
          <textarea value={JSON.stringify(deployed.mercle.Forwarder, null, 4)} style={{ height: "140px" }} />
          <br />
          <b>MintWithClaim : Address</b>
          <textarea value={JSON.stringify(deployed.MintWithClaim, null, 4)} style={{ height: "140px" }} />
        </p>
        <Dropdown
          id="id.input.admin.claimIssuer.tokenAddress"
          label="Token"
          options={Object.keys(community.tokens || {}).map((v) => ({
            label: `${community.tokens[v].chainId} - ${v}`,
            value: v,
          }))}
        />
        <Dropdown
          id="id.input.admin.claimIssuer.role"
          label="Role"
          options={[
            { label: "CLAIM_ISSUER_ROLE", value: "CLAIM_ISSUER_ROLE" },
            { label: "MINTER_ROLE", value: "MINTER_ROLE" },
          ]}
        />
        <Input label="Wallet Address" id="id.input.admin.claimIssuer.walletAddress" />
        <button onClick={grantRole}>Add Claim Issuer</button>
      </section>
      <AddOtpWhitelist />
    </>
  );
};

export default AdminCreateCollection;
