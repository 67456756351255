import { useEffect, useState, useContext } from "react";
import dayjs from "dayjs";

// import Address from "./Address";


import './component.scss'
import { useEthersHelper } from "../../../helpers/ethersHelper";
import { useModal } from "../../../components/modal/ModalContext";
import utils from "../../../utils";
import Address from "../../../components/Address";
import ButtonApi from "../../../components/ButtonApi";
import { useBackendApi } from "../../../helpers/backendHelper";
// import ApiButton from "./ApiButton";
// import { useEthersHelper } from "helpers/ethersHelper";

const Step1 = ({ data, closeModal, refreshData }) => {
  const ethersHelper = useEthersHelper();
  const backendApi = useBackendApi();
  const [userNft, setUserNft] = useState();

  useEffect(() => {
    if (!data) return;
    useEthersHelper
      .getNftProfile(data.userAddress, data.tokenAddr, data.chainId)
      .then((nftData) => {
        setUserNft(utils.getFileUrl(nftData?.metadata?.image));
      })
      .catch((e) => setUserNft(null));
  }, [data]);

  const onApprove = () => _onAction("VERIFIED");
  const onReject = () => _onAction("REJECTED");
  const _onAction = async (status) => {
    try {
      await backendApi.communities.tokens.events.verifySubmissionTasks({ submissionId: data._id, status });
      alert.success(`Task ${status}`);
      refreshData();
      closeModal();
    } catch (e) {
      console.error(e);
      alert.error("Something went wrong");
    }
  };

  const tdArray = ["Task Name", "Wallet Address", "Date", "Username", "Status"];
  return (
    <div>
      <table className="tableBox">
        <colgroup>
          <col className="col-200" />
          <col className="col-address" />
          <col />
          <col className="col-input" />
          <col className="col-date" />
        </colgroup>
        <thead>
          <tr>
            {tdArray.map((e) => (
              <td key={e}>{e}</td>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr key={data._id}>
            <td>{data?.eventName}</td>
            <td>
              <Address isAbsolute={true} a={data?.userAddress} />
            </td>
            <td>{dayjs(data?.cAt).format("DD MMM YYYY HH:mm")}</td>
            <td>{data?.userName}</td>
            <td>
              <span className="state">{data.status}</span>
            </td>
          </tr>
        </tbody>
      </table>
      <div className="infoBox">
        <div className="avatarBox">
          <img src={userNft} alt="" />
        </div>
        <div className="verifyList">
          {Object.keys(data?.data || {}).map((label) => (
            <div className="listItem">
              <h1>{label}</h1>
              <h2 className="detail">{data?.data?.[label]}</h2>
            </div>
          ))}
        </div>
      </div>
      <div className="actionBox">
        <ButtonApi onClick={onReject} className="actionButton reject" isRaw={true}>
          Reject
        </ButtonApi>
        <ButtonApi onClick={onApprove} className="actionButton approve" isRaw={true}>
          Approve
        </ButtonApi>
      </div>
    </div>
  );
};

const SubmitListPannel = ({ data, refreshData }) => {
    const [{closeModal}]=useModal()
  return (
    <div className="SubmitListPannel-container">
      <Step1 data={data} closeModal={closeModal} refreshData={refreshData} />
    </div>
  );
};

export default SubmitListPannel;
