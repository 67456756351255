import { useEffect, useState } from 'react';
import { CardsLoader } from '../../components/Skeleton/CardsLoader';
import SimpleSlider from '../../components/slider/SimpleSlider';
import { useBackendApi } from '../../helpers/backendHelper';
import './Banners.scss';

const Banner = ({ data }) => {
  return (
    <div className='Banner-container' style={{ backgroundImage: `url("${data.image}")` }}>
      <h1>{data.heading}</h1>
      <p>{data.subHeading}</p>
      <div className='fillter'> </div>
      <a className='button' href={data.link} target='_blank'>
        {data.buttonText}
      </a>
    </div>
  );
};

const Banners = () => {
  const backendApi = useBackendApi();
  const [banners, setBanners] = useState();

  useEffect(() => {
    backendApi
      .getAllBanners()
      .then(setBanners)
      .catch(err => {
        setBanners(null);
      });
  }, []);

  if (banners === undefined) return <CardsLoader rows={1} columns={1} columnHeight={300} />;
  if (!banners?.length) return <></>;
  return (
    <SimpleSlider className='Banners-container' slideGap={20}>
      {banners?.map((v, i) => (
        <Banner key={i} data={v} />
      ))}
    </SimpleSlider>
  );
};

export default Banners;
