import React, { useContext, useEffect, useState } from "react";
import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, BarChart, Bar, AreaChart, Area } from "recharts";
// import { backendApi, useBackendApi } from "helpers/restApiHelper";




// import HoverTooltip from "components/HoverTooltip";

import "./EventAnalytics.scss";

import dayjs from "dayjs";
import { useBackendApi } from "../../../helpers/backendHelper";
import Address from "../../../components/Address";
import { resources } from "../../../constants";
import utils from "../../../utils";
import { useGlobalState } from "../../../state/GlobalStateProvider";
import TableSearchApied from "../components/tableApied";
import EventProvider, { useEvent } from "../../events/event/EventProvider";

const ChartTooltip = ({ active, payload, label, labelSuffix = "", suffix = "user" }) => {
  const data = payload?.[0];
  return (
    <div className="ChartTooltip-container">
      <h2>
        {label}
        <span className="labelSuffix">{labelSuffix}</span>
      </h2>
      <h1>
        {data?.payload?.[data?.dataKey]}
        <span className="label">{suffix}</span>
      </h1>
    </div>
  );
};

const UserStatRow = ({ user, i, page, pageSize }) => {
  return (
    <tr key={`${user?.uAt}_${user?.tokenId}`}>
      <td className="td-small">{i + 1 + (page * pageSize || 0)}</td>
      <td className="td-small">
        <img className="useravatar" src={utils.getFileUrl(user?.metadata?.image)} />
      </td>
      <td>{user?.tokenId}</td>
      <td onClick={() =>  {
        navigator.clipboard.writeText(user?.address)
        alert("address Copied")
        }} style={{cursor: 'pointer'}} >{user?.address}</td>
      <td>{user?.metadata?.properties?.Level?.value}</td>
      <td>{user?.metadata?.properties?.XP?.value}</td>
      <td>{user?.metadata?.properties?.["De-Fi"]?.value}</td>
      <td>{user?.metadata?.properties?.Social?.value}</td>
      <td>{user?.metadata?.properties?.Community?.value}</td>
      <td>{dayjs(user?.uAt).format("DD MMM YYYY")}</td>
    </tr>
  );
};

const UserProperties = () => {
  const useUserStats = ({ searchText, page = 0, pageSize }) => {
   
    const [{ token:eventTokenDetails  , community}] = useEvent();
    const backendApi = useBackendApi();

    const [userStats, setUserStats] = useState();
    const [refreshCache, setRefreshCache] = useState(false);

    useEffect(() => {
      if (!eventTokenDetails?.address) return;
    }, [eventTokenDetails]);

    // gets user xp and level details
    const getUserStats = (exportCsv) => {
      if (!eventTokenDetails?.address) return;
      backendApi.communities.tokens.adminAnalytics
        .getUserStats(eventTokenDetails.address, searchText, page, refreshCache, exportCsv)
        .then(setUserStats)
        .catch((e) => console.error(e));
    };

    const exportCsv = () => {
      alert.promise(
        backendApi.communities.tokens.adminAnalytics
          .getUserStats(eventTokenDetails.address, searchText, page, refreshCache, true)
          .then((data) => {
            // hack to download the file. fetch doesn't automatically download the file
            const url = window.URL.createObjectURL(new Blob([JSON.stringify(data)], { type: "application/json" }));
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            a.download = `${community.name}_${Date.now()}`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          }),
        {
          pending: "Exporting Data",
          success: "Data exported",
          error: "Could not export data",
        }
      );
    };

    useEffect(() => {
      getUserStats();
    }, [eventTokenDetails, searchText, page]);

    return {
      users: userStats?.users,
      totalCount: userStats?.totalCount || 0,
      exportCsv,
    };
  };

  return (
    <>
      <h1>NFT Details</h1>
      <TableSearchApied
        className="UserProperties-container"
        searchPlaceholder="XP>=20, Level>=1"
        idInputSearch={"id.input.admin.userProperties.inputSearch"}
        renderToolbarRightPanel={({ dataHookRef }) => <button onClick={dataHookRef?.exportCsv}>Export</button>}
        theadRows={
          <>
            <th className="td-small">#</th>
            <th className="td-small">Avatar</th>
            <th>Token Id</th>
            <th>Address</th>
            <th>Level</th>
            <th>XP</th>
            <th>De fi</th>
            <th>Social</th>
            <th>Community</th>
            <th>Last Updated</th>
          </>
        }
        renderRow={({ item, i, page, pageSize }) => <UserStatRow user={item} i={i} page={page} pageSize={pageSize} />}
        pageSize={10}
        hookConfig={{
          useDataHook: useUserStats,
          rowsKey: "users",
          totalCountKey: "totalCount",
          funcHookParams: (searchText, page, pageSize) => {
            return { searchText, page, pageSize };
          },
        }}
      />
    </>
  );
};

const MintsStats = () => {
  const [{ token:eventTokenDetails }] = useEvent();
  const [nftMintStat, setNftMintStat] = useState();

  const backendApi = useBackendApi();

  useEffect(() => {
    if (!eventTokenDetails?.address) return;
    backendApi.communities.tokens.adminAnalytics
      .getMintStats(eventTokenDetails.address)
      .then(setNftMintStat)
      .catch((e) => console.error(e));
  }, [eventTokenDetails]);

  return (
    <div className="MintsStats-container">
      <div className="chart">
        <h1>Mints Per Day</h1>
        <ResponsiveContainer width={"100%"} height={300}>
          <AreaChart data={nftMintStat?.mintsAgo}>
            <CartesianGrid strokeDasharray="3 9" stroke="#ffffff44" />
            <XAxis
              dataKey="ago"
              tickFormatter={(tickItem) => dayjs(Date.now() - tickItem * 24 * 60 * 60 * 1000).format("DD MMM")}
            />
            <YAxis dataKey="count" />
            <Area type="natural" dataKey="count" fill="var(--main-color)" fillOpacity={1} />
            <Tooltip content={<ChartTooltip labelSuffix="days ago" suffix="mints" />} />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

const TaskDoneStats = () => {
  const [{ token:eventTokenDetails }] = useEvent();
  const backendApi = useBackendApi();
  const [stats, setStats] = useState();

  useEffect(() => {
    if (!eventTokenDetails?.address) return;
    backendApi.communities.tokens.adminAnalytics
      .getTasksDoneStats(eventTokenDetails.address)
      .then(setStats)
      .catch((e) => {});
  }, [eventTokenDetails]);

  return (
    <div className="TaskDoneStats-container">
      <div className="chart">
        <h1>Tasks Per Day</h1>
        <ResponsiveContainer width={"100%"} height={300}>
          <AreaChart data={stats?.tasksPerDay}>
            <CartesianGrid strokeDasharray="3 9" stroke="#ffffff44" />
            <XAxis dataKey="day" tickFormatter={(tickItem) => dayjs(tickItem).format("DD MMM")} />
            <YAxis dataKey="count" />
            <Area type="natural" dataKey="count" fill="#009688" fillOpacity={1} />
            <Tooltip content={<ChartTooltip suffix="tasks" />} />
          </AreaChart>
        </ResponsiveContainer>
      </div>
      <div className="chart">
        <h1>User Per Task</h1>
        <ResponsiveContainer width={"100%"} height={300}>
          <BarChart data={stats?.usersPerTask}>
            <CartesianGrid strokeDasharray="3 9" stroke="#ffffff44" />
            <XAxis dataKey="task" tickFormatter={(tick) => tick.slice(0, 6)} />
            <YAxis dataKey="count" />
            <Bar dataKey="count" fill="var(--main-color)" />
            <Tooltip content={<ChartTooltip suffix="users" />} cursor={{ fill: "transparent" }} />
          </BarChart>
        </ResponsiveContainer>
      </div>

      <div className="chart">
        <h1>Task Per Level</h1>
        <ResponsiveContainer width={"100%"} height={300}>
          <BarChart data={stats?.tasksDonePerLevel} margin={{ bottom: 70 }}>
            <CartesianGrid strokeDasharray="3 9" stroke="#ffffff44" />
            <XAxis dataKey="level" />
            <YAxis dataKey="count" />
            <Bar dataKey="count" fill="var(--warn-color)" />
            <Tooltip content={<ChartTooltip suffix="tasks done" />} cursor={{ fill: "transparent" }} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

const UserDetails = ({ taskDone }) => {
  return (
    <div className="UserDetails-container">
      <div className="contractRow">
        <h2>#{taskDone?.tokenId}</h2>
        <h1>
          <Address a={taskDone?.userAddress} />
        </h1>
      </div>
      {taskDone?.userDiscordId ? (
        <div className="contractRow">
          <img src={resources.img.discordIcon} /> {taskDone?.userDiscordId}
        </div>
      ) : (
        <></>
      )}
      {taskDone?.userTwitter ? (
        <div className="contractRow">
          <img src={resources.img.twitter} /> {taskDone?.userTwitter}
        </div>
      ) : (
        <></>
      )}
      {taskDone?.userEmail ? (
        <div className="contractRow">
          <img src={resources.img.emailColored} /> {taskDone?.userEmail}
        </div>
      ) : (
        <></>
      )}
      {taskDone?.userTelegramId ? (
        <div className="contractRow">
          <img src={resources.img.telegram} /> {taskDone?.userTelegramId}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

const TaskDoneRow = ({ taskDone, i, page, pageSize }) => {
  return (
    <tr key={`${taskDone?.taskId}_${taskDone?.tokenId}`}>
      <td className="td-small">{i + 1 + (page * pageSize || 0)}</td>
      <td>{taskDone?.name || taskDone?.taskId}</td>
      <td>{taskDone?.tokenId}</td>
      <td>
        <Address a={taskDone?.userAddress} hideCopy={false} />
      </td>
      <td>
        {/* <HoverTooltip className="info-tooltip" tools={<UserDetails taskDone={taskDone} />}>
          {taskDone?.username}
        </HoverTooltip> */}
      </td>
      <td>{dayjs(taskDone?.createdAt).format("DD MMM YYYY")}</td>
    </tr>
  );
};

const TasksDone = () => {
  const useTaskDone = ({ searchText, page = 0, pageSize }) => {
    
    const [{ token:eventTokenDetails , community }] = useEvent();
    const backendApi = useBackendApi();

    const [tasksDoneData, setTasksDoneData] = useState();
    const [refreshCache, setRefreshCache] = useState(false);

    /**
     * tasksDoneData = {
     *  tasksDone=[{tokenId, taskId}],
     * }
     */

    const getTasksDone = (exportCsv) => {
      if (!eventTokenDetails?.address) return;
      backendApi.communities.tokens.adminAnalytics
        .getTasksDone(eventTokenDetails.address, searchText, page, refreshCache, exportCsv)
        .then(setTasksDoneData)
        .catch((e) => {
          console.error(e);
          alert.error("could not get tasks done");
        });
    };

    const exportCsv = () => {
      alert.promise(
        backendApi.communities.tokens.adminAnalytics
          .getTasksDone(eventTokenDetails.address, searchText, page, refreshCache, true)
          .then((data) => {
            // hack to download the file. fetch doesn't automatically download the file
            const url = window.URL.createObjectURL(new Blob([JSON.stringify(data)], { type: "application/json" }));
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            a.download = `${community.name}_${Date.now()}`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          }),
        {
          pending: "Exporting",
          success: "Exported",
          error: "Could not export",
        }
      );
    };

    useEffect(() => {
      getTasksDone();
    }, [eventTokenDetails, searchText, page]);

    return {
      tasksDone: tasksDoneData?.tasksDone,
      totalCount: tasksDoneData?.totalCount || 0,
      exportCsv,
    };
  };

  return (
    <>
      <h1>Task Activity</h1>
      <TableSearchApied
        className="TasksDone-container"
        searchPlaceholder="Search by task name, user address, discord id, twitter id..."
        idInputSearch={"id.input.admin.taskTracking.inputSearch"}
        renderToolbarRightPanel={({ dataHookRef }) => <button onClick={dataHookRef?.exportCsv}>Export</button>}
        theadRows={
          <>
            <th className="td-small">#</th>
            <th>Task Id</th>
            <th>Token Id</th>
            <th>User Address</th>
            <th>User Name</th>
            <th>Date</th>
          </>
        }
        renderRow={({ item: taskDone, i, page, pageSize }) => (
          <TaskDoneRow taskDone={taskDone} i={i} page={page} pageSize={pageSize} />
        )}
        pageSize={10}
        hookConfig={{
          useDataHook: useTaskDone,
          rowsKey: "tasksDone",
          totalCountKey: "totalCount",
          funcHookParams: (searchText, page, pageSize) => {
            return { searchText, page, pageSize };
          },
        }}
      />
    </>
  );
};

// todo: remove this hardcoding
const JumperRaffleEntries = () => {
  const { community } = useGlobalState();
  const backendApi = useBackendApi();

  const downloadCsv = () => {
    alert.promise(
      backendApi.communities.tokens.getJumperBtsRaffleEntries().then((blob) => {
        // Create a new URL for the blob
        const url = window.URL.createObjectURL(blob);
        // Create a new <a> element to facilitate downloading
        const a = document.createElement("a");
        a.href = url;
        a.download = `raffleEntries_${Date.now()}.csv`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }),
      {
        pending: "Downloading Raffle Entries",
        success: "Raffle Entries Downloaded",
        error: "Could not download entries",
      }
    );
  };

  if (community?.name != "jumperbts") return <></>;

  return (
    <>
      <h1 style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        Jumper BTS Raffle Entries
        <button style={{ width: "fit-content" }} onClick={downloadCsv}>
          Download Entries CSV
        </button>
      </h1>
    </>
  );
};

const EventAnalytics = () => {
  return (
    <div className="EventAnalytics-container">
      <EventProvider>
      <JumperRaffleEntries />
      <MintsStats />
      <UserProperties />
      <TaskDoneStats />
      <TasksDone />
      </EventProvider>
     
    </div>
  );
};

export default EventAnalytics;
